import React from "react";
import { Link } from "react-router-dom";
import SettingForm from "@components/SettingForm";
import { connect } from "react-redux";

interface Props {}
interface State {}

class EditDisplayName extends React.Component<Props | any, State> {
  state: State = {};

  back = () => {
    this.props.navigate("/settings");
  }

  render() {
    const {creator} = this.props;

    const cname = creator.creator?.username;
    const pname = creator.program.name;
    return (
        <div
        className={`container relative h-full mx-auto overflow-x-hidden`}
        style={{ height: "calc(100vh - 90px)" }}>
        <div
        className={`absolute w-full transition-all duration-500 h-full flex flex-col`}>
        {/* Edit Display Name header title & mobile menu start  */}
        <div className="px-5 pt-10">
          <div className="flex items-center gap-x-4">
            <Link  className="cursor-pointer" to={`/${cname}/${pname}/settings`}>
            <svg className="h-6 w-6 tile-inlay-primary"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="15 6 9 12 15 18" /></svg>
            </Link>
              <h4 className="font-normal tile-inlay-primary text-[24px] tracking-wider">
                Edit Display Name
            </h4>
          </div>
        </div>
        <SettingForm back={this.back} isExtra={false} />
      </div>
      </div>
    );
  }
}

const mapStateToProps = ({creator}) => {
  return {
    creator: creator,
  };
};

export default connect(mapStateToProps)(EditDisplayName);