import Loader from "@components/Loader";
interface SubmitButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  label?: string;
}

export const SubmitButton = ({
  disabled = false,
  isLoading = false,
  label = "SUBMIT",
}: SubmitButtonProps) => {
  return (
    <button
      type="submit"
      disabled={disabled}
      className={`bg-btn font-medium tracking-[0.06em] btn-inlay-primary flex items-center justify-center w-[80px] p-[5px_12px_7px_12px] h-[25px] rounded-full btn-text-primary ${
        disabled ? "disabled:opacity-30" : ""
      }`}
    >
      {isLoading ? <Loader height={20} width={20} /> : label}
    </button>
  );
};
