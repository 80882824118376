import axios from 'axios';
import Api from '@services/api';
import NotificationService from "@services/notification-service";
import { getHostName } from "@helpers/common";
import playerJson from "@common/magic_players.json";

const types = {

  FETCH_SEARCH_REQUEST_PENDING:"FETCH_SEARCH_REQUEST_PENDING",
  FETCH_SEARCH_REQUEST_SUCCESS:"FETCH_SEARCH_REQUEST_SUCCESS",
  FETCH_SEARCH_REQUEST_FAILURE:"FETCH_SEARCH_REQUEST_FAILURE",

  ADD_SELECTED_SEARCH_RESULT:"ADD_SELECTED_SEARCH_RESULT",
  REMOVE_SELECTED_SEARCH_RESULT:"REMOVE_SELECTED_SEARCH_RESULT",
  UPDATE_SELECTED_SEARCH_RESULT:"UPDATE_SELECTED_SEARCH_RESULT",
  CLEAR_SELECTED_SEARCH_RESULT:"CLEAR_SELECTED_SEARCH_RESULT",

  SUBMIT_SEARCH_REQUEST_PENDING:"SUBMIT_SEARCH_REQUEST_PENDING",
  SUBMIT_SEARCH_REQUEST_SUCCESS:"SUBMIT_SEARCH_REQUEST_SUCCESS",
  SUBMIT_SEARCH_REQUEST_FAILURE:"SUBMIT_SEARCH_REQUEST_FAILURE",


};

export const actions = {

  search: async (dispatch, type, term)=>{
    dispatch({ type: types.FETCH_SEARCH_REQUEST_PENDING });
    if(type=="sport"){
      const json = await axios.get('https://www.thesportsdb.com/api/v1/json/60130162/all_sports.php');
      dispatch({ type: types.FETCH_SEARCH_REQUEST_SUCCESS, data:{type:type, data:(json.data?.sports || [])} });
    }
    if(type=="team"){
      const json = await axios.get(`https://www.thesportsdb.com/api/v1/json/60130162/searchteams.php?t=${term}`);
      dispatch({ type: types.FETCH_SEARCH_REQUEST_SUCCESS, data:{type:type, data:(json.data?.teams || [])} });
    }
    if(type=="player"){

      const json = getHostName() == "orlandomagic"? {data:playerJson}: await axios.get(`https://www.thesportsdb.com/api/v1/json/60130162/searchplayers.php?p=${term}`);
      dispatch({ type: types.FETCH_SEARCH_REQUEST_SUCCESS, data:{type:type, data:(json.data?.player || [])} });
    }
    if(type=="address"){
      const json = await axios.get(`https://api.geoapify.com/v1/geocode/autocomplete?text=${term}&format=json&apiKey=73b8a2e8195f42fca50fc8736590c7ff`);
      dispatch({ type: types.FETCH_SEARCH_REQUEST_SUCCESS, data:{type:type, data:(json.data?.results || [])} });
    }
    
  },

  submit: async (dispatch, creatorId, form)=>{
    dispatch({ type: types.SUBMIT_SEARCH_REQUEST_PENDING });
    const json = await Api.submitSurvey(creatorId, form);
    if(json!==undefined){
      if(json.status==200){
        dispatch({ type: types.SUBMIT_SEARCH_REQUEST_SUCCESS });
        NotificationService.success("Survey submit successfully.");
      } else {
        dispatch({ type: types.SUBMIT_SEARCH_REQUEST_FAILURE });
        NotificationService.error("Something went wrong.");
      }
    } else {
      dispatch({ type: types.SUBMIT_SEARCH_REQUEST_FAILURE });
      NotificationService.error("Something went wrong.");
    }

    return json;
    
  },

  addSelectedSearch: async (dispatch, type, value)=>{
    dispatch({ type: types.ADD_SELECTED_SEARCH_RESULT, data:{type:type, value:value} });    
  },

  removeSelectedSearch: async (dispatch, type, value)=>{
    dispatch({ type: types.REMOVE_SELECTED_SEARCH_RESULT, data:{type:type, index:value} });    
  },

  updateSelectedSearch: async (dispatch, type, value)=>{
    dispatch({ type: types.UPDATE_SELECTED_SEARCH_RESULT, data:{type:type, value:value} });    
  },

  clearSelectedSearch: async (dispatch)=>{
    dispatch({ type: types.CLEAR_SELECTED_SEARCH_RESULT});    
  },

};

const initialState = {
  loader:false,
  data:{},
  survey:{}
};

export const reducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {

    case types.FETCH_SEARCH_REQUEST_SUCCESS:{
      return {
        ...state,
        data:{
          ...state.data,
          [data.type]:data.data
        }
      }
    }

    case types.ADD_SELECTED_SEARCH_RESULT:{
      return {
        ...state,
        survey:{
          ...state.survey,
          [data.type]:[...(state.survey?.[data.type] || []), data.value]
        }
      }
    }

    case types.REMOVE_SELECTED_SEARCH_RESULT:{
      const array = (state.survey?.[data.type] || []);
      array.splice(data.index, 1);
      return {
        ...state,
        survey:{
          ...state.survey,
          [data.type]:array
        }
      }
    }

    case types.UPDATE_SELECTED_SEARCH_RESULT:{
      return {
        ...state,
        survey:{
          ...state.survey,
          [data.type]:data.value
        }
      }
    }

    case types.CLEAR_SELECTED_SEARCH_RESULT:{
      return {
        ...state,
        survey:{}
      }
    }

    case types.SUBMIT_SEARCH_REQUEST_PENDING:{
      return {
        ...state,
        loader:true
      }
    }

    case types.SUBMIT_SEARCH_REQUEST_SUCCESS:{
      return {
        ...state,
        loader:false
      }
    }
    case types.SUBMIT_SEARCH_REQUEST_FAILURE:{
      return {
        ...state,
        loader:false
      }
    }
 
    default:
      return state;
  }
};