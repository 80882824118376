import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Pending from "@assets/Pending.png";
import timer from "@assets/pending-timer.png";

const StatusProcessing = () => {

    const [isShow, setIsShow] = useState(false);

    return (
        <Transition.Root show={isShow} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => true}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-[#000]/80 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="relative w-screen h-full overflow-hidden pointer-events-auto">
                                <div className="flex flex-col items-center justify-center text-center">
                                    <img
                                        src={Pending}
                                        alt="Pending"
                                        className="h-[101px] w-[90px] object-contain"
                                    />
                                    <h4 className="font-medium mt-10 tile-inlay-primary lg:text-[36px] md:text-[36px] text-[24px] text-center">
                                        Completion status processing
                                    </h4>
                                    <p className="text-[18px] mb-[40px] leading-[21.6px] tile-inlay-primary opacity-70 font-normal tracking-[1.5px] mt-[10px]">
                                        Your score will be updated with 24 hours.
                                    </p>
                                    <div className="relative lg:w-[420px] sm:w-[420px] md:w-[420px] w-[330px]">
                                        <div className="bg-tile w-full p-[20px] transition-all duration-700 ease-in-out  rounded-[10px] relative cursor-pointer overflow-hidden">
                                            <div className="flex justify-between items-center">
                                                <h6 className="font-[550] text-[16px] tile-inlay-primary">Check in at the Encore NYC meetup</h6>
                                                <img
                                                    src={timer}
                                                    alt="Check"
                                                    className="h-5 w-5"
                                                />
                                            </div>
                                            <p className="font-normal text-left text-[15px] tile-inlay-primary opacity-80">
                                                Tap to scan the QR code at the event.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
export default StatusProcessing;
