import { useEffect, useState } from "react";
import { Item } from "../EarnItemCard/types";
import { useFetchPhoneCodes } from "./hooks/useFetchPhoneCodes";
import { SmsCard } from "./components/smsCard";
import { useProgramContext } from "@hooks/useProgramContext";
import Api from "@services/api";
import { useSelector } from "react-redux";
import Management from "@services/management-service";

interface SmsSubscriptionCardProps {
  item?: Item;
  value?:string;
  disabledInput?:boolean;
  disabledCard?:boolean;
  fetchEarnRewards?:Function;
  onSubmit?: (phone: string) => void;
  error?: string;
}

export const SmsEarnItemCard = ({
  item,
  value,
  disabledInput,
  disabledCard,
  fetchEarnRewards,
  error,
  onSubmit,
}: SmsSubscriptionCardProps) => {
  const { programId, userId } = useProgramContext();
  const [submittedPhone, setSubmittedPhone] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submittedVerifiedCode, setSubmittedVerifiedCode] = useState<
    string | null
  >(null);

  const eToken = useSelector((state: any) => state.user.token);
  const programType = Management.get()?.program?.config?.type;

  const {
    sendCodeError,
    verifyCodeError,
    sendCodeLoading,
    verifyCodeIsLoading,
    reSendCode,
    reVerifyCode,
    verifyCodeData,
    verifyStatus
  } = useFetchPhoneCodes({
    phoneNumber: submittedPhone,
    verifiedCode: submittedVerifiedCode,
    item,
    // If this prop is set to true it will attempt to
    // authenticate
    onFinishItem: () => setSubmittedPhone(null)
  });

  useEffect(() => {
    if (
      !verifyCodeData ||
      !submittedPhone ||
      !submittedVerifiedCode ||
      verifyCodeData.status !== 200
    ) {
      return;
    }
    if (onSubmit) {
      // onSubmit(submittedPhone);
    }
  }, [onSubmit, submittedPhone, submittedVerifiedCode, verifyCodeData]);

  const handleSubmitPhoneInput = (newPhoneNumber: string) => {
    if (submittedPhone === newPhoneNumber) {
      reSendCode();
    } else {
      setSubmittedPhone(newPhoneNumber);
    }
  };

  const handleAuthorize = async (phone:string) => {
    setIsLoading(true);
    const authorizeData = {
      programId,
      fanId: userId,
      actionId: item?.id || '',
      tpId: item?.touchpoint?.id || "",
      pointsEarned: item?.points || 0,
      details:{
        phoneNumber:phone
      }
    };
  
   await  Api.authorizeActionItem(authorizeData);
   setIsLoading(false);
   fetchEarnRewards();
  }

  const changeSubmittedPhone = () => {
    setSubmittedPhone(null);
    setSubmittedVerifiedCode(null);
  };

  const handleSubmitVerificationCode = (code: string) => {
    if (code === submittedVerifiedCode) {
      reVerifyCode();
    } else {
      setSubmittedVerifiedCode(code);
    }
  };

  useEffect(() => {
    if (value && item?.shares?.fanEngagement === 0 && programType==="ecommerce") {
      handleAuthorize(value)
    }
  }, [value, eToken, item?.shares?.fanEngagement])
  
  return (
    <SmsCard
      item={item}
      onSubmitPhoneInput={handleSubmitPhoneInput}
      onChangePhoneInput={changeSubmittedPhone}
      onResendCode={reSendCode}
      onSubmitConfirmationCode={handleSubmitVerificationCode}
      submittedPhone={submittedPhone}
      isLoadingSubmitCode={verifyCodeIsLoading}
      isLoadingSubmitPhone={isLoading || sendCodeLoading}
      value={value}
      disabledInput={disabledInput}
      disabledCard={disabledCard}
      onAuthorize={handleAuthorize}
      phoneInputError={(() => {
        if (!sendCodeError) {
          return null;
        }
        if (sendCodeError?.message && sendCodeError.message.length > 0) {
          return sendCodeError.message[0];
        }
        return "Invalid phone number";
      })()}
      confirmCodeError={(() => {
        if (verifyStatus === 409) {
          return "Phone Number already associated with another user";
        }
        if (!verifyCodeError) {
          return null;
        }
        if (verifyCodeError?.message && verifyCodeError?.message.length > 0) {
          return verifyCodeError.message[0];
        }
        return "Invalid verification code";
      })()}
    />
  );
};
