import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Logo from "@components/Logo";
import auth0 from "auth0-js";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "@components/Loader";
import NotificationService from "@services/notification-service";
import ProgramBox from "@components/ProgramBox";
import { isMobileName, isProgramActive } from "@helpers/common";
import Api from "@services/api";
import Emitter from "@services/emitter-service";

type Props = {};

const CorsVerification = (props: Props | any) => {
const { actions } = require("@redux/CreatorRedux");
	const { actions: userAction } = require("@redux/UserRedux");
	const dispatch = useDispatch();
	const rnavigate = useNavigate();
	const gLoader = useSelector((state: any) => state.loader.loader);
	const program = useSelector((state: any) => state.creator?.program);
	const session = useSelector((state: any) => state.user.session);
	const token = useSelector((state: any) => state.user?.token);

    const WebAuth = new auth0.WebAuth({
		clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
		audience: process.env.REACT_APP_AUTH0_AUDIENCE,
		domain: process.env.REACT_APP_AUTH0_DOMAIN,
		redirectUri: window.location.origin + "/auth",
		responseType: "token id_token",
	});


	useEffect(() => {
		WebAuth.crossOriginVerification();
	}, []);

	return (
		<>
			</>
	);
};

export default CorsVerification;
