import React, { useEffect, useState } from 'react';
import Index from "@containers/fans/Index";
import { Navigate, useSearchParams, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from '@components/Loader';
import { getAppName } from "@helpers/common";
import EcommerceToWin from '@containers/fans/E-commerce';
import Management from '@services/management-service';

const IndexPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const rnavigate = useNavigate();
  const type = searchParams.get("ref");
  const management = Management.get();
  const session = useSelector((state: any) => state.user.session);
  const isLoading = useSelector((state: any) => state.creator.isLoading);
  const token = useSelector((state: any) => state.user.token);
  const { cname, pname } = useParams();

  const vh = window.innerHeight * 0.01;


  useEffect(() => {
    if(getAppName()!="creator"){
      if (!pname) {
        rnavigate(`/`);
      }
      if(management?.program?.config?.type == 'ecommerce'){
        if(session?.[cname]?.[pname]){
          rnavigate(`/${cname}/${pname}/earn`);
        }
      } else {
        if(session?.[cname]?.[pname] && token){
          rnavigate(`/${cname}/${pname}/earn`);
        }
      }
     
    }
    
  }, [management]);

 

  

  return (
    management?.program?.config?.type == 'ecommerce' ?
    <>
    {isLoading ? 
      <div className="flex items-center justify-center bg-white h-screen overflow-auto">
        <Loader height={30} width={30} color={"#000"}/>
      </div>:<EcommerceToWin/>}</>
  :
    <div
      style={{ height: vh * 100 }}
      className="bg-body flex items-center justify-center h-[100vh] max-h-[100vh] relative"
    >
      {(!(session?.[cname]?.[pname] && token) &&
        <Index
          type={type}
          navigate={rnavigate}
        />
      )}
    </div>
  
  );
};

export default IndexPage;