import { calculateRedumptionTiime } from "@helpers/common";
import check from "@assets/check.png";
import pendingTimer from "@assets/pending-timer.png";
import { useSelector } from "react-redux";

const BadgeCompleted = ({item}) => {

    const currentTimestamp = Date.now();

    const socialIds = useSelector((state: any) => state.creator?.socialIds);

    const config = calculateRedumptionTiime(
        item?.config,
        item?.shares?.lastEngagement,
        item?.shares?.fanEngagement,
        item?.totalEngagements,
        -1
      );    
    const disabled = config?.isDisabled;

    return (
        disabled ? 
            <div data-testid={`check-${item?.action}`} className={`bg-green rounded-full p-[4px] h-5 w-5 flex items-center justify-center z-10 overflow-hidden`}>
                <img
                    src={check}
                    alt="Check"
                    className="object-contain w-full h-full"
                />
            </div>:(socialIds?.[item?.id] && currentTimestamp>(socialIds?.[item?.id] || 0))?<img
            src={pendingTimer}
            alt="Check"
            className="object-contain w-5"
        />:
        <>
        {item?.shares?.fanEngagement>0 &&
        <div data-testid={`check-${item?.action}`} className="bg-green text-[12px] text-white rounded-full p-[4px] h-5 flex items-center justify-center z-10 overflow-hidden">
            {item?.shares?.fanEngagement}x
        </div>
         }
        </>
    );
};
export default BadgeCompleted;
