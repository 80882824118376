import Loader from "@components/Loader";
import { appendToUrl, makeSecureUrl } from "@helpers/common";
import React from "react";
type Props = {
	openCard: any;
	heading: string;
	website: null | string;
	redirectButton: boolean;
    loader: boolean;
    code: null | string;
    store: string;
};

const CardFooter: React.FC<Props> = ({
	openCard,
	heading,
	website,
	redirectButton,
    loader,
    code,
    store
}) => {
	return (
		<div className="p-4 flex justify-between items-center">
			<div className="flex-col gap-y-[10px] flex">
				<p className="text-[24px] leading-[26px] tracking-[0.01em] text-white font-medium">
					{heading}
				</p>
				<p className="uppercase text-[16px] leading-[17px] tracking-[0.04em] text-white opacity-60 font-light">
					{store}
				</p>
			</div>
			{redirectButton ? (
				<a
					target="_blank"
					rel="noreferrer"
					href={makeSecureUrl(appendToUrl(website, `discount/${code}`))}
					className="bg-btn p-[10px_24px_12px_24px] uppercase btn-inlay-primary rounded-full font-semibold text-[16px] leading-[22px] tracking-[0.03em]"
				>
					Shop
				</a>
			) : (
				<button
					onClick={openCard}
					className="bg-btn p-[10px_24px_12px_24px] uppercase btn-inlay-primary rounded-full font-semibold text-[16px] leading-[22px] tracking-[0.03em]"
				>
					{loader ? <Loader height={20} width={20} white={true} /> : "Claim"}
				</button>
			)}
		</div>
	);
};

export default CardFooter;
