import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import SettingForm from "@components/SettingForm";
import { connect } from "react-redux";

interface Props {}
interface State {flag:boolean}

class EditExtraInfo extends React.Component<Props | any, State> {
  state: State = {
    flag:false
  };

  back = () => {

    this.props.navigate("/");
  }

  componentDidMount() {
    let flag = false;
    const extraInfo = this.props.extraInfo;
    const dynamicForm = this.props.dynamicForm;
    const routes = Object.keys(dynamicForm);

    if("extraInfo" in (extraInfo || {})){
			if(routes.length>0){
				const array_values = Object.values((extraInfo?.extraInfo || {}));
				let flag = false;
				array_values.map((item)=>{
				  if(!item){
					flag = true;
				  }
				});
				if(Object.keys(extraInfo?.extraInfo || {}).length==0){
					flag = true;
				}
				this.setState({flag});
			}
		}
  }

  render() {
    const {flag} = this.state;
    const {creator} = this.props;
    const cname = creator.creator?.username;
    const pname = creator.program.name;
    return (
        <div
        className={`container relative h-full mx-auto overflow-x-hidden`}
        style={{ height: "calc(100vh - 90px)" }}>
            {flag &&
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
            <strong className="font-bold">Alert!</strong>
            <span className="block sm:inline"> The information below is required to earn points.</span>
          </div>
          }
        <div
        className={`absolute w-full transition-all duration-500 h-full flex flex-col`}>
        {/* Edit Display Name header title & mobile menu start  */}
        <div className="px-5 pt-10">
          <div className="flex items-center gap-x-4">
            <Link  className="cursor-pointer" to={`/${cname}/${pname}/settings`}>
            <svg className="h-6 w-6 tile-inlay-primary"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="15 6 9 12 15 18" /></svg>
            </Link>
              <h4 className="font-normal tile-inlay-primary text-[24px] tracking-wider">
                Edit Fan Info
            </h4>
          </div>
        </div>
        <SettingForm back={this.back} isExtra={true} />
      </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user,creator }) => {
    return {
      extraInfo:user.user?.user,
      creator: creator,
    dynamicForm:user?.dynamicForm
    };
  };
  
  export default connect(mapStateToProps)(EditExtraInfo);
