/** @format */

import { persistCombineReducers } from "redux-persist";
import AsyncStorage from "redux-persist/lib/storage";
// You have to import every reducers and combine them.
import { reducer as UserRedux } from "./UserRedux";
import { reducer as EarnRewardRedux } from "./EarnRewardRedux";
import { reducer as RdeemRewardRedux } from "./RedeemRewardRedux";
import { reducer as ScoreRedux } from "./ScoreRedux";
import { reducer as LeaderBoardRedux } from "./LeaderBoardRedux";
import { reducer as LoaderRedux } from "./LoaderRedux";
import { reducer as SetttingRedux } from "./SetttingRedux";
import { reducer as CreatorRedux } from "./CreatorRedux";
import { reducer as ShopifyRedux } from "./ShopifyRedux";
import { reducer as FacebookRedux } from "./FacebookRedux";
import { reducer as InstagramRedux } from "./InstagramRedux";
import { reducer as QrScanRedux } from "./QrScanRedux";
import { reducer as FanReferralRedux } from "./FanReferralRedux";
import { reducer as WaitlistRedux } from "./WaitlistRedux";
import { reducer as utilRedex } from "./utilRedex";
import { reducer as SurveyRedux } from "./SurveyRedux";
import { reducer as TouchpointRedux } from "./TouchpointRedux";
//AsyncStorage.removeItem('persist:root')
const config = {
  key: "encore",
  storage: AsyncStorage,
  whitelist: ["user", "setting", "creator", 'QrScan'],
};

const appReducer = persistCombineReducers(config, {
  user: UserRedux,
  EarnReward: EarnRewardRedux,
  RedeemReward: RdeemRewardRedux,
  score: ScoreRedux,
  leaderboard: LeaderBoardRedux,
  loader: LoaderRedux,
  setting: SetttingRedux,
  creator: CreatorRedux,
  shopify: ShopifyRedux,
  QrScan: QrScanRedux,
  facebook: FacebookRedux,
  instagram: InstagramRedux,
  fanreferal: FanReferralRedux,
  waitlist: WaitlistRedux,
  util:utilRedex,
  survey: SurveyRedux,
  touchpoint:TouchpointRedux
});

export default appReducer;