import Lottie from 'react-lottie'
import successGif from "@assets/lotties/success.json";
import check from "@assets/check.png";
import reward_collected from "@assets/reward_collected.gif";
import Icon from "@components/Icon";

const Loyalty: React.FC<any> = (props) => {
  const { data } = props || {};
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <></>;
  }
  const totalPoints = data.reduce((accumulator, currentObject) => {
    return accumulator + currentObject?.data?.points;
  }, 0);

  const availablePoints = data.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.score?.points;
  }, 0);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successGif,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center text-center">
        {data?.[0]?.type == "redeem" ? (
          <>
            <img
              src={reward_collected}
              alt="Reward collected"
              className="max-w-[385px] w-full mx-auto"
            />
            <h3 className="tile-inlay-primary font-semibold text-[24px] leading-[31.2px] -mt-[40px]">
              You redeemed a reward!
            </h3>
            <p className="mt-[15px] text-[18px] font-normal leading-[21.6px] tile-inlay-primary opacity-70 mb-[40px]">
              See a recap of your prize below, and check your email soon for all
              the info you’ll need to complete your pickup.
            </p>
          </>
        ) : (
          <>
            <Lottie options={defaultOptions} height={242} width={242} />
            <>
              <h4 className="font-medium text-white text-[36px] text-center">
                You earned {totalPoints} points
              </h4>
              <p className="text-[18px] mb-[40px] leading-[21.6px] text-[white]/70 font-normal tracking-[1.5px] mt-[10px]">
                You now have {availablePoints} points available.
              </p>
            </>
          </>
        )}

        {data.map((item, index) => {
          const common = item?.data;
          return (
            <div
              key={index}
              className="relative mb-1  w-[300px] sm:w-[420px] md:w-[420px] pb-3"
            >
              <div className="bg-green rounded-full p-[4px] h-5 w-5 flex items-center justify-center z-10 absolute -left-1 -top-1 overflow-hidden">
                <img
                  src={check}
                  alt="Check"
                  className="object-contain w-full h-full"
                />
              </div>

              <div className="bg-tile w-full p-[20px] transition-all duration-700 ease-in-out  rounded-[10px] relative cursor-pointer overflow-hidden">
                <div className="flex items-center justify-between">
                  <div className="bg-body opacity-50 p-[8px] rounded-full flex items-center justify-center">
                    <p
                      className={`lg:text-[14px] text-[12px] uppercase font-semibold tracking-wider tile-inlay-primary`}
                    >
                      {common?.points || 0} POINTS
                    </p>
                  </div>
                  <div className="-mt-1">
                    <Icon icon={common?.name} />
                  </div>
                </div>
                <h4 className="font-medium tile-inlay-primary text-left lg:text-[20px] text-[16px] mt-[15px]">
                  {common?.title}
                </h4>
                {!!common?.description && (
                  <p className="font-small tile-inlay-primary text-left lg:text-[14px] text-[12px] mt-[15px]">
                    {common?.description}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default Loyalty;
