import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@common/color.css";
import "@common/fontfamily.css";
import "react-toastify/dist/ReactToastify.css";
import Router from "./Router";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "@store/configureStore";
import { persistStore } from "redux-persist";
import { BrowserRouter } from "react-router-dom";
import { unregister } from './serviceWorker';
import EarnPopup from "@components/EarnPopup";
import {Auth0ProviderWithNavigate} from "./Auth0ProviderWithNavigate";

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
unregister();
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <React.StrictMode> */}
        <BrowserRouter>
        <Auth0ProviderWithNavigate >
        <Router />
        </Auth0ProviderWithNavigate>
        <EarnPopup />
        </BrowserRouter>
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log result (for example: reportWebVitals(console.log))
// or send to an analytic endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
