import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAppName } from "@helpers/common";
import Loader from "@components/Loader";
import Management from "@services/management-service";

const LogOut = () => {
	const { actions } = require("@redux/UserRedux");
	const { actions:creatorAction } = require("@redux/CreatorRedux");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const management = Management.get();
	const isLogout = useSelector((state: any) => state.user.isLogout);
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const returnTo = getAppName() === "creator" ? "/":`/${management?.creator?.username}/${management?.program?.name}`;

	useEffect(() => {
		if (isLogout !== true) {
			navigate("/");
		}

		if (isLogout === true) {
			setTimeout(() => {
				const handleLogout = async () => {
					await dispatch(actions.logout({cname:management?.creator?.username,pname:management?.program?.name}));
					await dispatch(creatorAction.clearNotificationTray());
					navigate(returnTo);
				};
				handleLogout();
			}, 500);
		}

		if (query.get("returnTo")) {
			navigate(query.get("returnTo"));
		}
	}, [isLogout, navigate, returnTo, query, dispatch, actions]);

	return (
		<>
			{getAppName() === "creator" ? (
				<div className="flex items-center justify-center bg-[#F6F2E9] h-screen overflow-auto">
					<Loader height={30} width={30} white={false} creator={true} />
				</div>
			) : (
				<div className="flex items-center justify-center bg-body h-screen overflow-auto">
					<Loader height={30} width={30} white={true} color={'var(--bg-btn)'} />
				</div>
			)}
		</>
	);
};

export default LogOut;
