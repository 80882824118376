import Api from "@services/api";

const types = {
  FETCH_WAITLIST_PENDING: "FETCH_WAITLIST_PENDING",
  FETCH_WAITLIST_FAILURE: "FETCH_WAITLIST_FAILURE",
  FETCH_WAITLIST_SUCCESS: "FETCH_WAITLIST_SUCCESS",
  UPDATE_ACTIVITIES: "UPDATE_ACTIVITIES",
  SET_FANS:"SET_FANS",
};

export const actions = {
    fetchWaitlist: async (dispatch, creator_id) => {
        dispatch({ type: types.FETCH_WAITLIST_PENDING });
        const json = await Api.waitlist(creator_id);
        if (json === undefined) {
          dispatch({
            type: types.FETCH_WAITLIST_FAILURE,
            data: { message: ["Can't get data from server"] },
          });
        } else if (200 !== json.status) {
          dispatch({ type: types.FETCH_WAITLIST_FAILURE, data: json.data });
        } else {
            dispatch({
              type: types.FETCH_WAITLIST_SUCCESS,
              data: json.data,
            });
        }
      },
      setFans : (fans) => ({
        type: types.SET_FANS,
        payload: fans,
      })
    };

const initialState = {
    fans: [
        {
          id: 1,
          name: 'John Doe 1',
        },
        {
          id: 2,
          name: 'Jane Smith 2',
        },
        {
          id: 3,
          name: 'Bob Johnson 3',
        },
        {
            id: 4,
            name: 'Bob Johnson 4',
          },
          {
            id: 5,
            name: 'Bob Johnson 5',
          },
          {
            id: 6,
            name: 'Bob Johnson 6',
          },
          {
            id: 7,
            name: 'Bob Johnson 7',
          },
          {
            id: 8,
            name: 'Bob Johnson 8',
          },
          {
            id: 9,
            name: 'Bob Johnson 9',
          },
          {
            id: 10,
            name: 'Bob Johnson 10',
          },
          {
            id: 11,
            name: 'Bob Johnson 11',
          },
          {
            id: 12,
            name: 'Bob Johnson 12',
          },
          {
            id: 13,
            name: 'Bob Johnson 13',
          },{
            id: 14,
            name: 'John Doe 14',
          },
          {
            id: 15,
            name: 'Jane Smith 15',
          },
          {
            id: 16,
            name: 'Bob Johnson 16',
          },
      ],
  isFetching: false,
  error: {},
  activities: false,
};

export const reducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case types.SET_FANS:
      return {
        ...state,
        fans: action.payload,
      };
    case types.FETCH_WAITLIST_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    }
    case types.FETCH_WAITLIST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: data.message,
      };
    }
    case types.FETCH_WAITLIST_SUCCESS: {
      return {
        ...state,
        data: data,
        isFetching: false,
        error: {},
      };
    }
    case types.UPDATE_ACTIVITIES: {
        return {
          ...state,
          data: data,
          isFetching: false,
          error: {},
        };
      }
      
    default:
      return state;
  }
};
