import store from "@store/configureStore";



class ManagementService {
    
  async fetch() {
    const { actions } = require("@redux/CreatorRedux");
    const urlString = window.location.href;
    const cname = urlString.split('/')?.[3];
    const pname = urlString.split('/')?.[4]?.split('?')?.[0];
    const params = { creatorUn: cname, programName: pname };
    if(pname && cname && cname!=="callback"){
        store.dispatch({ type: "SET_GLOBAL_LOADER", data: true });
        await actions.fetchFanProgram(store.dispatch, params);
        store.dispatch({ type: "SET_GLOBAL_LOADER", data: false });
    }
   
  }

  earn(){
    const { rewardActions } = require("@redux/EarnRewardRedux");
    const urlString = window.location.href;
    const cname = urlString.split('/')?.[3];
    const pname = urlString.split('/')?.[4]?.split('?')?.[0];
    const token = store.getState().user?.session?.[cname]?.[pname] || null;
    const management = store.getState().creator?.management?.[cname]?.[pname] || {}
    if(token){
      rewardActions.fetchEarnRewards(store.dispatch, management?.program?.id);
    }
  }

  get(){
    const urlString = window.location.href;
    const cname = urlString.split('/')?.[3];
    const pname = urlString.split('/')?.[4]?.split('?')?.[0];
    return store.getState().creator?.management?.[cname]?.[pname] || {}
  }
}

const Management = new ManagementService();

Object.freeze(Management);

export default Management;
