import Api from "@services/api";
import NotificationService from "@services/notification-service";

const types = {
  FETCH_CREATOR_TOUCHPOINT_PENDING: "FETCH_CREATOR_TOUCHPOINT_PENDING",
  FETCH_CREATOR_TOUCHPOINT_SUCCESS: "FETCH_CREATOR_TOUCHPOINT_SUCCESS",
  FETCH_CREATOR_TOUCHPOINT_FAILURE: "FETCH_CREATOR_TOUCHPOINT_FAILURE",

  SUBMIT_CREATOR_TOUCHPOINT_PENDING: "SUBMIT_CREATOR_TOUCHPOINT_PENDING",
  SUBMIT_CREATOR_TOUCHPOINT_SUCCESS: "SUBMIT_CREATOR_TOUCHPOINT_SUCCESS",
  SUBMIT_CREATOR_TOUCHPOINT_FAILURE: "SUBMIT_CREATOR_TOUCHPOINT_FAILURE",

  UPDATE_CREATOR_TOUCHPOINT_PENDING: "UPDATE_CREATOR_TOUCHPOINT_PENDING",
  UPDATE_CREATOR_TOUCHPOINT_SUCCESS: "UPDATE_CREATOR_TOUCHPOINT_SUCCESS",
  UPDATE_CREATOR_TOUCHPOINT_FAILURE: "UPDATE_CREATOR_TOUCHPOINT_FAILURE",

  DELETE_CREATOR_TOUCHPOINT_PENDING: "DELETE_CREATOR_TOUCHPOINT_PENDING",
  DELETE_CREATOR_TOUCHPOINT_SUCCESS: "DELETE_CREATOR_TOUCHPOINT_SUCCESS",
  DELETE_CREATOR_TOUCHPOINT_FAILURE: "DELETE_CREATOR_TOUCHPOINT_FAILURE",

  SUBMIT_CREATOR_TOUCHPOINT_ACTION_PENDING:
    "SUBMIT_CREATOR_TOUCHPOINT_ACTION_PENDING",
  SUBMIT_CREATOR_TOUCHPOINT_ACTION_SUCCESS:
    "SUBMIT_CREATOR_TOUCHPOINT_ACTION_SUCCESS",
  SUBMIT_CREATOR_TOUCHPOINT_ACTION_FAILURE:
    "SUBMIT_CREATOR_TOUCHPOINT_ACTION_FAILURE",

  UPDATE_CREATOR_TOUCHPOINT_ACTION_PENDING:
    "UPDATE_CREATOR_TOUCHPOINT_ACTION_PENDING",
  UPDATE_CREATOR_TOUCHPOINT_ACTION_SUCCESS:
    "UPDATE_CREATOR_TOUCHPOINT_ACTION_SUCCESS",
  UPDATE_CREATOR_TOUCHPOINT_ACTION_FAILURE:
    "UPDATE_CREATOR_TOUCHPOINT_ACTION_FAILURE",

  DELETE_CREATOR_TOUCHPOINT_ACTION_PENDING:
    "DELETE_CREATOR_TOUCHPOINT_ACTION_PENDING",
  DELETE_CREATOR_TOUCHPOINT_ACTION_SUCCESS:
    "DELETE_CREATOR_TOUCHPOINT_ACTION_SUCCESS",
  DELETE_CREATOR_TOUCHPOINT_ACTION_FAILURE:
    "DELETE_CREATOR_TOUCHPOINT_ACTION_FAILURE",

  ADD_CREATOR_TOUCHPOINT_FORM: "ADD_CREATOR_TOUCHPOINT_FORM",
};
export const actions = {
  fetch: async (dispatch, programId) => {
    dispatch({ type: types.FETCH_CREATOR_TOUCHPOINT_PENDING });
    const json = await Api.fetchCreatorTouchpoint(programId);
    if (json !== undefined) {
      if (200 === json.status) {
        //NotificationService.success("Touchpoint created successfully.");
        dispatch({
          type: types.FETCH_CREATOR_TOUCHPOINT_SUCCESS,
          data: json.data.data,
        });
      } else {
        NotificationService.error(json.data?.message);
        dispatch({ type: types.FETCH_CREATOR_TOUCHPOINT_FAILURE, data: "" });
      }
    } else {
      dispatch({ type: types.FETCH_CREATOR_TOUCHPOINT_FAILURE, data: "" });
    }
    return json;
  },

  submit: async (dispatch, data) => {
    dispatch({ type: types.SUBMIT_CREATOR_TOUCHPOINT_PENDING, data });
    const json = await Api.submitCreatorTouchpoint(data);
    if (json !== undefined) {
      if (200 === json.status || 201 === json.status) {
        NotificationService.success(json.data?.message);
        dispatch({
          type: types.SUBMIT_CREATOR_TOUCHPOINT_SUCCESS,
          data: data,
        });
      } else {
        NotificationService.error(json.data?.message);
        dispatch({ type: types.SUBMIT_CREATOR_TOUCHPOINT_FAILURE, data: data });
      }
    } else {
      dispatch({ type: types.SUBMIT_CREATOR_TOUCHPOINT_FAILURE, data: data });
    }
    return json;
  },

  update: async (dispatch, data) => {
    dispatch({ type: types.UPDATE_CREATOR_TOUCHPOINT_PENDING, data });
    const json = await Api.updateCreatorTouchpoint(data);
    if (json !== undefined) {
      if (200 === json.status) {
        NotificationService.success(json.data?.message);
        dispatch({
          type: types.UPDATE_CREATOR_TOUCHPOINT_SUCCESS,
          data: data,
        });
      } else {
        NotificationService.error(json.data?.message);
        dispatch({ type: types.UPDATE_CREATOR_TOUCHPOINT_FAILURE, data: data });
      }
    } else {
      dispatch({ type: types.UPDATE_CREATOR_TOUCHPOINT_FAILURE, data: data });
    }
    return json;
  },

  delete: async (dispatch, data) => {
    dispatch({ type: types.DELETE_CREATOR_TOUCHPOINT_PENDING, data });
    const json = await Api.deleteCreatorTouchpoint(data);
    if (json !== undefined) {
      if (200 === json.status) {
        NotificationService.success(json.data?.message);
        dispatch({
          type: types.DELETE_CREATOR_TOUCHPOINT_SUCCESS,
          data: data,
        });
      } else {
        NotificationService.error(json.data?.message);
        dispatch({ type: types.DELETE_CREATOR_TOUCHPOINT_FAILURE, data: data });
      }
    } else {
      dispatch({ type: types.DELETE_CREATOR_TOUCHPOINT_FAILURE, data: data });
    }
    return json;
  },

  submitAction: async (dispatch, data) => {
    dispatch({ type: types.SUBMIT_CREATOR_TOUCHPOINT_ACTION_PENDING, data });
    const json = await Api.submitCreatorTouchpointAction(data);
    if (json !== undefined) {
      if (200 === json.status || 201 === json.status) {
        NotificationService.success(json.data?.message);
        dispatch({
          type: types.SUBMIT_CREATOR_TOUCHPOINT_ACTION_SUCCESS,
          data: data,
        });
      } else {
        NotificationService.error(json.data?.message);
        dispatch({
          type: types.SUBMIT_CREATOR_TOUCHPOINT_ACTION_FAILURE,
          data: data,
        });
      }
    } else {
      dispatch({
        type: types.SUBMIT_CREATOR_TOUCHPOINT_ACTION_FAILURE,
        data: data,
      });
    }
    return json;
  },

  updateAction: async (dispatch, data) => {
    dispatch({ type: types.UPDATE_CREATOR_TOUCHPOINT_ACTION_PENDING, data });
    const json = await Api.updateCreatorTouchpointAction(data);
    if (json !== undefined) {
      if (200 === json.status) {
        NotificationService.success(json.data?.message);
        dispatch({
          type: types.UPDATE_CREATOR_TOUCHPOINT_ACTION_SUCCESS,
          data: data,
        });
      } else {
        NotificationService.error(json.data?.message);
        dispatch({
          type: types.UPDATE_CREATOR_TOUCHPOINT_ACTION_FAILURE,
          data: data,
        });
      }
    } else {
      dispatch({
        type: types.UPDATE_CREATOR_TOUCHPOINT_ACTION_FAILURE,
        data: data,
      });
    }
    return json;
  },

  deleteAction: async (dispatch, data) => {
    dispatch({ type: types.DELETE_CREATOR_TOUCHPOINT_ACTION_PENDING, data });
    const json = await Api.deleteCreatorTouchpointAction(data);
    if (json !== undefined) {
      if (200 === json.status) {
        NotificationService.success(json.data?.message);
        dispatch({
          type: types.DELETE_CREATOR_TOUCHPOINT_ACTION_SUCCESS,
          data: data,
        });
      } else {
        NotificationService.error(json.data?.message);
        dispatch({
          type: types.DELETE_CREATOR_TOUCHPOINT_ACTION_FAILURE,
          data: data,
        });
      }
    } else {
      dispatch({
        type: types.DELETE_CREATOR_TOUCHPOINT_ACTION_FAILURE,
        data: data,
      });
    }
    return json;
  },

  addForm: (type, data) => {
    return { type: types.ADD_CREATOR_TOUCHPOINT_FORM, data: data, touch: type };
  },
};
const initialState = {
  data: [],
  isFetching: false,
  isLoading: {},
  form: {},
};
export const reducer = (state = initialState, action) => {
  const { type, data, touch } = action;
  switch (type) {
    case types.FETCH_CREATOR_TOUCHPOINT_PENDING: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case types.FETCH_CREATOR_TOUCHPOINT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        data: data.touchpoints,
        form:{}
      };
    }

    case types.FETCH_CREATOR_TOUCHPOINT_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case types.SUBMIT_CREATOR_TOUCHPOINT_PENDING: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.type]: true,
        },
      };
    }

    case types.SUBMIT_CREATOR_TOUCHPOINT_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.type]: false,
        },
      };
    }

    case types.SUBMIT_CREATOR_TOUCHPOINT_FAILURE: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.type]: false,
        },
      };
    }

    case types.UPDATE_CREATOR_TOUCHPOINT_PENDING: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.type]: true,
        },
      };
    }

    case types.UPDATE_CREATOR_TOUCHPOINT_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.type]: false,
        },
      };
    }

    case types.UPDATE_CREATOR_TOUCHPOINT_FAILURE: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.type]: false,
        },
      };
    }

    case types.DELETE_CREATOR_TOUCHPOINT_PENDING: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.type]: true,
        },
      };
    }

    case types.DELETE_CREATOR_TOUCHPOINT_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.type]: false,
        },
      };
    }

    case types.DELETE_CREATOR_TOUCHPOINT_FAILURE: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.type]: false,
        },
      };
    }

    case types.SUBMIT_CREATOR_TOUCHPOINT_ACTION_PENDING: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.action]: true,
        },
      };
    }

    case types.SUBMIT_CREATOR_TOUCHPOINT_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.action]: false,
        },
      };
    }

    case types.SUBMIT_CREATOR_TOUCHPOINT_ACTION_FAILURE: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.action]: false,
        },
      };
    }

    case types.UPDATE_CREATOR_TOUCHPOINT_ACTION_PENDING: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.action]: true,
        },
      };
    }

    case types.UPDATE_CREATOR_TOUCHPOINT_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.action]: false,
        },
      };
    }

    case types.UPDATE_CREATOR_TOUCHPOINT_ACTION_FAILURE: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.action]: false,
        },
      };
    }

    case types.DELETE_CREATOR_TOUCHPOINT_ACTION_PENDING: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.id]: true,
        },
      };
    }

    case types.DELETE_CREATOR_TOUCHPOINT_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.id]: false,
        },
      };
    }

    case types.DELETE_CREATOR_TOUCHPOINT_ACTION_FAILURE: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [data.id]: false,
        },
      };
    }

    case types.ADD_CREATOR_TOUCHPOINT_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          [touch]: data,
        },
      };
    }

    default:
      return state;
  }
};
