import Api from "@services/api";

const types = {
  FETCH_EARN_REWARDS_PENDING: "FETCH_EARN_REWARDS_PENDING",
  FETCH_EARN_REWARDS_FALIURE: "FETCH_EARN_REWARDS_FALIURE",
  FETCH_EARN_REWARDS_SUCCESS: "FETCH_EARN_REWARDS_SUCCESS",
  FETCH_SUBSCRIBE_PENDING: " FETCH_SUBSCRIBE_PENDING",
  FETCH_SUBSCRIBE_FALIURE: " FETCH_SUBSCRIBE_FALIURE",
  FETCH_SUBSCRIBE_SUCCESS: " FETCH_SUBSCRIBE_SUCCESS",

  UPDATE_REWARD_TOUCHPOINT:"UPDATE_REWARD_TOUCHPOINT",

  SET_REWARD_TOUCHPOINT:"SET_REWARD_TOUCHPOINT"
};
export const rewardActions = {
  fetchEarnRewards: async (dispatch, programId) => {
    dispatch({ type: types.FETCH_EARN_REWARDS_PENDING });
    const json =await Api.fanTouchpoints(programId);
    if (json === undefined) {
        dispatch({
        type: types.FETCH_EARN_REWARDS_FALIURE,
        data: {message: ["Can't get data from server"]},
        });
    } else if (200 !== json.status) {
        dispatch({ type: types.FETCH_EARN_REWARDS_FALIURE, data: json.data });
       
    } else {
        dispatch({ type: types.FETCH_EARN_REWARDS_SUCCESS, data: json.data?.data?.touchpoints });
    }
    return json;
  },

  fetchSubscribe: async (dispatch,creatorId,data) => {
    dispatch({ type: types.FETCH_SUBSCRIBE_PENDING });
    const json =await Api.subscribeActionItemEvent(data, creatorId);
    if (json === undefined) {
        dispatch({
        type: types.FETCH_SUBSCRIBE_FALIURE,
        data: {message: ["Can't get data from server"]},
        });
    } else if (200 !== json.status) {
        dispatch({ type: types.FETCH_SUBSCRIBE_FALIURE, data: json.data });
    } else {
        dispatch({ type: types.FETCH_SUBSCRIBE_SUCCESS, data: json.data });
    }
    return json;
  },

  updateTouchpoints:(dispatch, data) => {
    dispatch({ type: types.UPDATE_REWARD_TOUCHPOINT, data:data });
  },

  setTouchpoints:(data) => {
    return { type: types.SET_REWARD_TOUCHPOINT, data:data };
  }

};


const initialState = {
  data:[],
  isFetching:false,
  error:{},
};

export const reducer = (state = initialState, action) => {
  const { type, token, user, data } = action;

  switch (type) {
    case types.FETCH_EARN_REWARDS_PENDING:{
      return {
        ...state,
        isFetching:true,
        error:{},
      }
    }
    case types.FETCH_EARN_REWARDS_FALIURE:{
      return {
        ...state,
        isFetching:false,
        error:data,
      }
    }
    case types.FETCH_EARN_REWARDS_SUCCESS: {
      return { ...state, 
        data:data,
        isFetching:false,
      };
    }
    case types.UPDATE_REWARD_TOUCHPOINT:{

      let touchpoints = state.data;
      const array = [...touchpoints];
      
      const index = array.findIndex(i=>i.id==data.tpId);
      const actionItems = array[index];
      const aindex = (actionItems?.actions || []).findIndex(i=>i.id==data.id);
      (actionItems?.actions || [])[aindex] = data;
      array[index] = actionItems;
      return { 
        ...state, 
        data:array
      };

    }

    case types.SET_REWARD_TOUCHPOINT:{
      return { ...state, 
        data:data,
      };
    }
    default:
      return state;
  }
};
