import React, { useEffect, useState } from 'react';
const FollowTimer = () => {

    const [counter, setCounter] = useState(100);

    useEffect(() => {
        const timeout = setInterval(() => {
            setCounter(counter-1);
        }, 1000)
    
        return () => clearTimeout(timeout)
      }, [counter])

    return (<span>({counter})</span>);
}
export default FollowTimer;