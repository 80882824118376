import React from "react";
import { connect } from "react-redux";
import Loader from "@components/Loader";
import Logo from "@components/Logo";
import "react-phone-input-2/lib/style.css";
import "./index.css";
import Instagram from '@assets/instagram_black.png';
import Globe from '@assets/globe_black.png';
import Twitter from '@assets/twitter_black.png';
import Youtube from '@assets/youtube_black.png';
import Spotify from '@assets/spotify_black.png';
import Icon_White from "@components/Icon_white";
import Popup from "@components/Popup";
import ProgramBox from "@components/ProgramBox";


interface Props {
  form: Object;
  type: any;
  addFormStep: (step: number) => void;
  addForm: (form: Object) => void;
}
interface State {
  number_error: boolean;
  error_message: any;
  isShow: boolean;
  email: string;
  loader: boolean;
}

class Signin extends React.Component<Props | any, State> {
  state: State = {
    number_error: false,
    error_message: "",
    isShow: false,
    email: "",
    loader: false
  };

  componentDidMount() {
    if (this.props.assets.creatorId) {
      this.props.fetch(this.props.assets.creatorId);
    }
    if (this.props.location == "/sign-in/step-1") {
      this.props.addFormStep(2);
    }

  }

  stepHandle = async (step: number) => {
    if (!this.validation(this.props.form.email)) {
      this.props.doLogin(this.props.form.email);
    }
  };

  addForm = (key, value) => {
    const form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addForm(form);
  };

  formatPhoneNumber = (value) => {
    //if the user deletes the input, then just return
    if (!value) return value;
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;
    // we need to return the value with no formatting if its less then four digits
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 12)}`;
  };

  validation = (value: any) => {
    let error = true;
    var validRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!validRegex.test(value)) {
      this.setState({
        error_message: "Invalid email address",
      });
    } else {
      this.setState({
        error_message: "",
      });
      error = false;
    }
    return error;
  };

  render() {
    const { actionItem, assets,creator } = this.props;

    return (
      <div
        className={` absolute w-full transition-all duration-1000 ease-in-out h-full text-center py-10 flex flex-col ${creator.username === 'orlandomagic' && 'orlandomagic_bg'}`}>

        <div className="flex flex-col justify-center grow">
          <Logo className="xl:h-[auto] h-[auto] rounded-full xl:w-[200px] w-[150px] object-contain mx-auto" />

          <div className="flex justify-center w-full mt-10 lg:grow-0 grow">
            <div className="max-w-[340px] w-full px-3 flex flex-col">
              {!actionItem?.id &&
                <div className="flex items-center grow">
                  <h2 className="tile-inlay-primary font-medium text-[24px] leading-9 mx-auto text-center">
                    What’s your email?
                  </h2>
                </div>
              }

              {actionItem?.id &&
                <>
                  <h2 className="font-semibold text-[24px] leading-[31.2px] text-center text-white mb-[8px]">
                    {actionItem?.title}
                  </h2>
                  <p className="font-normal text-[16px] leading-[22.4px] text-center text-white mb-[40px]">
                    {actionItem?.description}
                  </p>
                </>
              }
              <div className="grow lg:mt-8">
                <div className="flex items-center w-full max-w-sm mx-auto gap-x-1 bg-tile rounded">
                  {/* <PhoneInput
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          this.stepHandle(0);
                        }
                      }}
                      onChange={e => this.addForm("phone_number", e)}
                      value={this.props.form.phone_number}
                        placeholder="xxx-xxx-xxxx"
                        country={'us'}
                        dropdownClass='mr-2 bg-transparent'
                        containerClass="bg-lightBlack appearance-native h-[60px] placeholder:font-normal font-normal rounded-[10px] w-20 text-white outline-none"
                      /> */}
                  <input
                    type="email"
                    onChange={(e) => this.addForm("email", e.target.value)}
                    value={this.props.form.email ?? ""}
                    placeholder="hello@gmail.com"
                    className="w-full mx-auto h-[60px] px-3 text-center rounded-[10px] text-[18pt] placeholder:opacity-30 placeholder:font-normal font-normal tile-inlay-primary light shadow-none outline-none"
                    data-testid="user_number"
                  />
                </div>
                {/* <div className="flex items-center w-full max-w-sm mx-auto gap-x-1">
                    <div>
                      <select
                        onChange={e =>
                          this.addForm("country_code", e.target.value)
                        }
                        name="cars"
                        id="cars"
                        className="bg-lightBlack appearance-native pl-4 h-[60px] text-[24px] placeholder:font-normal font-normal rounded-[10px] w-20 text-white px-1 outline-none"
                      >
                        {countries
                          .filter(object =>
                            ["US", "PK", "IL"].includes(object.isoName)
                          )
                          .map(item => {
                            return item.callingCodes.map((code, index) => {
                              return (
                                <option
                                  value={code}
                                  key={index}
                                  className="text-black"
                                >
                                  {code}
                                </option>
                              );
                            });
                          })}
                      </select> 
                    </div>
                  <input
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          this.stepHandle(0);
                        }
                      }}
                      onChange={e => this.addForm("phone_number", e.target.value)}
                      value={this.props.form.phone_number}
                      maxLength={12}
                      placeholder="xxx-xxx-xxxx"
                      className="w-full mx-auto h-[60px] px-3 text-center rounded-[10px] text-[24px] placeholder:font-normal font-normal text-white light shadow-none outline-none"
                      data-testid="user_number"
                    /> 
                  </div> */}
                <div className="flex items-center justify-center h-7">
                  {!!this.state.error_message && (
                    <p
                      className="text-sm font-normal text-red-500 "
                      data-testid="user_number_error">
                      {this.state.error_message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            disabled={this.state.loader}
            onClick={()=>this.props.loginWithRedirect()}
            className={` bg-tile hover:opacity-80 rounded-full mx-auto h-[42px] w-[89px] font-medium text-[18px] text-button-color`}>
            {this.state.loader ? (
              <Loader height={30} width={30} white={true} />
            ) : (
              actionItem?.id ? "submit" : <ProgramBox box="LOGIN_PAGE_BUTTON_TEXT" />
            )}
          </button>


        </div>
        {actionItem?.id &&
          <div className="mt-[60px] gap-[25px] flex items-center flex-wrap justify-center">
            {(this.props.touchpoint?.creatorstouchpoint?.touchpoints || []).map(
              (item, key) => (
                <div key={key}>
                  {item.touchpoint.touchpoint === "featured" ||
                    item.touchpoint.touchpoint === "typeform"
                    ? null
                    : item.touchpoint.url && (
                      <a
                        key={key}
                        rel="noreferrer"
                        href={item.touchpoint.url}
                        target={item.touchpoint.url ? "_blank" : ""}
                      >
                        <div className="bg-[#555555] h-[35px] w-[35px] rounded-full flex items-center justify-center">
                          <Icon_White icon={item.touchpoint.touchpoint} />
                        </div>
                      </a>
                    )}
                </div>
              )
            )}
          </div>}

        {/* <Popup title={"Confirm your email"} message={`We emailed a link to ${this.state.email}`} isFan={true} show={this.state.isShow} /> */}
      </div>
    );
  }
}

const mapStateToProps = ({ user, creator }, ownProps) => {
  return {
    step: user.step,
    form: user.form,
    isFetching: user.isFetching,
    creator : creator?.data,
    actionItem: creator.actionItemDetail,
    assets: creator.assets,
    touchpoint: creator,
    creator_id: creator?.data?.id,
    location: ownProps.location,
    navigate: ownProps.navigate,
    loginWithRedirect: ownProps.loginWithRedirect,

  };
};

const mergeProps = (stateProps: any, dispatchProps: any, type: any) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("@redux/UserRedux");
  const { actions: acreator } = require("@redux/CreatorRedux");
  return {
    ...stateProps,
    addFormStep: (step: number) => {
      dispatch(actions.addFormStep(step));
    },
    addForm: (form: Object) => {
      dispatch(actions.addForm(form));
    },
    resetForm: (form: Object) => {
      dispatch(actions.resetForm(form));
    },
    doLogin: (phone_number: any) => {
      actions.doLogin(dispatch, phone_number);
    },
    auth: (email: string) => {
      return actions.auth(dispatch, email);
    },
    resetUser: (data) => {
      return dispatch(actions.resetUser(data));
    },
    authError: (error: Object) => {
      dispatch(actions.authError(error));
    },
    fetch: (data) => {
      acreator.fetchProfileData(dispatch, data);
    }
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Signin);
