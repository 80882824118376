import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import left from "@assets/left.png";

interface Props {}
interface State {}

class InviteFriend extends React.Component<Props | any, State> {
  state: State = {};

  render() {
    return (
      <div className="bg-[#000] h-[calc(100vh-100px)] flex flex-col py-10 container mx-auto space-y-10">
        <Link
          to="/my-rewards"
          className="w-8 h-8 p-2 text-xl font-semibold tracking-wider text-center text-white bg-gray-900 rounded-full cursor-pointer hover:bg-gray-800 gap-x-2">
          <img src={left} alt="left" className="object-contain w-full h-full" />
        </Link>
        <div className="flex items-center justify-center h-full">
          <h1 className="text-3xl text-white">Coming Soon</h1>
        </div>
      </div>
    );
  }
}

export default InviteFriend;
