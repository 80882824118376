import { Item } from "@components/EarnItemCard/types";
import { useSelector } from "react-redux";

export const useProgramContext = () => {
  const state = useSelector((state: any) => state);

  return {
    programId: state.creator?.program?.id,
    userId: state?.user?.user?.user?.id,
  };
};
