class MailService {
  summary(fanId) {
    const subject = encodeURIComponent(`Request Data Summary / ${fanId}`);
    const emailLink = `mailto:${process.env.REACT_APP_CONTACT_US_MAIL}?subject=${subject}`;
    window.location.href = emailLink;
  }

  removal(fanId) {
    const subject = encodeURIComponent(`Request Data Removal / ${fanId}`);
    const emailLink = `mailto:${process.env.REACT_APP_CONTACT_US_MAIL}?subject=${subject}`;
    window.location.href = emailLink;
  }
}

const Mail = new MailService();

Object.freeze(Mail);

export default Mail;
