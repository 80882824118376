import React, { useState } from "react";
import Loader from "@components/Loader";
import store from "@store/configureStore";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import Api from "@services/api";
import emitter from "@services/emitter-service";
import { useSelector, useDispatch } from "react-redux";
import Mixpanel from "@services/mixpanel-events";

let creatorType = false;
let currentUrl = window.location.href;
const params = new URL(currentUrl).searchParams;
const state = params.get("state");
if (state) {
	let token_creator_type = state.split("~");
	if (token_creator_type) {
		if (token_creator_type?.[2] && token_creator_type?.[2] == "creator") {
			creatorType = true;
		}
	}
}

interface AuthProps {}

const Auth: React.FC<AuthProps | any> = (props) => {
	const { actions } = require("@redux/UserRedux");
	const [isAuthorize, setIsAuthorize] = React.useState(false);
	const [extraInfo, setExtraInfo] = React.useState({});
	const creator = useSelector((state: any) => state.creator);
	const [loader, setLoader] = useState(false);
	const token = useSelector((state: any) => state.user?.token);
	const formp = useSelector((state: any) => state.user.form);
	const { actions: userAction } = require("@redux/UserRedux");
	const {actions:creatorAction} = require("@redux/CreatorRedux");
	const creator_id = useSelector((state: any) => state.creator.creator.id);
	const programId = useSelector((state: any) => state.creator.program.id);
	const dynamicForm = creator?.program?.extraInfo || {};
	const routes = Object.keys(dynamicForm || {}).reverse();
	const fdata = useSelector((state: any) => Object.assign({}, state.user.form));

	const type = store.getState().loader.appCreator;
	const rnavigate = useNavigate();
	const dispatch = useDispatch();

	const cname = creator?.creator?.username;
	const pname = creator?.program?.name;
	const template = creator?.program?.config?.type;

	const urlFragment = window.location.hash;
	const accessTokenRegex = /access_token=([^&]+)/;
	const match = urlFragment.match(accessTokenRegex);
	const accessTokenJwt = match?.[1] || token;

	const authenticate = async () => {
		Api.setClientToken(accessTokenJwt);
		const json = await userAction.authenticate(
			dispatch,
			null,
			"fans",
			creator_id,
			accessTokenJwt,
			programId,
			cname,
			pname
		);
		if (json?.status == 404) {
			if (routes.length > 0) {
				let form = fdata;
				form = {
					...form,
					token:accessTokenJwt,
					refresh: (form?.refresh || 0) + 1,
					[`/${cname}/${pname}/auth/${routes[0]}`]: true,
				};
				await dispatch(userAction.addForm(form));
				if(template=="ecommerce"){
					rnavigate(`/${cname}/${pname}`);
				} else {
					rnavigate(`/${cname}/${pname}/auth/${routes[0]}`);
				}

			} else {
				let form = fdata;
				form = {
					...form,
					token:accessTokenJwt,
					refresh: (form?.refresh || 0) + 1,
					[`/${cname}/${pname}/auth/last`]: true,
				};
				await dispatch(userAction.addForm(form));
				if(template=="ecommerce"){
					rnavigate(`/${cname}/${pname}`);
				} else {
					rnavigate(`/${cname}/${pname}/auth/last`);
				}

			}
		} else if (json?.status == 207) {
			const extraInfo = json.data?.data?.signUpSteps || {};
			const requestAuthorization = json.data?.data?.requestAuthorization || false;
			if (Object.keys(extraInfo).length > 0) {
				let form = fdata;
				form = {
					...form,
					token:accessTokenJwt,
					refresh: (form?.refresh || 0) + 1,
					[`/${cname}/${pname}/auth/${routes[0]}`]: true,
				};

				await dispatch(userAction.addForm(form));
				if(!requestAuthorization){
					dispatch(creatorAction.upddateExtraInfo(extraInfo));
					rnavigate(`/${cname}/${pname}/auth/${routes[0]}`);
				} else {
					setIsAuthorize(true);
					setExtraInfo(extraInfo);
				}

			} else {
				if(!requestAuthorization){
					signupHandle();
				} else {
					setIsAuthorize(true);
					setExtraInfo(extraInfo);
				}

			}
		} else if (json?.status == 200) {
			setTimeout(() => Mixpanel.send("Logged In")); 
			rnavigate(`/${cname}/${pname}/earn`);
		}
	};

	React.useEffect(() => {
		if (accessTokenJwt) {
			authenticate();
		}
	}, [accessTokenJwt]);

	const onAuthorize = () => {
		setIsAuthorize(false);
		if (Object.keys(extraInfo).length > 0) {
			dispatch(creatorAction.upddateExtraInfo(extraInfo));
			rnavigate(`/${cname}/${pname}/auth/${routes[0]}`);
		} else {
			signupHandle();
		}
	}

	const onCancel = () => {
		setIsAuthorize(false);

		if (routes.length > 0) {
			rnavigate(`/${cname}/${pname}/auth/${routes[0]}`);
		} else {
			signupHandle();
		}
	}

	const signupHandle = async () => {
		setLoader(true);
		let userData = {
			creatorId: creator?.creator?.id,
			programId: creator?.program?.id,
		};

		userData["extraInfo"] = formp?.extrainfo || {};

		const json = await actions.doSignup(dispatch, userData, accessTokenJwt, creator?.creator?.username, creator?.program?.name);

		if (json.status == 201) {
			setTimeout(() => Mixpanel.send("Account Created")); 
			setLoader(false);
			rnavigate(`/${cname}/${pname}/earn`);
			const jsonTouchpoint = await Api.fanTouchpoints(
				json?.data?.data?.programId
			);
			if (jsonTouchpoint.status == 200) {
				const touchpoints = jsonTouchpoint.data?.data?.touchpoints || [];
				const touchpoint =
					touchpoints.filter((i) => i.type == "featured" && i.active)?.[0] ||
					{};
				const action =
					(touchpoint.actions || []).filter(
						(i) => i.action == "join" && i.config?.active
					)?.[0] || {};
				if (action?.id) {
					const data = {
						score: {
							points: action?.points,
							lifetimePoints: action?.points,
						},
						data: action,
					};
					emitter.emit("NotificationPopup", [data]);
				}
			}
		}
	};

	return (
		<>
			{creatorType || type ? (
				<div className="flex items-center justify-center bg-[#F6F2E9] h-screen overflow-hidden">
					<Loader height={30} width={30} white={false} creator={true} />
				</div>
			) : (
				<div className="flex items-center justify-center bg-body h-screen overflow-hidden">
					<Loader height={30} width={30} white={true} color={'var(--bg-btn)'} />
				</div>
			)}

			<Transition appear show={isAuthorize} as={React.Fragment}>
				<Dialog
				as="div"
				className="relative z-50"
				onClose={() => {}}
				>
				<div className={`fixed inset-0`}>
					<div className="flex items-end justify-center min-h-full text-center">
					<Transition.Child
						as={React.Fragment}
						enter="transform transition ease-in-out duration-700"
						enterFrom="translate-y-full"
						enterTo="translate-y-0"
						leave="transform transition ease-in-out duration-700"
						leaveFrom="translate-y-0"
						leaveTo="translate-y-full"
					>


						<Dialog.Panel className="w-screen max-w-[685px] transform overflow-hidden rounded-b-none rounded-t-[40px] text-left align-middle transition-all bg-tile" style={{boxShadow: '0px 0px 34px 0px rgba(0, 0, 0, 0.15)'}}>
						<div className="relative">
							<button
							type="button"
							onClick={() => {}}
							>
							<span className="sr-only">Close panel</span>
							<div className="w-[25px] h-1 absolute mx-auto top-[10px] left-0 right-0 bottom-0 rounded-full opacity-20 bg-black"></div>
							{/* <svg className="w-8 h-8 text-red-500 absolute mx-auto top-[10px] left-0 right-0 bottom-0"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg> */}
							</button>
						</div>
						<div className="px-[30px] text-center">
							<h3 className="tile-inlay-primary font-[550] text-[24px] leading-[31px] mt-[41px]">
							Do you want to share your data with Encore?
							</h3>
							<p className="mt-[15px] text-[18px] font-normal leading-[22px] tile-inlay-primary">
							By authorizing below, you agree to share information about your account information.
							</p>
							<div className="relative">
							<button
								onClick={onAuthorize}
								className={`font-semibold bg-btn mt-[50px] rounded-full btn-inlay-primary w-32 mx-auto h-[41px] flex items-center justify-center`}
							>
								{loader ? (
								<Loader height={30} width={30} white={true} />
							) : (
								"authorize"
							)}
							</button>
							</div>
							<div className="relative">
							<button
								onClick={onCancel}
								className="font-semibold bg-black rounded-full text-white w-28 mx-auto h-[41px] flex items-center justify-center mt-[15px] mb-[50px]"
							>enter
							</button>
							</div>
						</div>
						</Dialog.Panel>
					</Transition.Child>
					</div>
				</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default Auth;
