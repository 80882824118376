import React from "react";
import logo from "@assets/logo_trade.png";
import LinkTo from "@components/LinkTo";
import left from "@assets/left.png";

interface Props {}
interface State {}

class TermsConditions extends React.Component<Props | any, State> {
  state: State = {};

  render() {
    return (
      <div className="bg-[#f6f2e9] h-full 2xl:py-16 py-10 2xl:pb-5">
        <div className="container mx-auto">
          <div className="flex items-center justify-center">
            <div className=" w-36">
              <LinkTo
                text=""
                to="/"
                prefix={
                  <img
                    src={left}
                    alt="Close"
                    className="object-contain w-4 h-4"
                  />
                }
                className="flex items-center justify-center w-8 h-8 rounded-full bg-[#f6104e]"
              />
            </div>
            <img
              alt={"logo"}
              src={logo}
              className="lg:w-[220px] w-[150px] object-contain mx-auto"
            />
            <div className="flex justify-end text-right w-44">
              <LinkTo
                text="privacy policy"
                to="/p/privacy-policy"
                className="hover:text-[#f6104e] underline font-medium text-[18px] tile-inlay-primary lg:flex hidden items-center"
              />
            </div>
          </div>

          <h2 className="lg:mt-[80px] mt-[30px] text-black font-semibold lg:text-[34px] text-[24px] lg:max-w-[697px] max-w-[299px] lg:leading-tight leading-10 mx-auto text-center">
            Terms and Conditions
          </h2>
          <p className="italic font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
            Last updated: November, 2023
          </p>
          <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
            Hi fans! Encore builds tools for decentralized fan loyalty programs
            (the “Platform”). These terms of service (“Terms”) are between you
            and Encore Fans, inc. doing business as Encore (“Encore,” “we,” “our,”
            or “us”), and establish the terms that apply to you when you use the
            Service (as defined below).
          </p>
          <p className="uppercase font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
            BY USING THE SERVICE YOU INDICATE YOUR ACCEPTANCE OF THESE TERMS AND
            YOUR AGREEMENT TO BE BOUND BY THESE TERMS. YOU MAY NOT USE THE
            SERVICE IF YOU DO NOT AGREE TO THESE TERMS.
          </p>
          <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
            We are constantly improving the Service, so these Terms may need to
            change as we do. We may change these Terms at any time. Your
            continued use of the Service after any change to these Terms means
            that you accept the new version. You should consult these Terms each
            time you access the Service to view any changes. These Terms were
            last modified on the date indicated above.
          </p>
          <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
            Please review our 
            <LinkTo
              text="Privacy policy"
              to="/p/privacy-policy"
              className="mx-2 font-light lg:text-[24px] text-[18px] lg:leading-10 leading-7 c-primary"
            />
            which explains how we collect, use, and share information about you
            when you access or use the Service. By accepting these Terms, you
            agree to the Privacy Policy.
          </p>
          <ul className="list-decimal lg:pl-10 pl-5 space-y-8  mt-[20px]">
            <li className="font-medium text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              Use of the Service.
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Service.
                  </p>
                  The "
                  <p className="inline font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Service
                  </p>
                  " includes: (1) Encore’s website (located at{" "}
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    className="inline font-light c-primary lg:text-[24px] text-[18px]"
                    href="https://www.encore.fans/">
                    https://www.encore.fans/
                  </a>{" "}
                  ) (the "
                  <p className="inline font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Site
                  </p>
                  ") (2) the Platform; and (3) all content on the Site and Apps
                  and all intellectual property rights arising out of or related
                  to the foregoing. Any person who accesses or uses the Service,
                  whether on their own behalf or on behalf of any third party,
                  is a "
                  <p className="inline font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    User
                  </p>
                  ".
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    License.
                  </p>
                  Subject to your compliance with these Terms, Encore hereby
                  grants you a non-exclusive, revocable, non-transferable
                  license to access the Site, in each case, solely for your
                  personal use or for internal business purposes.
                </li>
              </ul>
            </li>
            <li className="font-medium text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              Limited Use Policy
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  Encore's use and transfer to any other app of information received
                  from Google APIs will adhere to &nbsp;
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    className="inline font-light c-primary lg:text-[24px] text-[18px]"
                    href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                    Google API Services User Data Policy
                  </a>{" "}
                  including the Limited Use requirements.
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                Encore’s Role and Your Risks.
              </p>
              Encore is a technology platform. Because Encore is a technology
              platform that facilitates access to services that are
              blockchain-based and/or involve acquiring, holding, or using
              cryptocurrency, to use the Service you acknowledge that
              participating in or using the Service requires you to take on
              certain risks. These risks include, but are not limited to the
              following.
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Risk of Losing Money.
                  </p>
                  As with any asset, the value of digital currencies can
                  fluctuate without warning. Therefore, when you acquire, hold,
                  or transfer digital currencies there is a significant risk
                  that you will lose money doing so. For these reasons, you
                  should carefully consider whether acquiring, holding, or
                  transferring digital currencies is suitable for you in light
                  of your current financial situation and your tolerance for
                  risk.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Not Registered.
                  </p>
                  Encore is not registered with the U.S. Securities and Exchange
                  Commission and does not offer securities services in the
                  United States or to U.S. persons. Therefore, you should be
                  aware that if you elect to place digital currencies with any
                  of Encore’s third-party services partners or use the services
                  of those partners in any way, such decisions are made solely
                  at your own risk. Digital currencies transferred or held by
                  these partners are not insured or guaranteed by Encore or any
                  agency of the United States government. For these reasons, you
                  may lose principal as well as accrued interest on such virtual
                  currencies.
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Accounts; Registration; Restrictions.
              </p>
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Verify:
                  </p>
                  You agree and acknowledge that you are solely responsible and
                  liable for your use of the verification technology provided
                  (“Verification”) or any resulting data of such Verification
                  (“Verification Results”). Encore makes no representations or
                  warranties regarding the accuracy or completeness of any
                  Verification Results, or that such Verification or
                  Verification Results are adequate for, or achieve, your
                  business purpose or requirements. You assume sole
                  responsibility for compliance with any and all required
                  registrations, licensing, and other requirements regulating
                  your products and services and related activities. Encore
                  assumes no responsibility for compliance with any laws or
                  regulations regarding your products or services or your use of
                  the Verification or Verification Results. Without limiting the
                  foregoing, Encore is not responsible for, and will not provide
                  you with any advice, counsel, or recommendation pertaining to
                  legal compliance of your products or services associated with
                  your use of the Verification or Verification Results in
                  connection with your products or services, and Encore hereby
                  explicitly disclaims any representation or warranty that use
                  of any Verification or Verification Results will meet your
                  legal obligations, if any.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Encore Users.
                  </p>
                  To access most features of the Service, you must register for
                  an account. You must complete the registration process by
                  providing us with current, complete, and accurate information,
                  as prompted by the applicable registration form. Without
                  limiting anything contained in the Privacy Policy, Encore may
                  use account registration information to determine if a User
                  is, or you are, listed on any list of specially designated
                  nationals and blocked persons or otherwise sanctioned by ay
                  governmental authority.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Accuracy of Information.
                  </p>
                  You acknowledge that if you provide any information to us that
                  is not current, complete, or accurate, Encore may terminate
                  these Terms and your continued access and use of the Service.
                  You agree to update your information if it is no longer
                  current, complete, and accurate.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Closing your Account.
                  </p>
                  You may close your account at any time and without cost, but
                  you will remain liable for any outstanding purchases as well
                  as any fees or other charges incurred in connection with your
                  account. Encore will not issue refunds for amounts previously
                  incurred through our Service once you close your account. You
                  can close your account by emailing
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    href="mailto:hello@encore.fans"
                    className="inline ml-2 font-light c-primary lg:text-[24px] text-[18px]">
                    hello@encore.fans
                  </a>
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Eligibility.
                  </p>
                  You represent and warrant that: (1) you are at least 18 years
                  of age; (2) you have not been previously suspended or removed
                  from the Service; and (3) your registration and your use of
                  the Service is in compliance with all applicable laws. Encore
                  provides the Service from the United States. If you are using
                  the Service from outside the United States, the Service may
                  not be appropriate or permitted under applicable law. It is
                  your responsibility to use the Service in accordance with all
                  applicable law in any jurisdiction that applies to you.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Credentials.
                  </p>
                  As part of the registration process, you might be asked to
                  select a username, password, or other login credentials. You
                  are responsible for maintaining the security and
                  confidentiality of your login credentials. You agree to notify
                  Encore immediately of any unauthorized use of your account or
                  any other breach of security. To notify us, contact us at
                  hello@encore.fans.You are responsible for all use of the
                  Service occurring under your account and all content posted
                  with your account on the Service. You may not share your login
                  credentials with any third party. Encore will not be liable
                  for any loss that you incur as a result of someone else using
                  your login credentials or account.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Your Responsibilities.
                  </p>
                  You may use the Service solely for lawful purposes, and you
                  may not (and you may not allow or assist any third party to):
                  <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      use, copy, modify, create derivative works, install,
                      transfer, or distribute the Service, except as
                      specifically described in these Terms and any usage
                      limitations communicated to you;
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      rent, lease, or otherwise permit third parties to use the
                      Service, or reformat, mirror, or frame any portion of the
                      Service;
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      circumvent or disable any security features of the
                      Service, or probe, scan, or test the vulnerability of the
                      System;
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      gain unauthorized access to the Service, to other Users’
                      accounts, names, or personally identifiable information,
                      or to other computers or websites connected or linked to
                      the Service;
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      use the Service to distribute any viruses or other
                      malicious code, or to transmit large amounts of data in a
                      way that would be expected to have a detrimental effect on
                      the Service;
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      use any robot, spider, site search/retrieval application,
                      or other manual or automatic device or process to
                      retrieve, index, “data mine,” or reproduce or circumvent
                      the navigational structure or presentation of the Service
                      or its contents;
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      reverse engineer, decompile, disassemble, or otherwise
                      attempt to discover the source code, object code, or
                      underlying structure, ideas, know-how, or algorithms
                      related to the Service (except to the extent this
                      restriction is prohibited by applicable law);
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      restrict, discourage, or inhibit any other User from using
                      the Service;
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      disclose personal information about a third party or
                      another User on the Service or obtained from the Service
                      without the consent of such third party or User, or
                      solicit, harvest, or collect information about other
                      Encore Users without their consent;
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      violate any applicable international, federal, state,
                      provincial, or local laws or regulations;
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      use the Service in violation of Encore’s or any third
                      party’s intellectual property or other rights;
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      express or imply that any statements you make are endorsed
                      by Encore, without our prior written consent in each
                      instance; or
                    </li>
                    <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                      use or access the Service to build a competing service.
                    </li>
                  </ul>
                </li>
              </ul>
              We may take any legal action and implement any technical remedies
              to prevent the violation of these restrictions and to enforce
              these Terms.
            </li>
            <li className="font-medium text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              Fees.
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Fees.
                  </p>
                  Certain features within the Service may require you to pay
                  fees, including fees related to transactions you engage in via
                  the Platform. All prices are in U.S. Dollars unless otherwise
                  indicated.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Network Fees.
                  </p>
                  Encore may charge network fees to process a digital asset
                  transaction on your behalf. Encore will calculate the network
                  fee at Encore’s discretion, but Encore will notify you of the
                  network fee at or before the time you authorize the digital
                  asset transaction.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Third-Party Services Fees.
                  </p>
                  When you use the Service, some fees may be required to use the
                  functionality provided by Third-Party Services (“Third-Party
                  Services Fees”). It is your responsibility to pay such
                  Third-Party Services Fees. Third-Party Services Fees are
                  determined by the Third-Party Services providers, and may be
                  subject to change at any time.
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Third-Party Services.
              </p>
              Encore and third parties may make available integrations between
              the Service and third-party products or services, including
              plugins and related services ("
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Third-Party Services.
              </p>
              ") that you may elect to use. Any use by you of such Third-Party
              Services is solely between you and the applicable Third-Party
              Service provider. Without limiting Section 12, Encore’s only
              representations and warranties relating to the Service are those
              set forth in these Terms, and Encore expressly disclaims any
              representations or warranties with respect to the validity or
              accuracy of any claims made by Third-Party Services relating to
              Encore or the Service. Because the Third-Party Services rely on
              the Third-Party Service provider’s continued operation, Encore
              does not warrant or provide support for Third-Party Services.
              Encore is not responsible for any violations of applicable law by
              Third-Party Service providers, or for any liability arising from
              your use of Third-Party Services. Encore does not guarantee the
              continued availability of any Third-Party Services (or any
              integration with Third-Party Services or related Service
              features), and if such Third-Party Services or related features
              are discontinued, you will not be entitled to any refund, credit,
              or other compensation. Depending on your location, certain
              Third-Party Services may not be available to you.
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Ownership.
              </p>
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Encore IP.
                  </p>
                  The Platform and Service, including any content,
                  modifications, and updates, and all intellectual property
                  rights therein (collectively, "
                  <p className=" inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Encore IP.
                  </p>
                  "), is owned by Encore and its licensors. No ownership rights
                  in the Encore IP are transferred to you by these Terms. You do
                  not have any rights in or to the Encore IP except for the
                  limited express rights granted in these Terms.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Trademarks.
                  </p>
                  You acknowledge that Encore has acquired, and is the owner of,
                  common law or registered trademark rights in the name and word
                  mark “Encore” and in the other marks and design marks
                  displayed on the Service. You acknowledge that these names and
                  marks are famous and internationally known. You will not
                  challenge the validity of, or Encore’s ownership of, the
                  foregoing names or marks, and you waive any rights you may
                  have to do so. You may not use our names or marks in
                  connection with any product or service that is not ours, or in
                  any manner that is likely to cause confusion. All use of the
                  foregoing names and marks by you will inure exclusively to
                  Encore’s benefit.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Feedback.
                  </p>
                  If you give Encore feedback, comments, or suggestions
                  concerning the Service (collectively, "
                  <p className=" inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Feedback.
                  </p>
                  "), you hereby assign to Encore all right, title, and interest
                  in and to the Feedback, and Encore is free to use the Feedback
                  without payment, attribution, or restriction.
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Confidentiality.
              </p>
              In the course of providing the Service, Encore may disclose or
              make available to you information about its business. You
              acknowledge that all knowledge, information, and data provided by
              Encore to you with respect to the business, operations, and
              marketing of Encore’s products and services that is not generally
              known or publicly available, whether or not designated as
              “confidential,” is Encore’s confidential information and you will
              not use or disclose such confidential information to any third
              party without Encore’s prior written consent.
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Data.
              </p>
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Usage Data.
                  </p>
                  Encore may collect and analyze data relating to your use of
                  the Service ("
                  <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Usage Data.
                  </p>
                  ") and other information relating to the provision, use, and
                  performance of various aspects of the Service and related
                  systems and technologies (including information provided by
                  third-party analytical tools). Encore may analyze, process,
                  collect, and use Usage Data for any purpose, including for
                  improving the Service and developing new products, services,
                  features, and functionality.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Special Notice for International Use.
                  </p>
                  Recognizing the global nature of the Internet, you agree to
                  comply with all applicable laws regarding the transmission of
                  data exported from the United States or the country in which
                  you reside. If you are using the Service from the European
                  Union or other regions with laws governing data collection and
                  use, then you agree to the transfer and processing of your
                  information to and in the United States. By providing your
                  information to us, you consent to any transfer and processing
                  in accordance with our
                  <LinkTo
                    text=" Privacy policy."
                    to="/p/privacy-policy"
                    className="font-light lg:text-[24px] text-[18px] lg:leading-10 leading-7 c-primary"
                  />{" "}
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Consent to Electronic Communications.
              </p>
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Administrative Communications.
                  </p>
                  By using the Service, you agree that we may communicate with
                  you electronically relating to your use of the Service.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Consent.
                  </p>
                  You consent to receiving our email newsletter and other
                  marketing-related emails from us. If you wish to remove
                  yourself from our email list for such marketing-related
                  emails, please use the unsubscribe link in any email received
                  from us, or email us with “OPT-OUT”, “UNSUBSCRIBE”, “STOP”, or
                  “REMOVE” in the subject line.
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Term and Termination.
              </p>
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Term.
                  </p>
                  The term of these Terms will commence on the date on which you
                  first access or use the Service and will continue as long as
                  you continue until terminated.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Termination.
                  </p>
                  If you breach (or if Encore suspects you have breached) these
                  Terms, Encore may, in its sole discretion, terminate these
                  Terms and your User account and/or limit, suspend, or
                  terminate your access to the Apps or Service, with or without
                  notice. You may terminate these Terms by contacting us at{" "}
                  <a
                    href="mailto:hello@encore.fans"
                    className="inline font-light c-primary lg:text-[24px] text-[18px]">
                    hello@encore.fans
                  </a>{" "}
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Effect of Termination.
                  </p>
                  Upon termination of these Terms, you remain obligated to pay
                  any outstanding fees relating to your use of the Service that
                  were incurred prior to termination. The following Sections of
                  these Terms will survive termination of these Terms:1.1, 2-3,
                  5-9, 11, and 12-20. Either party’s termination of these Terms
                  is without prejudice to any other remedies it may have at law
                  or in equity, and does not relieve either party of liability
                  for breaches occurring prior to the effective date of
                  termination. Neither party will be liable to the other for
                  damages arising solely as a result of terminating these Terms.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Deactivation.
                  </p>
                  Encore may, without notice to you: (1) restrict, deactivate,
                  or terminate your access to the Service (or any portion); or
                  (2) terminate or modify the Service (or any portion). Encore
                  will not be liable to you or any third party for any
                  termination of or modification to the Service regardless of
                  the reason for such termination or modification. If you are
                  dissatisfied with any termination or modification of the
                  Service, your only remedy is to stop using the Service.
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Representations and Warranties; Disclaimer.
              </p>
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    DISCLAIMER.
                  </p>
                  ENCORE MAKES NO EXPRESS OR IMPLIED WARRANTIES WITH RESPECT TO
                  THE SERVICE, THIRD-PARTY SERVICES, OR OTHERWISE, AND
                  SPECIFICALLY DISCLAIMS ALL IMPLIED AND STATUTORY WARRANTIES,
                  INCLUDING THE IMPLIED WARRANTIES OF NON-INFRINGEMENT OF
                  THIRD-PARTY RIGHTS, MERCHANTABILITY, SATISFACTORY QUALITY,
                  ACCURACY, TITLE, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY
                  WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE
                  PRACTICE. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS
                  AVAILABLE” BASIS. ENCORE DOES NOT WARRANT THAT THE SERVICE, OR
                  THIRD-PARTY SERVICES WILL SATISFY YOUR REQUIREMENTS, ARE
                  WITHOUT DEFECT OR ERROR, OR THAT THE OPERATION OF THE SERVICE,
                  OR THIRD-PARTY SERVICES WILL BE UNINTERRUPTED.
                  <p className="inline mr-2 font-light text-[#140943]/70 ml-2 lg:text-[24px] text-[18px]">
                    Some jurisdictions may limit or prohibit warranty
                    disclaimers, and this Section will apply solely to the
                    extent permitted under applicable law.
                  </p>
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Indemnification.
              </p>
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Defense.
                  </p>
                  At Encore’s option and request, you will defend Encore, its
                  affiliates, and their respective directors, officers,
                  employees, agents, and contractors (the "
                  <p className=" inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Encore Parties
                  </p>
                  ") from any actual or threatened third-party claim,
                  proceeding, or suit arising out of or based on: (1) your
                  breach of any representation, warranty, or covenant in these
                  Terms; (2) your violation of applicable law or any third-party
                  intellectual property, proprietary, or privacy right; (3) any
                  dispute between you and any third party; or (4) your use of
                  the Service in a manner not authorized under these Terms
                  (each, a "
                  <p className=" inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Claim
                  </p>
                  "). If Encore requests you to defend a Encore Party from any
                  Claim, Encore will: (a) give you prompt written notice of the
                  Claim; (b) grant you full and complete control over the
                  defense and settlement of the Claim; (c) provide assistance in
                  connection with the defense and settlement of the Claim as you
                  may reasonably request; and (d) comply with any settlement or
                  court order made in connection with the Claim. Notwithstanding
                  the previous sentence, you may not enter into any settlement
                  that involves an admission of guilt or liability of a Encore
                  Party without Encore’s prior written consent. Encore may
                  participate in the defense of a Claim at its own expense and
                  with counsel of its own choosing.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Indemnification.
                  </p>
                  You will indemnify the Encore Parties from and pay: (1) all
                  damages, costs, and attorneys’ fees finally awarded against a
                  Encore Party in any Claim; (2) all out-of-pocket costs
                  (including attorneys’ fees) reasonably incurred by a Encore
                  Party in connection with the defense of a Claim; and (3) all
                  amounts that you agree to pay to any third party to settle any
                  Claim.
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Limitation of Liability.
              </p>
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    EXCLUSION OF DAMAGES.
                  </p>
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER
                  ENCORE NOR ANY ENCORE PARTY WILL BE LIABLE TO YOU FOR ANY
                  CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR EXEMPLARY DAMAGES
                  ARISING OUT OF OR RELATED TO THESE TERMS OR YOUR USE OF THE
                  SERVICE, INCLUDING LOST PROFITS, LOSS OF BUSINESS, OR LOSS OF
                  DATA, EVEN IF ENCORE IS APPRISED OF THE LIKELIHOOD OF SUCH
                  DAMAGES OCCURRING. ENCORE SHALL NOT BE LIABLE FOR ANY DAMAGES,
                  LIABILITY, OR LOSSES ARISING OUT OF YOUR RECEIPT OR PROVISION
                  OF ANY THIRD-PARTY SERVICES.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    DAMAGES CAP.
                  </p>
                  ENCORE’S TOTAL LIABILITY OF ALL KINDS ARISING OUT OF OR
                  RELATED TO THESE TERMS OR YOUR USE OF THE SERVICE (INCLUDING
                  WARRANTY CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF
                  WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT, OR
                  OTHERWISE, WILL NOT EXCEED THE GREATER OF: (1) THE AMOUNT OF
                  FEES PAID BY YOU TO ENCORE DURING THE SIX MONTHS IMMEDIATELY
                  PRECEDING THE CLAIM (DETERMINED AS OF THE DATE OF ANY FINAL
                  JUDGMENT IN AN ACTION); OR (2) $100.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    BASIS OF THE BARGAIN.
                  </p>
                  EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION
                  OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF
                  DAMAGES IS TO ALLOCATE THE RISKS UNDER THESE TERMS BETWEEN THE
                  PARTIES. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS
                  OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS
                  IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE
                  TERMS. THE LIMITATIONS IN THIS SECTION 14 WILL APPLY EVEN IF
                  ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Arbitration.
              </p>
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Generally.
                  </p>
                  If you are not a resident of the United States, this Section
                  (Dispute Resolution and Arbitration) will only apply to you to
                  the extent applicable law in your country of residence
                  permits. In the interest of resolving disputes between you and
                  Encore in the most expedient and cost-effective manner, you
                  and Encore agree that any dispute arising out of or related to
                  these Terms or your use of the Service will be resolved by
                  binding arbitration. Arbitration uses a neutral arbitrator
                  instead of a judge or jury and can be subject to very limited
                  review by courts. Arbitrators can award the same damages and
                  relief that a court can award. This agreement to arbitrate
                  disputes includes all claims arising out of or in any way
                  related to these Terms or your use of the Service, whether
                  based in contract, tort, statute, fraud, misrepresentation, or
                  any other legal theory, and regardless of whether a claim
                  arises during or after the expiration or termination of these
                  Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE
                  TERMS, YOU AND ENCORE ARE EACH WAIVING THE RIGHT TO A TRIAL BY
                  JURY OR TO PARTICIPATE IN A CLASS OR COLLECTIVE ACTION. YOU
                  FURTHER UNDERSTAND THAT THIS SECTION WILL APPLY TO YOU.
                  <br />
                  <br />
                  Whether to agree to arbitration is an important decision. It
                  is your decision to make, and you should not rely solely on
                  the information provided in these Terms, as they are not
                  intended to contain a complete explanation of the consequences
                  of arbitration. You should take further steps to conduct
                  research and to consult with others, including an attorney,
                  regarding the consequences of your decision, just as you would
                  when making any other important business or life decision.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Exceptions.
                  </p>
                  Despite the provisions of Section 14.1 (Generally), nothing in
                  these Terms will be deemed to waive, preclude, or otherwise
                  limit the right of either party to: (1) bring an individual
                  action in small claims court; (2) bring an action in a court
                  pursuant to the California Private Attorneys General Act of
                  2004, California Labor Code § 2698 et seq.; (3) seek
                  injunctive relief in a court of law; or (4) file suit in a
                  court of law to address an intellectual property infringement
                  claim.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Arbitrator.
                  </p>
                  Any arbitration between you and Encore will be governed by the
                  Federal Arbitration Act, and governed by the Commercial
                  Dispute Resolution Procedures and the Supplementary Procedures
                  for Consumer Related Disputes (collectively, "
                  <p className="inline font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    AAA Rules
                  </p>
                  ") of the American Arbitration Association ( "{" "}
                  <p className="inline font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    AAA
                  </p>
                  " ), as modified by these Terms, and will be administered by
                  the AAA. The AAA Rules and filing forms are available online
                  at
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    className="inline mx-2 font-light underline c-primary lg:text-[24px] text-[18px]"
                    href="www.adr.org">
                    www.adr.org
                  </a>
                  , by calling the AAA at 1-800-778-7879, or by contacting
                  Encore. The arbitrator has exclusive authority to resolve any
                  dispute relating to the interpretation, applicability, or
                  enforceability of this binding arbitration agreement.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Notice; Process.
                  </p>
                  A party who intends to seek arbitration must first send a
                  written notice of the dispute to the other party within the
                  applicable statute of limitations period ("
                  <p className="inline font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Notice
                  </p>
                  "). Encore’s email address for Notice is hello@encore.fans.
                  The Notice must: (1) describe the nature and basis of the
                  claim or dispute; (2) set forth the specific relief sought;
                  and (3) if you are sending the Notice to Encore, include your
                  name and address ("
                  <p className="inline font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Demand
                  </p>
                  "). The parties will make good faith efforts to resolve the
                  claim directly, but if the parties do not reach an agreement
                  to do so within 45 days after the Notice is received, you or
                  Encore may commence an arbitration proceeding.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Confidentiality.
                  </p>
                  The parties agree to keep confidential the existence of the
                  arbitration, the arbitral proceedings, the submissions made by
                  the parties, and the decisions made by the arbitrator,
                  including its awards, except as required by applicable law and
                  to the extent not already in the public domain.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    No Class Actions.
                  </p>
                  YOU AND ENCORE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
                  OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A
                  PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                  REPRESENTATIVE PROCEEDING (OTHER THAN ACTIONS UNDER THE
                  CALIFORNIA PRIVATE ATTORNEYS GENERAL ACT OF 2004, CALIFORNIA
                  LABOR CODE § 2698 ET SEQ., WHICH ARE NOT COVERED BY THIS
                  SECTION 15 (ARBITRATION)). Unless both you and Encore agree
                  otherwise, the arbitrator may not consolidate more than one
                  person’s claims and may not otherwise preside over any form of
                  a representative or class proceeding.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Enforceability.
                  </p>
                  If Section 14.6 (No Class Actions) is found to be
                  unenforceable or if the entirety of this Section 14
                  (Arbitration) is found to be unenforceable, then the entirety
                  of this Section 14 will be null and void and the exclusive
                  jurisdiction and venue described in Section 19.2 (Governing
                  Law) will govern any action arising out of or related to these
                  Terms or your use of the Service.
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Cooperation with Authorities.
              </p>
              Encore may cooperate with legal authorities and/or third parties
              in the investigation of any suspected or alleged crime or civil
              wrong. Except as may be expressly limited by the Privacy Policy,
              Encore may disclose any information as Encore deems necessary to
              satisfy any applicable law, regulation, legal process, or
              governmental request, or to edit, refuse to post, or to remove any
              information or materials, in whole or in part, in Encore’s sole
              discretion.
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Compliance with Applicable Law.
              </p>
              You will comply with all applicable international, federal, state,
              provincial, and local laws, regulations, binding regulatory
              guidance, directives, and governmental requirements in connection
              with exercising your rights or performing your obligations under
              these Terms.
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Miscellaneous.
              </p>
              <ul className="pl-5 space-y-5 list-decimal lg:pl-16">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Subcontractors.
                  </p>
                  Encore may use subcontractors or other third parties to
                  perform its obligations under these Terms, but Encore will
                  remain responsible for all such obligations.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Governing Law.
                  </p>
                  These Terms are governed by New York law without reference to
                  its conflict of laws principles. The United Nations Convention
                  on Contracts for the International Sale of Goods will not
                  apply to these Terms. Subject to Section 15 (Arbitration), all
                  claims arising under these Terms will be litigated exclusively
                  in the federal or state courts of New York, New York. The
                  parties submit to the jurisdiction of those courts. In any
                  proceeding to enforce rights under these Terms, the prevailing
                  party will be entitled to recover costs and attorneys’ fees.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Injunctive Relief.
                  </p>
                  If you breach Sections 3 (Accounts; Registration;
                  Restrictions), 6 (Ownership), or 7 (Confidentiality), Encore
                  may suffer irreparable harm, and monetary damages may be
                  inadequate to compensate Encore. Accordingly, Encore may, in
                  addition to any other remedies available, seek injunctive or
                  other equitable relief in response to any such breach.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Further Assurances.
                  </p>
                  You agree to execute and deliver any documents or instruments,
                  and take any further actions that are reasonably required, to
                  provide the other party the full benefits and rights described
                  in these Terms.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Assignment.
                  </p>
                  You may not assign these Terms or delegate your performance
                  without Encore’s prior written consent, and any attempt to do
                  so is void. Encore may assign or transfer these Terms without
                  your consent. These Terms are binding upon and inure to the
                  benefit of the parties’ permitted successors and assigns.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Severability.
                  </p>
                  If any provision of these Terms or portion of a provision is
                  invalid, illegal, or unenforceable, the rest of these Terms
                  will remain in effect.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    No Waiver.
                  </p>
                  Neither party will be treated as having waived any rights by
                  not exercising (or delaying the exercise of) any rights under
                  these Terms.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Entire Agreement.
                  </p>
                  These Terms constitute the entire agreement and supersede any
                  other agreement of the parties relating to their subject
                  matter. No usage of trade or other regular practice or method
                  of dealing between the parties will be used to modify,
                  interpret, or supplement these Terms.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Nature of Rights.
                  </p>
                  The licenses granted under these Terms are rights in
                  “intellectual property” within the scope of Section 101 (or
                  its successors) of the United States Bankruptcy Code (the
                  “Code”). Each party as licensee will have and may fully
                  exercise all rights available to a licensee under the Code,
                  including under Section 365(n) or its successors.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Relationship.
                  </p>
                  Neither Encore’s provision of the Services to you, nor your
                  access to and use of the Service, creates any direct business
                  relationship between you and Encore. You acknowledge and agree
                  that these Terms are not an employment agreement, nor does it
                  create an employment or contractor relationship, between you
                  and Encore; and no joint venture, partnership, or agency
                  relationship exists between you and Encore. As a User, YOU ACT
                  EXCLUSIVELY ON YOUR OWN BEHALF AND FOR YOUR OWN BENEFIT, AND
                  NOT ON BEHALF OF OR FOR THE BENEFIT OF ENCORE. YOU AGREE NOT
                  TO DO ANYTHING TO CREATE A FALSE IMPRESSION THAT YOU ARE
                  ENDORSED BY, PARTNERING WITH, OR ACTING ON BEHALF OF OR FOR
                  THE BENEFIT OF ENCORE, INCLUDING BY INAPPROPRIATELY USING ANY
                  ENCORE IP.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    No Third-Party Beneficiaries.
                  </p>
                  There are no third-party beneficiaries of these Terms, except
                  as described in Section 21 (Notice Regarding Apple).
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Notices.
                  </p>
                  All notices under these Terms must be in writing, and will be
                  considered given: (1) upon delivery, if delivered personally
                  or by internationally recognized courier service; (2) three
                  business days after being sent, if delivered by U.S.
                  registered or certified mail (return receipt requested); or
                  (3) upon acknowledgement of receipt, if delivered by email.
                  Either party may update its notice address by notice to the
                  other party in accordance with this Section. All notices to
                  Encore will be sent to:
                  <br />
                  <br />
                  Encore Fans, Inc. 7004 Tavistock Lakes Blvd, Orlando, FL 32827
                  Email: hello@encore.fans Attn: Legal
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  <p className="italic inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                    Force Majeure.
                  </p>
                  Encore will not be liable for any delay or failure to perform
                  under these Terms as a result of any cause or condition beyond
                  Encore’s reasonable control (a “Force Majeure Event”), so long
                  as Encore uses reasonable efforts to avoid or remove those
                  causes of delay or non-performance. If a Force Majeure Event
                  causes Encore to delay or fail to perform its obligations
                  under these Terms for 30 consecutive days, either party may
                  terminate these Terms.
                </li>
              </ul>
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                NOTICE FOR CALIFORNIA USERS.
              </p>
              Under California Civil Code Section 1789.3, Users located in
              California are entitled to the following notice: The Complaint
              Assistance Unit of the Division of Consumer Services of the
              California Department of Consumer Affairs may be contacted in
              writing at 400 R Street, Suite 1080, Sacramento, California 95814,
              or by telephone at (916) 445-1254 or (800) 952-5210.
            </li>
            <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              <p className="inline mr-2 font-medium text-[#140943] lg:text-[24px] text-[18px]">
                Contact Us.
              </p>
              If you have any questions about these Terms or otherwise need to
              contact us for any reason, you can reach us at
              <a
                rel="noreferrer"
                target={"_blank"}
                href="mailto:hello@encore.fans"
                className="inline ml-2 font-light c-primary lg:text-[24px] text-[18px]">
                hello@encore.fans
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default TermsConditions;
