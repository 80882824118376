
import infinite_avail from "@assets/infinite_avail.png";
import finite_avail from "@assets/svgs/finite_avail.svg";
import finite_availed from "@assets/svgs/finite_availed.svg";
type Props = {
  counter: boolean;
  disabled: boolean;
};
const LimtCounter: React.FC<Props | any> = props => {
    const marginLeft = ((props.counter).toString().length)*5;
    return (
        <>
            {
                props.counter == -1?
                    <img src={infinite_avail} className="w-[31px]" />
                :    
                    <div className={`w-[25px] relative `} style={{marginLeft}}>
                        <span className={`absolute right-[18px] -top-[3px] ${props.disabled?"text-white/40":"text-[#44CD51]"}   text-[12px]`}>{props.counter}</span>
                        <img src={ props.disabled ?finite_avail:finite_availed} className="w-[25px]" />
                    </div>
            }
           
        </>
    )

}

export default LimtCounter;