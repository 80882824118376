import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ProgramBox from "@components/ProgramBox";
import mTouchpoints from '@model/touchpoints-model';

type Props = {}

const EnterToWinAside = (props: Props) => {

    const [filter, setFilter]:any = useState({});
    const touchpoints = useSelector((state:any)=>(state.EarnReward.data || []));

    useEffect(()=>{
        setFilter(mTouchpoints.getFilterData())
    },[touchpoints])


    return (
        <div className='flex justify-between flex-col items-start mb-4'>
            <div>
            <h1 className='lg:text-[42px] body-inlay-primary md:text-[42px] text-[31px] lg:whitespace-normal whitespace-nowrap font-semibold leading-10'>
            <ProgramBox box="DASHBOARD_HEADING" winnerList={filter?.isRequiredCompleted} />
          </h1>
                <p className='lg:text-[28px] body-inlay-primary md:text-[28px] text-[24px] font-semibold leading-9'><ProgramBox box="DASHBOARD_SUBHEADING"/></p>
                <p className='font-normal body-inlay-primary lg:text-[20px] md:text-[20px] text-[16px] leading-7 mt-5 w-full max-w-[350px]'>
                <ProgramBox box="DASHBOARD_DESCRIPTION" winnerList={filter?.isRequiredCompleted} /></p>
            </div>
            <div className='shadow-[0px_6px_41px_0px_#0000001F] overflow-hidden hidden bg-tile relative h-32 w-32 lg:flex justify-center items-center rounded-full'>
                {
                    filter?.isRequiredCompleted ? <div className='bg-btn h-full w-full flex justify-center items-center'>
                        <p className='font-semibold text-[20px] tile-inlay-primary text-center leading-6'>You're on the list</p>
                    </div> : 
                    <>
                        <svg className="w-full h-full">
                            <circle
                                className="ring-color opacity-30"
                                strokeWidth="6"
                                stroke="currentColor"
                                fill="transparent"
                                r="40"
                                cx="60"
                                cy="60"
                            />
                            <circle
                                className="ring-color"
                                strokeWidth="6"
                                strokeDasharray={40 * 2 * Math.PI}
                                strokeDashoffset={`${40 * 2 * Math.PI -
                                    (filter?.progressPercentage / 100) * (40 * 2 * Math.PI)
                                    }`}
                                stroke="currentColor"
                                fill="transparent"
                                r="40"
                                cx="60"
                                cy="60"
                            />
                        </svg>
                        <div
                            className="absolute flex justify-center items-center top-0 w-full h-full m-auto cursor-pointer"
                        >
                            
                            <p className='font-semibold text-[22px] tile-inlay-primary leading-5'>{`${filter?.progressPercentage}%`}</p>
                        </div>
                    </>
                }
            </div>
            <div className='shadow-[0px_6px_41px_0px_#0000001F] overflow-hidden lg:hidden fixed z-50 bottom-5 right-3 bg-tile h-[88px] w-[88px] flex justify-center items-center rounded-full'>
                {
                    filter?.isRequiredCompleted ? <div className='bg-tile h-full w-full flex justify-center items-center'>
                    <p className='font-semibold text-[16px] tile-inlay-primary text-center leading-4'>You're on the list</p>
                </div> : 
                    <>      
                        <svg className="w-full h-full">
                            <circle
                                className="ring-color opacity-30"
                                strokeWidth="6"
                                stroke="currentColor"
                                fill="transparent"
                                r="33"
                                cx="44"
                                cy="44"
                            />
                            <circle
                                className="ring-color"
                                strokeWidth="6"
                                strokeDasharray={33 * 2 * Math.PI}
                                strokeDashoffset={`${33 * 2 * Math.PI -
                                    (filter?.progressPercentage / 100) * (33 * 2 * Math.PI)
                                    }`}
                                stroke="currentColor"
                                fill="transparent"
                                r="33"
                                cx="44"
                                cy="44"
                            />
                        </svg>
                        <div
                            className="absolute flex justify-center items-center top-0 w-full h-full m-auto cursor-pointer"
                        >
                            <p className='font-semibold tile-inlay-primary text-[15px] leading-5'>{filter?.progressPercentage}%</p>
                        </div>
                    </>
                }
            </div>
            
        </div>
    )
}

export default EnterToWinAside