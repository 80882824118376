import { EarnItemCard } from "@components/EarnItemCard";
import { Item } from "@components/EarnItemCard/types";
import { PhoneInput } from "@components/PhoneInput";
import { useState } from "react";
import { ResendBanner } from "@components/ResendBanner";
import { VerificationCode } from "@components/VerificationCodeForm";
import Mixpanel from "@services/mixpanel-events";
import { formatPhoneNumber } from "@helpers/common";

interface SmsCardProps {
  item?: Item;
  value?:string;
  disabledCard?:boolean;
  onAuthorize?: (phone: string) => void;
  disabledInput?:boolean;
  onSubmitPhoneInput?: (phone: string) => void;
  // This value is for a button that allows the user to
  // reset the phone it is not onChange for the input
  onChangePhoneInput?: () => void;
  onResendCode?: () => void;
  onSubmitConfirmationCode?: (code: string) => void;
  phoneInputError?: string;
  confirmCodeError?: string;
  submittedPhone: string | null;
  isLoadingSubmitPhone?: boolean;
  isLoadingSubmitCode?: boolean;
}

export const SmsCard = ({
  item,
  value,
  disabledInput,
  disabledCard,
  phoneInputError,
  confirmCodeError,
  onSubmitPhoneInput,
  onChangePhoneInput,
  onResendCode,
  onSubmitConfirmationCode,
  onAuthorize,
  submittedPhone,
  isLoadingSubmitPhone,
  isLoadingSubmitCode,
}: SmsCardProps) => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle((prev) => !prev);
    if (!toggle) {
      Mixpanel.send("Action Tapped", {action_name: item?.title})
    }
  };

  const fanEngagement = item?.shares?.fanEngagement || 0;
  const limitOfTask = item?.config?.limit || 0;
  const showTask = (()=>{
    // Negative value means it can try
    // as many times as the user wants
    if(limitOfTask < 0) {
      return true
    }
    return fanEngagement < limitOfTask
  })()

  return (
    <EarnItemCard
      onClick={handleToggle}
      isOpened={toggle &&  showTask}
      title={item?.title || ""}
      item={item}
      disabledCard={disabledCard}
      description={formatPhoneNumber(value)}
    >
      <div className="mt-2">
        <PhoneInput
          onSubmit={submittedPhone ? onChangePhoneInput : disabledInput?onAuthorize:onSubmitPhoneInput}
          error={phoneInputError}
          buttonLabel={submittedPhone && !phoneInputError ? "CHANGE" : "SUBMIT"}
          isLoading={
            (!submittedPhone || !!phoneInputError) && isLoadingSubmitPhone
          }
          phoneNumber={value}
          disabledInput={disabledInput}
        />
      </div>
      {submittedPhone && !phoneInputError && (
        <>
          <div>
            <VerificationCode
              onSubmit={onSubmitConfirmationCode}
              isLoading={isLoadingSubmitCode}
              codeError={confirmCodeError}
            />
          </div>
          <ResendBanner
            onResendCode={onResendCode}
            isLoading={!!submittedPhone && isLoadingSubmitPhone}
          />
        </>
      )}
    </EarnItemCard>
  );
};
