import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface AuthProps { }

const Redirect: React.FC<AuthProps | any> = (props) => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const url = searchParams.get('url');
		if (url) {
			// Create an anchor element and click it programmatically
			const anchor = document.createElement('a');
			anchor.href = url;
			anchor.target = '_self'; // Ensure it targets the current window
			document.body.appendChild(anchor); // Append to the document
			anchor.click(); // Simulate click
			document.body.removeChild(anchor); // Clean up
			// window.location.href = url; // Perform the redirect
		} else {
			navigate('/'); // Navigate back to the homepage or any other fallback
		}
	}, [location, navigate]);

	return <div>Redirecting...</div>;
};

export default Redirect;
