import { useState, useEffect } from "react";

interface UseAsyncProps<T, F> {
  asyncFn: () => Promise<T>;
  skip?: boolean;
  onSuccess?: (data: T) => Promise<any>;
}

export const useAsync = <T, F>({
  asyncFn,
  skip = false,
  onSuccess,
}: UseAsyncProps<T, F>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<F | null>(null);
  const [data, setData] = useState<T | null>(null);
  // This is a mechanism for force to re do the same task an other time
  // as the state changes the useEffect will be executed again
  // this must be a dependency on the useEffect
  // We set a number in order to re do the task many times
  const [forceReDo, setForceReDo] = useState(0);

  useEffect(() => {
    if (skip) {
      return;
    }
    setIsError(false);
    setError(null);
    setIsLoading(true);
    asyncFn()
      .then(async (data) => {
        setData(data);
        if (onSuccess) {
          await onSuccess(data);
        }
      })
      .catch((err) => {
        setIsError(true);
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [asyncFn, skip, forceReDo]);

  const reDoTask = () => {
    setForceReDo((prev) => prev + 1);
  };

  return {
    isLoading,
    error,
    isError,
    data,
    reDoTask,
  };
};
