import { calculateRedumptionTiime } from "@helpers/common";

const TextCompleted = ({item}) => {

    const config = calculateRedumptionTiime(
        item?.config,
        item?.shares?.lastEngagement,
        item?.shares?.fanEngagement,
        item?.totalEngagements,
        -1
      );
    
      const disabled = config?.isDisabled;

    return (
        <>
        {item?.shares?.fanEngagement>0 && !disabled &&
        <>
        <div className="divide-y h-[1px] w-full bg-gray-600 mt-2 mb-[20px] bg-opacity-40" />
        <div className="bg-[#38BC45] text-[#2CAB38] bg-opacity-20 rounded-full py-1.5 px-[15px] flex items-center justify-center">
            {item?.config?.limit>1 ?
            <p>You can complete this item {parseInt(item?.config?.limit) - parseInt(item?.shares?.fanEngagement)} more times.</p>
            :item?.config?.supply>1? <p>You can complete this item {parseInt(item?.config?.supply) - parseInt(item?.shares?.fanEngagement)} more times.</p>:
            <p className="text-center">You can complete this item as many times as you'd like.</p>}
        </div>
</>
         }
        </>
    );
};
export default TextCompleted;
