import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Logo from "@components/Logo";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "@components/Loader";
import NotificationService from "@services/notification-service";
import ProgramBox from "@components/ProgramBox";
import { isMobileName, isProgramActive, klaviyoEvent } from "@helpers/common";
import Api from "@services/api";
import Emitter from "@services/emitter-service";
import Mixpanel from "@services/mixpanel-events";
import Management from "@services/management-service";

type Props = {};

const FanLogin = (props: Props | any) => {
const { actions } = require("@redux/CreatorRedux");
	const { actions: userAction } = require("@redux/UserRedux");
	const dispatch = useDispatch();
	const rnavigate = useNavigate();
	const gLoader = useSelector((state: any) => state.loader.loader);
	const program = useSelector((state: any) => state.creator?.program);
	const session = useSelector((state: any) => state.user.session);
	const token = useSelector((state: any) => state.user?.token);
	const [contact, setContact] = useState("");
	const [contactError, setContactError] = useState("");
	const [codeError, setCodeError] = useState("");
	const [code, setCode] = useState("");
	const [step, setStep] = useState(false);
	const [loader, setLoader] = useState(false);
	const [verificationLoader, setVerificationLoader] = useState(false);
	const { cname, pname } = useParams();
	const creator = useSelector((state: any) => state.creator);
	const creator_id = useSelector((state: any) => creator.creator.id);
	const programId = useSelector((state: any) => creator.program.id);
	const isActive = isProgramActive(program);
	const fdata = useSelector((state: any) => Object.assign({}, state.user.form));
	const formp = useSelector((state: any) => Object.assign({}, state.user.form));
	const auth = Management.get()?.program?.config?.auth ?? "email";
	const authType = auth === "phoneNumber" ? "sms" : "email";
	const authText = auth === "phoneNumber" ? "phone number" : "email address";

	useEffect(() => {
		const fetchProgram = async () => {
			dispatch({ type: "SET_GLOBAL_LOADER", data: true });
			const params = { creatorUn: cname, programName: pname };
			await actions.fetchFanProgram(dispatch, params);
			dispatch({ type: "SET_GLOBAL_LOADER", data: false });

		};
		if (pname) {
			fetchProgram();
		}
	}, [pname]);


	useEffect(() => {
		if (!isActive) {
			rnavigate(`/${cname}/${pname}`);
		}
		if (!session?.[cname]?.[pname] && token) {
			rnavigate(`/auth`);
		}
	}, [program])

	const animations = {
		initial: { opacity: 0, x: 100 },
		animate: { opacity: 1, x: 0 },
		exit: { opacity: 0, x: -100 },
	};

	const handleContactSubmit = async () => {
		if (!validation(contact)) return;

		Mixpanel.send(`${auth === "phoneNumber" ? "Phone Number": "Email"} Entered`, null, 
		{[auth === "phoneNumber" ? "phone": "email"]: contact})
		setLoader(true);
		const data = {
			type: authType,
			query: "sendToken",
			[auth]: contact
		};
		let res = null;
		if (auth === "phoneNumber") {
			res = await Api.phoneAuthentication(data);
		} else {
			res = await Api.emailAuthentication(data);
		}
		setLoader(false);
		if (res?.status === 200) {
			setStep(true);
		} else {
			Mixpanel.send("Error Recieved", {page_path:'/login', error_message:res?.data?.message?.[0] || `Invalid ${authText}`})
			setContactError(res?.data?.message?.[0] || `Invalid ${authText}`);
		}
	};

	const authenticate = async (token) => {
		Api.setClientToken(token);
		setLoader(true)
		const json = await userAction.authenticate(
			dispatch,
			null,
			"fans",
			creator_id,
			token,
			programId,
			cname,
			pname
		);
		setLoader(false)
		if (json?.status == 404 || json?.status == 207) {
			let form = fdata;
				form = {
					...form,
					[auth]: contact,
					token:token
				};
				await dispatch(userAction.addForm(form));
				rnavigate(`/auth#access_token=${token}`)
		} else if (json?.status == 200) {
			klaviyoEvent(auth === "email" ? "email": 'phone_number', contact);
			setTimeout(() => Mixpanel.send("Logged In"), 1000)
			rnavigate(`/${cname}/${pname}/earn`);
		}
	};

	const resendHandle = async () => {
	setVerificationLoader(true);
	const data = {
		type: authType,
		query: "sendToken",
		[auth]: contact,
	};
	let res = null;
	if (auth === "phoneNumber") {
		res = await Api.phoneAuthentication(data);
	} else {
		res = await Api.emailAuthentication(data);
	}
	setVerificationLoader(false);
	if (res?.status === 200) {
		NotificationService.success(`A verification code has been sent to your ${authText}.`)
		setContactError("");
		setStep(true);
	} else {
		NotificationService.error(`An error occurred; please enter valid ${authText}.`)
		setContactError(res?.data?.message?.[0] || `Invalid ${authText}`);
	}
};

	const handleLogin = async () => {
		setCodeError("");
		// Validate code input
		if (!codeValidation(code)) return;
		setLoader(true);
		Mixpanel.send("Code Entered")
		const data = {
			type: authType,
			query: "verifyToken",
			[auth]: contact,
			verifyCode: code,
			encore_hosted: true,
		};
		let res = null;
		if (auth === "phoneNumber") {
			res = await Api.phoneAuthentication(data);
		} else {
			res = await Api.emailAuthentication(data);
		}
		setLoader(false);
		if (res?.status === 200) {
			authenticate(res?.data?.jwtToken);
			setCodeError("");
		} else {
			Mixpanel.send("Error Recieved", {page_path:'/login', error_message:'Invalid verification code'})
			setCodeError("Invalid verification code");
		}
	};

	const validation = (value: any) => {
		let validRegex = /[]/;
		if (auth === "phoneNumber") {
			validRegex = /^(\+?(\d{1,3}))?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
		} else {
			validRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		}

		if (!validRegex.test(value)) {
			setContactError(`Invalid ${authText}`);
			return false;
		} else {
			setContactError("");
			return true;
		}
	};

	const codeValidation = (value: string) => {
		const validCodeRegex = /^\d{6}$/;

		if (value.length === 0) {
			setCodeError("Verification code is required");
			return false;
		} else if (!validCodeRegex.test(value)) {
			setCodeError("Invalid verification code");
			return false;
		} else {
			setCodeError("");
			return true;
		}
	};

	const onChangeContact = () => {
		setStep(false);
	}

	return (
		<>
			{
				gLoader ?
					<div className="bg-body flex items-center justify-center overflow-hidden h-[100vh] max-h-[100vh] relative">
						<Loader height={30} width={30} />
					</div>
					:
					<motion.div
						variants={animations}
						initial="initial"
						animate="animate"
						exit="exit"
						transition={{ duration: 0.5 }}
					>
						<div className={`flex flex-col h-screen overflow-auto`}>
							<div className={`flex flex-col grow lg:py-10 md:py-10 py-5`}>
								<Logo
									className="lg:h-[135px] lg:w-[135px] h-auto rounded-full w-[100px] object-contain mx-auto"
									alt="userimage"
								/>
								<div className={`flex flex-col ${step ? 'lg:justify-center' : ''} grow justify-start`}>
									<div className="flex justify-center items-center my-5 w-full">
										<div className="lg:max-w-[500px] w-full max-w-[350px] items-center px-3 flex flex-col">
											<div className="flex flex-col gap-3 items-center">
												<>
													<h1 className="body-inlay-primary font-medium tracking-[0.02em] text-[18px] lg:text-[24px] leading-6 mx-auto text-center">
														What’s your {authText}?
													</h1>
													<p className="font-normal text-[15px] tracking-[0.02em] lg:text-[16px] leading-5 body-inlay-primary opacity-80">
														We’ll send you a verification code via {authType}.
													</p>
												</>
											</div>
											<div className="mt-7 w-full flex justify-center items-center">
												<input
													onChange={(e) => setContact(e.target.value)}
													onKeyDown={(e) => {
														if (e.keyCode === 13) {
															handleContactSubmit()
														}
													}}
													type={auth === "phoneNumber" ? "tel":"email"}
													placeholder={auth === "phoneNumber" ? "+1 555-555-555":"hello@me.com"}
													disabled={step}
													className="w-full max-w-[350px] bg-tile-form mx-auto h-[50px] px-3 text-center rounded-[10px] lg:text-[18px] text-[16px] placeholder:opacity-30 placeholder:font-normal font-normal tile-inlay-primary shadow-none outline-none"
												/>
											</div>
											{contactError && (
												<p className="font-normal text-[16px] text-center text-red-500 ">
													{contactError}
												</p>
											)}
											{contact !== "" && !step && (
											<button
												disabled={loader}
												onClick={handleContactSubmit}
												className="btn-inlay-primary bg-btn hover:opacity-80 rounded-full mx-2 mt-8 h-[39px] w-[85px] font-semibold text-[16px] lg:text-[18px]"
											>
												{loader ? (
													<Loader height={30} width={30}/>
												) : (
													"send"
												)}
											</button>
											)}
										</div>
									</div>
									{step &&
										<div className="flex justify-center items-center my-5 w-full">
											<div className="lg:max-w-[500px] w-full max-w-[350px] items-center px-3 flex flex-col">
												<div className="flex flex-col gap-3 items-center">
													<>
														<h1 className="body-inlay-primary font-medium tracking-[0.02em] text-[18px] lg:text-[24px] leading-6 lg:leading-8 mx-auto text-center">
															Please enter your code.
														</h1>
														<p className="font-normal text-center text-[15px] lg:text-[16px] leading-5 body-inlay-primary opacity-80 max-w-[320px]">
															Enter the six-digit code you just received via {authType}.
														</p>
													</>
												</div>
												<div className="mt-7 w-full flex justify-center items-center">
													<input
														onChange={(e) => setCode(e.target.value)}
														onKeyDown={(e) => {
															if (e.keyCode === 13) {
																handleLogin()
															}
														}}
														type="text"
														placeholder="XXXXXX"
														className="w-full max-w-[360px] bg-tile-form mx-auto h-[50px] px-3 text-center rounded-[10px] lg:text-[18px] text-[16px] placeholder:opacity-30 placeholder:font-normal font-normal tile-inlay-primary shadow-none outline-none"
													/>
												</div>
												{codeError && (
													<p className="text-[16px] text-center font-normal text-red-500 ">
														{codeError}
													</p>
												)}
												<p className="font-normal text-[15px] tracking-[0.02em] lg:text-[16px] leading-5 body-inlay-primary text-center mt-6">Didn't receive a code?</p>
												{verificationLoader ? (
													<div className="mt-2" >
														<Loader height={30} width={30}/>
													</div>
												) : (
													<>
														<p
															onClick={resendHandle}
															className="font-[700] text-[15px] lg:text-[16px] leading-5 tracking-[0.02em] body-inlay-primary text-center cursor-pointer"
														>
															Resend
														</p>
													</>
												)}
												<div>
											{code !== "" ? (
												<button
													disabled={loader || (code || "").length < 6}
													onClick={handleLogin}
													className="btn-inlay-primary bg-btn hover:opacity-80 rounded-full mx-2 mt-6 h-[39px] w-[113px] font-semibold text-[16px] lg:text-[18px]"
												>
													{loader ? (
														<Loader height={30} width={30}/>
													) : (
														"continue"
													)}
												</button>

											) : <button
												onClick={onChangeContact}
												className="btn-inlay-primary bg-btn hover:opacity-80 rounded-full mx-2 mt-6 h-[39px] w-[85px] font-semibold text-[16px] lg:text-[18px]"
											>
												back

											</button>}
										</div>
										</div>
										</div>
									}
								</div>
							</div>
							<div className="flex flex-col grow m-0 p-0 items-center justify-end">		
							<div className="pb-[32px]">
								<ProgramBox box="LOGIN_FOOTER" etw={true} />
							</div>
						</div>
						</div>
					</motion.div>
			}
		</>
	);
};

export default FanLogin;
