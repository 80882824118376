import React from "react";
import logo from "@assets/logo_trade.png";
import LinkTo from "@components/LinkTo";
import left from "@assets/left.png";

interface Props {}
interface State {}

class PrivacyPolicy extends React.Component<Props | any, State> {
  state: State = {};

  render() {
    return (
      <div className="bg-[#f6f2e9] h-full 2xl:py-16 py-10 2xl:pb-5">
        <div className="container mx-auto">
          <div className="flex items-center justify-center">
            <div className="w-44">
              <LinkTo
                text=""
                to={"/"}
                prefix={
                  <img
                    src={left}
                    alt="Close"
                    className="object-contain w-4 h-4"
                  />
                }
                className="flex items-center justify-center w-8 h-8 rounded-full bg-[#f6104e]"
              />
            </div>
            <img
              alt={"logo"}
              src={logo}
              className="lg:w-[220px] w-[150px] object-contain mx-auto"
            />
            <div className="flex justify-end text-right w-44">
              <LinkTo
                text="terms & conditions"
                to="/p/terms-conditions"
                className="hover:text-[#f6104e] underline font-medium text-[18px] tile-inlay-primary lg:flex hidden items-center"
              />
            </div>
          </div>

          <h2 className="lg:mt-[80px] mt-[30px] text-black font-semibold lg:text-[34px] text-[24px] lg:max-w-[697px] max-w-[299px] lg:leading-tight leading-10 mx-auto text-center">
            Privacy Notice
          </h2>
          <p className="italic font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
            Last Updated: July, 2022
          </p>
          <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
            We understand that your data is personal, and important to you.
            Everything we do at Encore, is designed to create a
            better experience for you. Part of improving that experience is
            understanding who you are and what you're interested in. To learn
            this we collect data throughout the process. Please review the below
            for details.
          </p>
          <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
            In addition to the activities described in this Privacy Notice, we
            may process Personal Information on behalf of our commercial
            customers when they use the Service. We process such Personal
            Information as a data processor of our commercial customers, which
            are the entities responsible for the data processing. To understand
            how a commercial customer processes your Personal Information,
            please refer to that customer’s privacy policy.{" "}
          </p>

          <ul className="list-decimal list-inside space-y-8  mt-[20px]">
            <li className="font-normal text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              PERSONAL INFORMATION WE MAY COLLECT
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                For the purpose of this Privacy Notice, “Personal Information”
                means any information relating to an identified or identifiable
                individual. If you do not provide Personal Information when
                requested, you may not be able to benefit from our Service if
                that information is necessary to provide you with the service or
                if we are legally required to collect it.
              </p>
            </li>

            <li className="font-normal text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              PERSONAL INFORMATION PROVIDED BY YOU
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Registration. If you desire to have access to certain restricted
                information or request to receive marketing materials, you may
                be required to become a registered user, and to submit the
                following types of Personal Information to Encore:
              </p>
              <ul className="list-disc list-inside pl-10  mt-[20px]">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  Your name
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  Email address
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  Phone number
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  User name
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  Password
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  Address, City, State and Zip
                </li>
              </ul>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Customer Support. We may collect information through your
                communications with our customer support team or other
                communications that you may send us and their contents.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Other. We may also collect your contact details when you provide
                them in the context of our customer, vendor, and partner
                relationships.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Personal Information Automatically Obtained from Your
                Interactions with the Service Log Data. When you use our
                Service, our servers automatically record information that your
                browser sends whenever you visit a website (“Log Data”). This
                Log Data may include information such as your IP address,
                browser type or the domain from which you are visiting, the
                web-pages you visit, the search terms you use, and any
                advertisements on which you click. Cookies and Similar
                Technologies. Like many websites, we may also use “cookie”
                technology to collect additional website usage data and to
                improve the Site and our Service. A cookie is a small data file
                that we transfer to your computer’s hard disk. A session cookie
                enables certain features of the Site and our service and is
                deleted from your computer when you disconnect from or leave the
                Site. A persistent cookie remains after you close your browser
                and may be used by your browser on subsequent visits to the
                Site. Persistent cookies can be removed by following your web
                browser help file directions. Most Internet browsers
                automatically accept cookies. Encore may use both session
                cookies and persistent cookies to better understand how you
                interact with the Site and our Service, to monitor aggregate
                usage by our users and web traffic routing on the Site, and to
                improve the Site and our Service.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                We may also automatically record certain information from your
                device by using various types of technology, including “clear
                gifs” or “web beacons.” This automatically collected information
                may include your IP address or other device address or ID, web
                browser and/or device type, the web pages or sites that you
                visit just before or just after you use the Service, the pages
                or other content you view or otherwise interact with on the
                Service, and the dates and times that you visit, access, or use
                the Service. We also may use these technologies to collect
                information regarding your interaction with email messages, such
                as whether you opened, clicked on, or forwarded a message, to
                the extent permitted under applicable law.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                You can instruct your browser, by editing its options, to stop
                accepting cookies or to prompt you before accepting a cookie
                from the websites you visit. Please note that if you delete, or
                choose not to accept, cookies from the Service, you may not be
                able to utilize the features of the Service to their fullest
                potential.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Do Not Track. Encore does not process or respond to web
                browsers’ “do not track” signals or other similar transmissions
                that indicate a request to disable online tracking of users who
                use our Service.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Third Party Web Beacons and Third Party Buttons. We may display
                third-party content on the Service, including third-party
                advertising. Third-party content may use cookies, web beacons,
                or other mechanisms for obtaining data in connection with your
                viewing of the third party content on the Service. Additionally,
                we may implement third party buttons, such as Facebook “share”
                buttons, that may function as web beacons even when you do not
                interact with the button. Information collected through
                third-party web beacons and buttons is collected directly by
                these third parties, not by Encore. Please consult such third
                party’s data collection, use, and disclosure policies for more
                information.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Links to Other Websites. Our Site may contain links to other
                websites. The fact that we link to a website is not an
                endorsement, authorization or representation of our affiliation
                with that third party. We do not exercise control over third
                party websites. These other websites may place their own cookies
                or other files on your computer, collect data or solicit
                Personal Information from you. Other sites follow different
                rules regarding the use or disclosure of the Personal
                Information you submit to them. We are not responsible for the
                content, privacy and security practices, and policies of
                third-party sites or services to which links or access are
                provided through the Service. We encourage you to read the
                privacy policies or statements of the other websites you visit.
              </p>
            </li>

            <li className="font-normal text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              HOW WE MAY USE YOUR PERSONAL INFORMATION
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                We may use the Personal Information we obtain about you to:
              </p>
              <ul className="list-disc list-outside pl-10  mt-[2px]">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  create and manage your account, provide our Service, process
                  payments, and respond to your inquiries.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  manage account authentication such as two-factor
                  authentication
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  communicate with you to verify your account and for
                  informational and operational purposes, such as account
                  management, customer service, or system maintenance, including
                  by periodically emailing you service-related announcements.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  tailor our Service (e.g., we may use cookies and similar
                  technologies to remember your preferences).
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  provide tailored advertising, for Encore services.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  aggregate your Personal Information for analytical purposes.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  provide customer support.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  operate, evaluate and improve our business (including by
                  developing new products and services; managing our
                  communications; determining the effectiveness of our
                  advertising; analyzing how the Service is being accessed and
                  used; tracking performance of the Service; debugging the
                  Service; facilitating the use of our Service).
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  send you marketing communications about products, services,
                  offers, programs and promotions of Encore, and affiliated
                  companies.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  ensure the security of our Service.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  manage our customer, service provider and partner
                  relationships.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  enforce our agreements related to our Service and our other
                  legal rights and
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  comply with applicable legal requirements, industry standards
                  and our policies.
                </li>
              </ul>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                If you are located in the European Economic Area, we may process
                your Personal Information for the above purposes when:
              </p>
              <ul className="list-disc list-outside pl-10  mt-[2px]">
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  you have consented to the use of your Personal Information,
                  For example, we may seek to obtain your consent for our uses
                  of cookies or similar technologies, or to send you marketing
                  communications.
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  we need your Personal Information to provide you with services
                  and products requested by you, or to respond to your
                  inquiries,
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  we have a legal obligation to use your Personal Information,
                  or
                </li>
                <li className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7">
                  we have a legitimate interest in using your Personal
                  Information. In particular, we have a legitimate interest in
                  using your Personal Information to ensure and improve the
                  safety, security, and performance of our Service, to anonymize
                  Personal Information and carry out data analyses.
                </li>
              </ul>
            </li>

            <li className="font-normal text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              HOW WE SHARE YOUR PERSONAL INFORMATION
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                We may disclose the Personal Information we collect about you as
                described below or otherwise disclosed to you at the time the
                data is collected, including with:
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Customers
                <br />
                Our primary purpose for using information is to help our
                customers understand their fans better and to enable fans to
                interact with their content. We may collect information about
                what you are viewing and what you interact with to better
                understand your interests. This information may be shared with
                our customers and once shared its use will be governed by their
                privacy policy.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Service Providers <br />
                We engage certain trusted third parties to perform functions and
                provide services to us, including hosting and maintenance, error
                monitoring, debugging, performance monitoring, billing, customer
                relationship, database storage and management, and direct
                marketing campaigns. We may share your Personal Information with
                these third parties, but only to the extent necessary to perform
                these functions and provide such services. We also require these
                third parties to maintain the privacy and security of the
                Personal Information they process on our behalf.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Compliance with Laws and Law Enforcement
                <br />
                Encore cooperates with government and law enforcement officials
                or private parties to enforce and comply with the law. To the
                extent permitted under applicable law, we may disclose any
                information about you to government or law enforcement officials
                or private parties as we believe is necessary or appropriate to
                investigate, respond to, and defend against claims, for legal
                process (including subpoenas), to protect the property and
                rights of Encore or a third party, to protect Encore against
                liability, for the safety of the public or any person, to
                prevent or stop any illegal, unethical, fraudulent, abusive, or
                legally actionable activity, to protect the security or
                integrity of the Service and any equipment used to make the
                Service available, or to comply with the law.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Business Transfers <br />
                Encore may sell, transfer or otherwise share some or all of its
                assets, including Personal Information, in connection with a
                merger, acquisition, reorganization, sale of assets, or similar
                transaction, or in the event of insolvency or bankruptcy.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Other Third Parties
                <br />
                We may share Personal Information with our headquarters and
                affiliates, and business partners to whom it is reasonably
                necessary or desirable for us to disclose your data for the
                purposes described in this Privacy Notice. We may also make
                certain non-Personal Information available to third parties for
                various purposes, including for business or marketing purposes
                or to assist third parties in understanding our users’ interest,
                habits, and usage patterns for certain programs, content,
                services, advertisements, promotions, and functionality
                available through the Service.
              </p>
            </li>

            <li className="font-normal text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              HOW WE PROTECT YOUR PERSONAL INFORMATION
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Encore is very concerned about safeguarding the confidentiality
                of your Personal Information. We employ administrative and
                electronic measures designed to appropriately protect your
                Personal Information against accidental or unlawful destruction,
                accidental loss, unauthorized alteration, unauthorized
                disclosure or access, misuse, and any other unlawful form of
                processing of the Personal Information in our possession. Please
                be aware that no security measures are perfect or impenetrable.
                We cannot guarantee that information about you will not be
                accessed, viewed, disclosed, altered, or destroyed by breach of
                any of our administrative, physical, and electronic safeguards,
                subject to requirements under applicable law to ensure or
                warrant information security.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                We will make any legally-required disclosures of any breach of
                the security, confidentiality, or integrity of your unencrypted
                electronically stored Personal Information to you via email or
                conspicuous posting on our Site in the most expedient time
                possible and without unreasonable delay, consistent with (i) the
                legitimate needs of law enforcement or (ii) any measures
                necessary to determine the scope of the breach and restore the
                reasonable integrity of the data system , and any other
                disclosures that may be required under applicable law.
              </p>
            </li>

            <li className="font-normal text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              YOUR RIGHTS AND CHOICES
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                If you decide at any time that you no longer wish to receive
                such marketing communications from us, please follow the
                unsubscribe instructions provided in any of the communications.
                You may also opt out from receiving commercial email from us by
                sending your request to us by email at{" "}
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="mailto:  hello@encore.fans"
                  className="inline ml-2 font-light c-primary lg:text-[24px] text-[18px]">
                  hello@encore.fans
                </a>
                . Please be aware that, even after you opt out from receiving
                commercial messages from us, you will continue to receive
                administrative messages from us regarding the Service.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                In certain jurisdictions you have the right to request access
                and receive information about the Personal Information we
                maintain about you, to update and correct inaccuracies in your
                Personal Information, to restrict or object to the processing of
                your Personal Information, to have the information blocked,
                anonymized or deleted, as appropriate, or to exercise your right
                to data portability to transfer your Personal Information to
                another company. Those rights may be limited in some
                circumstances by local law requirements. In addition to the
                above-mentioned rights, you also have the right to lodge a
                complaint with a competent supervisory authority subject to
                applicable law.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Where required by law, we obtain your consent for the processing
                of certain Personal Information collected by cookies or similar
                technologies, or used to send you direct marketing
                communications, or when we carry out other processing activities
                for which consent may be required. If we rely on consent for the
                processing of your Personal Information, you have the right to
                withdraw it at any time and free of charge. When you do so, this
                will not affect the lawfulness of the processing before your
                consent withdrawal.
              </p>
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                To update your preferences, ask us to remove your information
                from our mailing lists, delete your account or submit a request
                to exercise your rights under applicable law, please contact us
                as specified in the “How to Contact Us” section below.
              </p>
            </li>

            <li className="font-normal text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              DATA TRANSFERS
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                Encore is based in the United States. Personal Information that
                we collect may be transferred to, and stored at, any of our
                affiliates, partners or service providers which may be inside or
                outside the European Economic Area (“EEA”) and Switzerland,
                including the United States. By submitting your personal data,
                you agree to such transfers. Your Personal Information may be
                transferred to countries that do not have the same data
                protection laws as the country in which you initially provided
                the information. When we transfer or disclose your Personal
                Information to other countries, we will protect that information
                as described in this Privacy Notice.
              </p>
            </li>

            <li className="font-normal text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              CHILDREN’S PRIVACY
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                The Site is not directed to persons under 16. If a parent or
                guardian becomes aware that his or her child has provided us
                with Personal Information without their consent, he or she
                should contact us at
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="mailto: hello@encore.fans"
                  className="inline ml-2 font-light c-primary lg:text-[24px] text-[18px]">
                  hello@encore.fans.
                </a>
              </p>
            </li>

            <li className="font-normal text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              UPDATES TO THIS PRIVACY POLICY
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                This Privacy Notice may be updated from time to time for any
                reason, each version will apply to information collected while
                it was in place. You are advised to consult this Privacy Notice
                regularly for any changes.
              </p>
            </li>

            <li className="font-normal text-[#140943] lg:text-[24px] text-[18px] lg:leading-10 leading-7">
              HOW TO CONTACT US
              <p className="font-light text-[#140943]/70 lg:text-[24px] text-[18px] lg:leading-10 leading-7 mt-[20px]">
                If you have any questions or comments regarding this Privacy
                Notice, or if you would like to exercise your rights to your
                Personal Information, you may contact us by emailing us at
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="mailto: hello@encore.fans"
                  className="inline ml-2 font-light c-primary lg:text-[24px] text-[18px]">
                  hello@encore.fans.
                </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default PrivacyPolicy;
