import React, { useState, Fragment } from "react";
import Loader from "@components/Loader";
import { Listbox, Transition } from "@headlessui/react";
import Api from "@services/api";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import countries from "@helpers/countries";

const SubscriptionSms = (props) => {
	const { handleSubscribe, isAuthorizeLoader, data } = props;
	const [number, setNumber] = useState("");
	const [numberError, setNumberError] = useState("");
	const [selectedCountry, setSelectedCountry] = useState(countries[0]);
	const [selectedCountryShow, setSelectedCountryShow] = useState(true);
	const [codeError, setCodeError] = useState("");
	const [code, setCode] = useState("");
	const [step, setStep] = useState(false);
	const [loader, setLoader] = useState(false);
	const [resendLoader, setResendLoader] = useState(false);
	const creator = useSelector((state: any) => state.creator);
	const template = creator?.program?.config?.type;

	const onChangeNumber = () => {
		setStep(false);
		setCode("");
		setCodeError("");
		setSelectedCountryShow(true);
	};

	const handleSendCode = async () => {
		setLoader(true);
		const data = {
			type: "sms",
			query: "sendToken",
			phoneNumber: number,
		};
		const res = await Api.verificationAction(data);
		if (res?.status == 200) {
			setSelectedCountryShow(false);
			setResendLoader(false);
			setNumberError("");
			setStep(true);
			setLoader(false);
		} else {
			setNumberError(res?.data?.message?.[0] || "Invalid phone number");
			setLoader(false);
			setSelectedCountryShow(true);
		}
	};

	const handleResendCode = async () => {
		setResendLoader(true);
		const data = {
			type: "sms",
			query: "sendToken",
			phoneNumber: number,
		};
		const res = await Api.verificationAction(data);
		if (res?.status == 200) {
			setResendLoader(false);
			setNumberError("");
			setStep(true);
		} else {
			setNumberError(res?.data?.message?.[0] || "Invalid phone number");
			setResendLoader(false);
		}
	};

	const handleVerifyCode = async () => {
		setLoader(true);
		const data = {
			type: "sms",
			query: "verifyToken",
			phoneNumber: number,
			verifyCode: code,
		};
		const res = await Api.verificationAction(data);
		setLoader(false);
		if (res?.status == 200 && res?.data?.status == "approved" || res?.data?.verification_check?.status == "approved") {
			setCodeError("");
			handleSubscribe("phoneNumber", number);
		} else if (res?.status === 409) {
			setCodeError("Phone Number already associated with another user");
		} else {
			setCodeError("Invalid verification code");
		}
	};

	const hanldeCountryChange = (data) => {
		setSelectedCountry(data);
		setNumber(data.callingCodes)
	}

	return data.phoneNumber ? (
		<div className={`mt-3 ${template == "ecommerce" ? "bg-tile-form" : "bg-tile-form"} ${template == "loyalty" ? "opacity-50" : ""} px-3 flex items-center justify-between rounded-[10px]`}>
			<input
				onKeyDown={(e) => {
					if (e.keyCode === 13) {
						handleSubscribe("phoneNumber", data?.phoneNumber);
					}
				}}
				type="text"
				disabled={data?.phoneNumber}
				placeholder={"Phone Number"}
				value={data?.phoneNumber || ""}
				className={`w-full h-[40px] bg-transparent disabled:opacity-100 rounded-[10px] tile-inlay-primary placeholder:tile-inlay-primary placeholder:text-[14px] shadow-none outline-none`}
			/>
			<button
				onClick={() => handleSubscribe("phoneNumber", data?.phoneNumber)}
				disabled={isAuthorizeLoader}
				className={`bg-btn flex items-center justify-center w-[80px] h-[25px] rounded-full btn-inlay-primary py-[6px] px-[12px]`}
			>
				{isAuthorizeLoader ? (
					<Loader height={20} width={20} />
				) : (
					"SUBMIT"
				)}
			</button>
		</div>
	) : (
		<div className="" onClick={(e) => {
					e.stopPropagation();
				}}>
					<div className={`${template == "ecommerce" ? "bg-tile-form" : "bg-tile-form"} ${template == "loyalty" ? "opacity-50" : ""} mt-3 rounded-[10px] flex`}>
					{
						selectedCountryShow && 
						<div className="shrink-0">
							<Listbox value={selectedCountry} onChange={hanldeCountryChange}>
								{({open})=>{
									return(
										<>
											<Listbox.Button className={`${open ? 'w-[120px] shadow-md' : ''} ${template == "ecommerce" ? "bg-tile-form" : "bg-tile-form"} mt-1 w-full cursor-default rounded-lg py-2 pl-3 text-left`}>
												<div className="flex items-center gap-2 relative">
													<img src={selectedCountry?.flag} alt="flag" className="w-6 h-6 object-contain" />
													<svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none" className={`${open && 'transfrom rotate-180'}`}>
														<path d="M0 4.5L4 0.5L8 4.5H0Z" fill="black" fillOpacity="0.3"/>
													</svg>
													{
														open && 
														<span
															className={`block text-sm font-normal`}
														>
															{selectedCountry.callingCodes}
														</span>
													}
												</div>
											</Listbox.Button>
											<Transition
												as={Fragment}
												leave="transition ease-in duration-100"
												leaveFrom="opacity-100"
												leaveTo="opacity-0"
											>
												<Listbox.Options className="absolute z-[9999999999] mt-1 no-scrollbar max-h-[360px] w-full max-w-[120px] overflow-auto rounded-md bg-tile-form py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
												{countries.map((country, countryIdx) => (
													<Listbox.Option
													key={countryIdx}
													className={({ active }) =>
														`relative cursor-default select-none py-2 px-4 ${
														active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
														}`
													}
													value={country}
													>
													{({ selected }) => (
														<div className="flex items-center gap-2">
															<img src={country?.flag} alt="flag" className="w-6 h-5 object-contain" />
															<span
																className={`block truncate text-sm ${
																selected ? 'font-medium' : 'font-normal'
																}`}
															>
																{country.callingCodes}
															</span>
														</div>
													)}
													</Listbox.Option>
												))}
												</Listbox.Options>
											</Transition>
										</>
									)
								}}
							</Listbox>
						</div>
					}
			<div
				className={`${template == "ecommerce" ? "bg-tile-form" : "bg-tile-form"} ${template == "loyalty" ? "opacity-50" : ""} px-3 grow flex items-center justify-between rounded-[10px]`}
			>
				<InputMask
					// mask={number.slice(0,2)=="+1"?"+99999999999":"+999999999999"}
					type={"tel"}
					maskPlaceholder={null}
					placeholder={"Phone Number"}
					onChange={(e) => setNumber(e.target.value)}
					onKeyDown={(e) => {
						if (e.keyCode === 13) {
							handleSendCode();
						}
					}}
					value={number || selectedCountry.callingCodes}
					disabled={step}
					autoComplete="off"
					className={`w-full h-[40px] rounded-[10px] disabled:opacity-100 bg-transparent tile-inlay-primary placeholder:text-[black]/40 placeholder:text-[14px] shadow-none outline-none`}
				/>
				<>
					{!step ? (
						<button
							onClick={handleSendCode}
							disabled={number == "" || loader || (number || "").length < 12}
							className={`bg-btn disabled:opacity-30 flex items-center justify-center w-[80px] h-[25px] rounded-full btn-inlay-primary py-[6px] px-[12px]`}
						>
							{loader ? (
								<Loader height={20} width={20} />
							) : (
								"SUBMIT"
							)}
						</button>
					) : (
						<button
							onClick={onChangeNumber}
							className={`bg-btn flex items-center justify-center w-[80px] h-[25px] rounded-full btn-inlay-primary py-[6px] px-[12px]`}
						>
							CHANGE
						</button>
					)}
				</>
			</div>
			</div>
			{numberError && (
				<p
					className="font-normal text-[10px] mt-1 text-center text-red-500 "
				>
					{numberError}
				</p>
			)}
			{step && (
				<>
					<div
						className="py-1"
					>
						<div className={`${template == "ecommerce" ? "bg-tile-form" : "bg-tile-form"} ${template == "loyalty" ? "opacity-50" : ""} px-3 flex items-center justify-between rounded-[10px]`}>
							<input
								onChange={(e) => setCode(e.target.value)}
								onKeyDown={(e) => {
									if (e.keyCode === 13) {
										handleVerifyCode();
									}
								}}
								type="text"
								value={code || ""}
								className={`w-full h-[40px] rounded-[10px] disabled:opacity-100 bg-transparent tile-inlay-primary placeholder:text-[black]/40 placeholder:text-[14px] shadow-none outline-none`}
								placeholder="Verification Code"
							/>
							<button
								disabled={code == "" || loader || (code || "").length < 6 }
								onClick={handleVerifyCode}
								className={`bg-btn ${
									(code || "").length < 6 && "opacity-30"
								} flex items-center justify-center w-full max-w-[80px] min-w-[80px] h-[25px] rounded-full btn-inlay-primary py-[6px] px-[12px]`}
							>
								{loader ? (
									<Loader height={20} width={20} />
								) : (
									"SUBMIT"
								)}
							</button>
						</div>
					</div>
					{codeError && (
				<p
					className="font-normal text-[10px] mt-1 text-center text-red-500 "
				>
					{codeError}
				</p>
			)}
					<div
					>
						<p className={`font-normal text-[14px] lg:text-[16px] leading-5 tile-inlay-primary text-center mt-2`}>
							Didn't receive a code?
						</p>
						{resendLoader ? (
							<div className="mt-2">
								<Loader height={20} width={20} color={"#000"} />
							</div>
						) : (
							<p
								onClick={handleResendCode}
								className={`font-[700] text-[14px] lg:text-[16px] leading-8 tile-inlay-primary text-center cursor-pointer`}
							>
								Resend
							</p>
						)}
					</div>
				</>
			)}
		</div>
	);
};
export default SubscriptionSms;
