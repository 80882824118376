import logo_letter from "@assets/logo_letter.png";
import { toast } from "react-toastify";

class NotificationService {
  success(message) {
    toast.success(message, {
      icon: ({ theme, type }) => <img alt="logo" src={logo_letter} />,
    });
  }

  error(message) {
    toast.error(message, {
      icon: ({ theme, type }) => <img alt="logo" src={logo_letter} />,
    });
  }

  redirect(location) {
    setTimeout(() => {
      //window.location.reload();
    }, 3000);
  }
}

const Notification = new NotificationService();

Object.freeze(Notification);

export default Notification;
