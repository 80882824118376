const types = {
    UPDATE_PAGE_Y_OFFSET: 'UPDATE_PAGE_Y_OFFSET',
};

export const utilactions = {

    setPageYOffset: (data) => {
        return { type: types.UPDATE_PAGE_Y_OFFSET, data };

    }
};

const initialState = {
    pageYOffset: 0,
};

export const reducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case types.UPDATE_PAGE_Y_OFFSET: {
            return {
                ...state,
                pageYOffset: data,
            };
        }

        default:
            return state;
    }
};