import BadgeCompleted from "@components/EnterToWin/BadgeCompleted";
import Icon from "@components/Icon";
import React from "react";
import { Item } from "../types";

interface TitleProps {
  title: string;
  icon?: string;
  action?: string;
  titleDataTestId?: string;
  item: Item;
  onClick?: () => void;
}

export const Title = ({
  title,
  icon,
  action,
  titleDataTestId,
  item,
  onClick,
}: TitleProps) => {
  return (
    <div className="flex justify-between items-center " onClick={onClick}>
      <div className="flex items-center gap-2">
        {!!icon && <Icon icon={icon} className="w-[25px] h-[25px]" />}
        <h4
          data-testid={`title-${titleDataTestId}`}
          className={(() => {
            let style = "";
            if (action === "refer" || action === "email" || action === "sms") {
              style += "mt-[6px]";
            }
            style += " font-[400] leading-5 tile-inlay-primary tracking-[0.02em] text-[16px]";
            return style;
          })()}
        >
          {title}
        </h4>
      </div>
      {item && <BadgeCompleted item={item} />}
    </div>
  );
};
