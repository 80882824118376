import Api from "@services/api";

const types = {

  FETCH_SETTING_ASSETS_PENDING:"FETCH_SETTING_ASSETS_PENDING",
  FETCH_SETTING_ASSETS_SUCCESS:"FETCH_SETTING_ASSETS_SUCCESS",
  FETCH_SETTING_ASSETS_FAILURE:"FETCH_SETTING_ASSETS_FAILURE",

  SET_SETTING_TOUCHPOINT_MESSAGE:"SET_SETTING_TOUCHPOINT_MESSAGE",

};
export const actions = {

  fetchSettingAsset: async (dispatch , creatorId, programId) => {
    dispatch({ type: types.FETCH_SETTING_ASSETS_PENDING });
    const json = await Api.fetchCreatorAssets(creatorId, programId);
    if (json === undefined) {
        dispatch({
        type: types.FETCH_SETTING_ASSETS_FAILURE,
        data: {message: "Can't get data from server"},
        });
    } else if (200 !== json.status) {
        dispatch({ type: types.FETCH_SETTING_ASSETS_FAILURE });
    } else {
        dispatch({ type: types.FETCH_SETTING_ASSETS_SUCCESS, data: json.data });
    }
    return json;
  },
};

const initialState = {
  loader: false,
  data:{},
  payload:{}
};

export const reducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {

    case types.FETCH_SETTING_ASSETS_PENDING:{
      return {
        ...state,
        loader:true,
      }
    }

    case types.FETCH_SETTING_ASSETS_SUCCESS:{
      return {
        ...state,
        loader:false,
        data:data

      }
    }

    case types.FETCH_SETTING_ASSETS_FAILURE:{
      return {
        ...state,
        loader:false,
      }
    }

    case types.SET_SETTING_TOUCHPOINT_MESSAGE:{
      return {
        ...state,
        payload:data,
      }
    }

    default:
      return state;
  }
};
