import React from "react";
import { useSelector } from "react-redux";
import Sport from "./Sport";
import Team from "./Team";
import Player from "./Player";
import Social from "./Social";
import Traditional from "./Traditional";
import Address from "./Address";
import Loader from "@components/Loader";

const Survey = ({ questions, submit }) => {
  const { actions } = require("@redux/SurveyRedux");
  const [index, setIndex] = React.useState(0);

  const survey = useSelector((state: any) =>
    Object.assign({}, (state.survey.survey || {}))
  );

  const loader = useSelector((state: any) =>  (state.survey.loader || false)
  );

  const disabled = (survey?.[questions?.[index]] || []).length == 0;

  return (
    <div className="relative rounded-[10px] overflow-hidden h-full bg-tile">
      <div className="pt-1 lg:pt-0 h-full">
        <div className="lg:col-span-2 flex flex-col lg:mt-10 h-full mt-11 justify-center relative">
          <div className="no-scrollbar overflow-y-auto max-w-xl mx-auto w-full relative lg:px-0 px-4">
            {questions.map((question: any, key: number) => {
              if (questions[key] == "sport" && index == key) {
                return (
                  <Sport question={question} key={key} />
                );
              }
              if (questions[key] == "team" && index == key) {
                return (
                  <Team question={question} key={key} />
                );
              }
              if (questions[key] == "player" && index == key) {
                return (
                  <Player question={question} key={key} />
                );
              }
              if (questions[key] == "social" && index == key) {
                return (
                  <Social question={question} key={key} />
                );
              }
              if (questions[key] == "traditional" && index == key) {
                return (
                  <Traditional question={question} key={key} />
                );
              }
              if (questions[key] == "address" && index == key) {
                return (
                  <Address question={question} key={key} />
                );
              }


            })}
          </div>
            <div className="bg-tile px-3 py-3 w-full">
              <div className={`w-full flex items-center ${index > 0 ? 'justify-between' : 'justify-end'}`}>
                {index > 0 &&
                  <button
                    onClick={() => setIndex(index - 1)}
                    className={`mr-2 justify-center text-button-color font-medium lg:text-[18px] text-[16px] bg-tile rounded-full w-[119px] h-[42px]`}
                    data-testid="previous-button"
                  >
                    back
                  </button>
                }
                {questions.length == (index + 1) ?
                  loader? <Loader height={30} width={30} white={true} />:
				           <button
                   data-testid="submit-button"
                    disabled={disabled}
                    onClick={submit}
                    className={`text-button-color font-medium lg:text-[18px] text-[16px] ${disabled ? "bg-[#616161] hidden" : "bg-primary"} rounded-full w-[119px] h-[42px]`}
                  >
                    Submit
                  </button> :
                  <button
                    disabled={disabled}
                    onClick={() => setIndex(index + 1)}
                    className={`text-button-color font-medium lg:text-[18px] text-[16px] ${disabled ? "bg-[#616161]" : "bg-primary"} rounded-full w-[119px] h-[42px]`}
                    data-testid="next-button"
                  >
                    next
                  </button>

                }
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;
