import React, { Fragment, useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from "react-redux";

const Social = ({ question }) => {
  const { actions } = require("@redux/SurveyRedux");
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState([0]);

  const [data] = useState(["Facebook", "Snap", "TikTok", "Instagram", "Twitter", "YouTube", "break"]);
  const [available, setAvailable] = useState(["Facebook", "Snap", "TikTok", "Instagram", "Twitter", "YouTube"]);
  const [unavailable, setUnavailable] = useState([]);
  const survey = useSelector((state: any) =>
    Object.assign([], (state.survey.survey?.[question] || []))
  );

  const onDragEnd = (result) => {
    // Check if the destination is valid
    if (!result.destination) {
      return;
    }

    const [reorderedItem] = survey.splice(result.source.index, 1);
    survey.splice(result.destination.index, 0, reorderedItem);

    actions.updateSelectedSearch(dispatch, question, survey);
    // Logic to handle the dragged item
  };


  useEffect(() => {
    if (survey.length == 0) {
      actions.updateSelectedSearch(dispatch, question, data);
    }
  }, []);



  const search = (type: string, term: any) => {
    //if ((data?.[type] || []).length == 0) {
    actions.search(dispatch, type, term);
    //}
  };

  const selectedSearch = (type: string, value: any) => {
    actions.addSelectedSearch(dispatch, type, value);
  };

  const removeSearch = (type: string, value: any) => {
    actions.removeSelectedSearch(dispatch, type, value);
  };


  return (
    <div>
      <div className="transition-all duration-1000 ease-in-out animate-fade-in-up">
        <div className="h-[500px] lg:mb-0 mb-7">
          <>
            <div className="pb-6">
              <p className="text-primary text-lg font-semibold">
                Which social platforms do you use the most?
              </p>
              <p className="text-base font-normal text-primary">
                Drag and drop to re-order choices.
              </p>
            </div>

            <DragDropContext onDragEnd={onDragEnd} shouldRespectForcePress={false}>
              <Droppable droppableId="list1">
                {(provided) => (
                  <ul className="grid grid-cols-1 gap-3 list1"
                    ref={provided.innerRef}
                    {...provided.droppableProps}

                  >
                    {survey.map((item, index) => (

                      <Draggable key={item} draggableId={item} index={index}>
                        {(provided,snapshot) => (
                          <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          
                          className={`inline-flex ${snapshot.isDragging && 'bg-[#454647] !static'} items-center gap-4 text-lg font-normal text-primary p-[5px_10px_5px_10px] rounded-lg`}
                          
                          >
                            {item == "break" ?
                              <div className="mb-4 mt-4">
                                <p className="text-base font-normal text-primary">
                                  If you don’t use any of the above, drag them here.
                                </p>
                              </div> :
                              <>
                                <div>
                                  <svg

                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.2}
                                    stroke="currentColor"
                                    className="w-5 h-5 text-primary"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                    />
                                  </svg>
                                </div>
                                <p>{item}</p>
                              </>}
                          </li>
                        )}
                      </Draggable>

                    ))}
                  </ul>
                )}
              </Droppable>

            </DragDropContext>

          </>

        </div>
      </div>
    </div>
  );
};

export default Social;
