import { Fragment, useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Lottie from 'react-lottie'
import successGif from "@assets/lotties/success.json";
import { useSelector } from 'react-redux';
import check from "@assets/check.png";
import ProgramBox from "@components/ProgramBox";

const ActionPending = forwardRef((props, ref) => {
    const program = useSelector((state: any) => state?.creator?.program);
    const creator = useSelector((state: any) => state?.creator?.creator);
    const user = useSelector((state: any) => state?.user?.user?.user);
    const [isShow, setIsShow] = useState(false);
    const [data, setData] :any = useState({});


    useImperativeHandle(ref, () => ({
        open(data) {
            setData(data);
            doRedirection(data);
            setIsShow(true);
        },
        close() {
            setIsShow(false);
        },
      }));

    const doRedirection = (data) => {
        const timer = setTimeout(() => {
            handleRedirect(data)
          }, 2500);
          return () => clearTimeout(timer);
    }

    const handleRedirect = (data) => {
        let query = `email=${user?.email}&fanId=${user?.id}&programId=${program?.id}&actionId=${data?.id}&creatorId=${creator?.id}&tpId=${data?.tpid}`
        window.location.href = `${data?.config?.redirectUrl}?${query}`;
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: successGif,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Transition.Root show={isShow} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => true}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-[#000]/80 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="relative w-screen h-full overflow-hidden pointer-events-auto">
                                
                                 <div className="flex flex-col  items-center justify-center text-center">
                                        <Lottie options={defaultOptions} height={242} width={242} />
                                        <div className="lg:w-[500px] sm:w-[420px] md:w-[420px] w-[330px]">
                                            <h4 className="font-medium text-white lg:text-[36px] md:text-[36px] text-[24px] text-center">
                                            <ProgramBox box="ACTION_COMPLETE_REDIRECT_HEADING" />
                                            </h4>
                                            <p className="text-[18px] text-center mb-[40px] leading-[21.6px] text-white font-normal tracking-[1.5px] mt-[10px]">
                                            <ProgramBox box="ACTION_COMPLETE_REDIRECT_DESCRIPTION" />
                                            </p>
                                        </div>
                                        <button
                                  onClick={()=>handleRedirect(data)}
                                  className="cursor-pointer text-[14px] p-[9px_25px] btn-inlay-primary bg-btn w-max text-center rounded-full border-none outline-none"
                                  >
                                    launch SQWAD
                                    </button>
                                    </div> 
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
});
export default ActionPending;
