import Api from "@services/api";
import logo from "@assets/logo.png";
import background_img from "@assets/desktop_bg.png";
import favicon from "@assets/favicon/encore-fans.ico";
import icon from "@assets/logo_letter.png";
import moment from "moment";
import NotificationService from "@services/notification-service";
import store from "@store/configureStore";


const types = {
  LOGOUT: "LOGOUT",
  SLOGOUT:'SLOGOUT',
  SET_LOGOUT: "SET_LOGOUT",
  LOGIN_PENDING: "LOGIN_PENDING",
  LOGIN_FALIURE: "LOGIN_FALIURE",
  LOGIN_CANCEL: "LOGIN_CANCEL",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  SIGNUP_PENDING: "SIGNUP_PENDING",
  SIGNUP_FALIURE: "SIGNUP_FALIURE",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  NEW_SIGNUP_SUCCESS: "NEW_SIGNUP_SUCCESS",
  NEW_FAN_SIGNUP_SUCCESS: "NEW_FAN_SIGNUP_SUCCESS",

  MAGIC_SIGNUP_PENDING: "MAGIC_SIGNUP_PENDING",
  MAGIC_SIGNUP_FALIURE: "MAGIC_SIGNUP_FALIURE",
  MAGIC_SIGNUP_CANCEL: "MAGIC_SIGNUP_CANCEL",
  MAGIC_SIGNUP_SUCCESS: "MAGIC_SIGNUP_SUCCESS",
  UPDATE_USER_PENDING: "UPDATE_USER_PENDING",
  UPDATE_USER_FALIURE: "UPDATE_USER_FALIURE",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_OBJECT_SUCCESS: "UPDATE_USER_OBJECT_SUCCESS",
  GUEST: "GUEST",
  ADD_FORM: "ADD_FORM",
  RESET_FORM: "RESET_FORM",
  ADD_NEW_USER_FLAG: "ADD_NEW_USER_FLAG",
  ADD_FORM_STEP: "ADD_FORM_STEP",
  AUTH_YOUTUBE: "AUTH_YOUTUBE",
  AUTH_SPOTIFY: "AUTH_SPOTIFY",
  AUTH_DISCORD: "AUTH_DISCORD",
  AUTH_TWITTER: "AUTH_TWITTER",
  AUTH_INSTAGRAM: "AUTH_INSTAGRAM",
  AUTH_TIKTOK: "AUTH_TIKTOK",
  AUTH_ERROR: "AUTH_ERROR",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_SHOPIFY: "AUTH_SHOPIFY",
  API_TOKEN_EXPIRE: "API_TOKEN_EXPIRE",
  LOG_DATA: "LOG_DATA",
  SET_JWT_TOKEN: "SET_JWT_TOKEN",
  SET_TOKEN: "SET_TOKEN",

  FETCH_CREATOR_ASSETS_PENDING: "FETCH_CREATOR_ASSETS_PENDING",
  FETCH_CREATOR_ASSETS_SUCCESS: "FETCH_CREATOR_ASSETS_SUCCESS",
  FETCH_CREATOR_ASSETS_FAILURE: "FETCH_CREATOR_ASSETS_FAILURE",

  FETCH_FAN_SEARCH_PENDING: "FETCH_FAN_SEARCH_PENDING",
  FETCH_FAN_SEARCH_SUCCESS: "FETCH_FAN_SEARCH_SUCCESS",
  FETCH_FAN_SEARCH_MORE: "FETCH_FAN_SEARCH_MORE",
  FETCH_FAN_SEARCH_FAILURE: "FETCH_FAN_SEARCH_FAILURE",

  FETCH_CREATOR_DATA_SUCCESS: "FETCH_CREATOR_DATA_SUCCESS",

  ADD_TYPE_FORM: "ADD_TYPE_FORM",
  TYPE_FORM_PENDING: "TYPE_FORM_PENDING",
  TYPE_FORM_SUCCESS: "TYPE_FORM_SUCCESS",
  TYPE_FORM_FAILURE: "TYPE_FORM_FAILURE",
  CHANGE_LOADER_STATUS: "CHANGE_LOADER_STATUS",
  USER_RESET_STATE: "USER_RESET_STATE",

  FETCH_SIGNUP_INFO_PENDING: "FETCH_SIGNUP_INFO_PENDING",
  FETCH_SIGNUP_INFO_SUCCESS: "FETCH_SIGNUP_INFO_SUCCESS",
  FETCH_SIGNUP_INFO_FAILURE: "FETCH_SIGNUP_INFO_FAILURE",

  FETCH_NOTIFICATION_PENDING: "FETCH_NOTIFICATION_PENDING",
  FETCH_NOTIFICATION_SUCCESS: "FETCH_NOTIFICATION_SUCCESS",
  FETCH_NOTIFICATION_FAILURE: "FETCH_NOTIFICATION_FAILURE",
  FETCH_FAN_PROGRAM_SUCCESS: "FETCH_FAN_PROGRAM_SUCCESS",
  SET_REDIRECT_URL: "SET_REDIRECT_URL",
  UPDATE_CREATOR_OBJECT_SUCCESS: "UPDATE_CREATOR_OBJECT_SUCCESS",

  FETCH_CREATOR_ASSET_PENDING: "FETCH_CREATOR_ASSET_PENDING",
  FETCH_CREATOR_ASSET_SUCCESS: "FETCH_CREATOR_ASSET_SUCCESS",
  FETCH_CREATOR_ASSET_FAILURE: "FETCH_CREATOR_ASSET_FAILURE",

  ADD_NOTIFICATION_QUEUE:"ADD_NOTIFICATION_QUEUE",
  REMOVE_NOTIFICATION_QUEUE:"REMOVE_NOTIFICATION_QUEUE",
  ADD_NOTIFICATION_DATA:"ADD_NOTIFICATION_DATA",

  GET_ALL_REFERRALS_PROGRAMS:"GET_ALL_REFERRALS_PROGRAMS",

  SET_REDEEM_PROGRAMS: "SET_REDEEM_PROGRAMS",

  AUTH_LOADER:"AUTH_LOADER",

  SYNC_STORAGE: "SYNC_STORAGE"
};
export const actions = {
  authenticate: async (dispatch, exprience, type, id, token, programId, cname, pname) => {
    try {
      //dispatch({ type: types.USER_RESET_STATE, data:{}});
      dispatch({ type: types.LOGIN_PENDING });

      const userData = {};
      if (type != "creator") {
        userData["creatorId"] = id;
        userData["programId"] = programId;
      }
      const json =
        type != "creator"
          ? await Api.login(userData)
          : await Api.creatorSignin(userData);
      if (json === undefined) {
        dispatch({
          type: types.LOGIN_FALIURE,
          data: { message: "Can't get data from server" },
        });
        NotificationService.error("User does not exist");
        window.location.reload();
      } else if (404 === json?.status || 207 === json?.status) {
        if (type != "creator") {
          dispatch({ type: types.NEW_FAN_SIGNUP_SUCCESS, data: userData, token: token });
        } else {
          dispatch({ type: types.NEW_SIGNUP_SUCCESS, data: userData, token: token });
        }
      } else if (200 === json?.status) {
        store.dispatch({ type: "SET_INVITE_TOKEN", data: {} });
        if (json.data?.message) {
          NotificationService.success(json?.data?.message);
        }

        const timestamp = moment();
      
        dispatch({
          type: types.LOGIN_SUCCESS,
          data: { user: json?.data?.data, token: token },
          token: token,
          exprience:exprience,
          timestamp: timestamp,
          session:{cname,pname}
        });
      } else {
        NotificationService.error(json?.data?.message);
        dispatch({ type: types.LOGIN_FALIURE, data: json?.data.error });
        NotificationService.redirect(process.env.REACT_APP_BASE_URL);
      }
      return json;
    } catch (error) {
      NotificationService.error("Login unsuccessful, please try again");
      dispatch({
        type: types.LOGIN_CANCEL,
        data: { message: "Login unsuccessful, please try again" },
      });
      //window.location.reload();
    }
  },

  creatorTypeform: async (dispatch, form) => {
    dispatch({ type: types.TYPE_FORM_PENDING });
    const json = await Api.creatorTypeform(form);
    if (json !== undefined) {
      if (200 === json.status) {
        dispatch({ type: types.TYPE_FORM_SUCCESS, data: json.data });
      } else {
        dispatch({ type: types.TYPE_FORM_FAILURE });
      }
    } else {
      dispatch({ type: types.TYPE_FORM_FAILURE });
    }
  },

  doSignup: async (dispatch, userData, token, cname, pname, exprience="loyalty") => {
    dispatch({ type: types.MAGIC_SIGNUP_PENDING });
    const json = await Api.signup(userData);
    if (json === undefined) {
      dispatch({
        type: types.SIGNUP_FALIURE,
        data: { message: "Can't get data from server" },
      });
      NotificationService.redirect(process.env.REACT_APP_BASE_URL);
    } else if (201 !== json.status) {
      NotificationService.error(json?.data?.message);
      dispatch({ type: types.SIGNUP_FALIURE, data: json.data });
      NotificationService.redirect(process.env.REACT_APP_BASE_URL);
    } else {
      if (json?.data?.message) {
        NotificationService.success(json?.data?.message);
      }

      const timestamp = moment();
      dispatch({
        type: types.SIGNUP_SUCCESS,
        data: { user: json.data?.data, token: token },
        token: token,
        exprience:exprience,
        timestamp: timestamp,
        session:{cname,pname}
      });
    }
    return json;
  },

  doCreatorSignup: async (dispatch, userData, token) => {
    dispatch({ type: types.MAGIC_SIGNUP_PENDING });
    try {
      const json = await Api.creatorSignup(userData);
      if (json === undefined) {
        dispatch({
          type: types.MAGIC_SIGNUP_FALIURE,
          data: {},
          token: userData.token,
        });
      } else if (201 !== json.status) {
        NotificationService.error(json?.data?.message);
        dispatch({
          type: types.MAGIC_SIGNUP_FALIURE,
          data: json.data?.error,
          token: userData.token,
        });
      } else {
        if (json?.data?.message) {
          NotificationService.success(json?.data?.message);
        }

        const timestamp = moment();
        dispatch({
          type: types.MAGIC_SIGNUP_SUCCESS,
          data: { user: json.data?.data, token: token },
          token: token,
          timestamp: timestamp,
        });
      }
      return json;
    } catch (error) {
      dispatch({
        type: types.MAGIC_SIGNUP_CANCEL,
        data: { message: "Signup unsuccessful, please try again." },
      });
      NotificationService.redirect(process.env.REACT_APP_BASE_URL);
    }
  },

  logout: (session) => {
    return { type: types.LOGOUT,session };
  },

  setLogout: (isLogout) => {
    return {
      type: "SET_LOGOUT",
      payload: isLogout,
    };
  },

  resetUser: (data) => {
    return { type: types.USER_RESET_STATE, data };
  },
  updateUser: async (dispatch, userData, isExtra = false, fanId) => {
    dispatch({ type: types.UPDATE_USER_PENDING });
    const json = isExtra
      ? await Api.updateFansExtra(userData)
      : await Api.updateFan(fanId, userData);
    if (json === undefined) {
      dispatch({
        type: types.UPDATE_USER_FALIURE,
        data: { message: "Can't get data from server" },
      });
    } else if (200 !== json.status) {
      NotificationService.error(json.data.error.message);
      dispatch({ type: types.UPDATE_USER_FALIURE, data: json.data.error });
    } else {
      if (isExtra) {
        NotificationService.success("Fan info updated successfully");
      } else {
        NotificationService.success("Display name updated successfully");
      }

      dispatch({
        type: types.CHANGE_LOADER_STATUS,
        data: json.data,
      });
    }
    return json;
  },
  updateCreator: async (dispatch, userData, id) => {
    dispatch({ type: types.UPDATE_USER_PENDING });
    const json = await Api.updateCreator(userData, id);
    if (json === undefined) {
      dispatch({
        type: types.UPDATE_USER_FALIURE,
        data: { message: "Can't get data from server" },
      });
    } else if (200 !== json.status) {
      NotificationService.error(
        json.data?.error?.message || "something went wrong"
      );
      dispatch({ type: types.UPDATE_USER_FALIURE, data: json.data.error });
    } else {
      NotificationService.success("Website updated successfully");
      dispatch({
        type: types.CHANGE_LOADER_STATUS,
      });
    }
    return json;
  },
  fetchCreator: async (dispatch, filter) => {
    const json = await Api.filterByCreator(filter);
    if (json !== undefined) {
      if (200 === json.status) {
        dispatch({ type: types.UPDATE_USER_SUCCESS, data: json.data?.[0] });
      }
    }
    return json;
  },

  fetchPrograms: async (dispatch) => {
    dispatch({ type: "SET_GLOBAL_LOADER", data: true });
    const json = await Api.getAllReferralsPrograms();
    dispatch({ type: "SET_GLOBAL_LOADER", data: false });
    if (json !== undefined) {
      if (200 === json.status) {
        dispatch({ type: types.GET_ALL_REFERRALS_PROGRAMS, data: json?.data?.data });
      }
    }
    return json;
  },

  fanSearch: async (dispatch, index, limit) => {
    dispatch({ type: types.FETCH_FAN_SEARCH_PENDING });
    const json = await Api.fanSearch(index, limit);
    if (json !== undefined) {
      if (200 === json.status) {
        if (index === 0) {
          dispatch({ type: types.FETCH_FAN_SEARCH_SUCCESS, data: json.data });
        } else {
          dispatch({ type: types.FETCH_FAN_SEARCH_MORE, data: json.data });
        }
      } else {
        dispatch({ type: types.FETCH_FAN_SEARCH_FAILURE });
      }
    } else {
      dispatch({ type: types.FETCH_FAN_SEARCH_FAILURE });
    }
  },

  fetchNotifications: async (dispatch, creatorId) => {
    dispatch({ type: types.FETCH_NOTIFICATION_PENDING });
    const json = await Api.fetchNotifications(creatorId);
    if (json !== undefined) {
      if (200 === json.status) {
        dispatch({ type: types.FETCH_NOTIFICATION_SUCCESS, data: json.data });
      } else {
        dispatch({ type: types.FETCH_NOTIFICATION_FAILURE });
      }
    } else {
      dispatch({ type: types.FETCH_NOTIFICATION_FAILURE });
    }
  },

  fetchCreatorAsset: async (dispatch, creatorId, programId) => {
    dispatch({ type: types.FETCH_CREATOR_ASSET_PENDING });
    const json = await Api.fetchCreatorAssets(creatorId, programId);
    if (json === undefined) {
      dispatch({
        type: types.FETCH_CREATOR_ASSET_FAILURE,
        data: { message: "Can't get data from server" },
      });
    } else if (200 !== json.status) {
      dispatch({ type: types.FETCH_CREATOR_ASSET_FAILURE });
    } else {
      dispatch({ type: types.FETCH_CREATOR_ASSET_SUCCESS, data: json.data });
    }
    return json;
  },

  updateUserObject: async (userData) => {
    return { type: types.UPDATE_USER_OBJECT_SUCCESS, userData };
  },

  updateTypeformObject: async (userData) => {
    return { type: types.UPDATE_USER_OBJECT_SUCCESS, userData };
  },
  saveGuest: (data) => {
    return { type: types.GUEST, data };
  },
  addForm: (data) => {
    return { type: types.ADD_FORM, data };
  },
  addTypeForm: (data) => {
    return { type: types.ADD_TYPE_FORM, data };
  },
  clearAddTypeForm: () => {
    return { type: types.TYPE_FORM_SUCCESS };
  },
  resetForm: (data) => {
    return { type: types.RESET_FORM, data };
  },
  addNewUserFlag: (data) => {
    return { type: types.ADD_NEW_USER_FLAG, data };
  },
  addFormStep: (data) => {
    return { type: types.ADD_FORM_STEP, data };
  },
  authYoutube: (data) => {
    return { type: types.AUTH_YOUTUBE, data };
  },
  authSpotify: (data) => {
    return { type: types.AUTH_SPOTIFY, data };
  },
  authDiscord: (data) => {
    return { type: types.AUTH_DISCORD, data };
  },
  authTwitter: (data) => {
    return { type: types.AUTH_TWITTER, data };
  },
  authInstagram: (data) => {
    return { type: types.AUTH_INSTAGRAM, data };
  },
  authTiktok: (data) => {
    return { type: types.AUTH_TIKTOK, data };
  },
  authShopify: (data) => {
    return { type: types.AUTH_SHOPIFY, data };
  },
  authError: (data) => {
    return { type: types.AUTH_ERROR, data };
  },
  authSuccess: (data) => {
    return { type: types.AUTH_SUCCESS, data };
  },
  logData: (data) => {
    return { type: types.LOG_DATA, data };
  },
  setJwtToken: (data) => {
    return { type: types.SET_JWT_TOKEN, data };
  },
  setRedirectUrl: (data) => {
    return { type: types.SET_REDIRECT_URL, data };
  },
  updateCreatorData: (data) => {
    return { type: types.UPDATE_CREATOR_OBJECT_SUCCESS, data };
  },
};

const initialState = {
  session:{},
  token: null,
  creatorToken: null,
  timestamp: null,
  new_user_flag: false,
  user: {},
  guest: {},
  form: {
    name: "",
    phone_number: "",
    code_digit: "",
    country_code: "1",
  },
  step: 6,
  youtube: {},
  spotify: {},
  discord: {},
  instagram: {},
  twitter: {},
  tiktok: {},
  shopify: {},
  isFetching: false,
  authLoader: false,
  error: {},
  success: {},
  logs: {
    payload: {},
    response: {},
    userData: {},
  },
  assets: {
    backgroundImage: background_img,
    favicon: favicon,
    logo: logo,
    icon: icon,
    profileImage: logo,
  },
  search: { creators: [], nextIndex: 0 },
  searchLoading: false,
  creatorData: {},
  typeForm: {
    url: "",
    typeformId: "",
    title: "",
    description: "",
    points: "",
  },
  typeFormLoading: false,
  dynamicFormTest: {
    "step-2": [
      {
        label: "What's your USTA account number?",
        name: "personId",
        placeholder: "123123",
        rules: {
          required: "Account number is required",
          pattern: "^[a-zA-Z]+$",
        },
        type: "text",
      },
    ],

    "step-3": [
      {
        label: "What's your birthday?",
        name: "dateOfBirth",
        placeholder: "mm/dd/yy",
        rules: {
          required: "Birthday is required",
          minLength: 8,
          maxLength: 10,
        },
        type: "date",
      },
    ],
  },
  dynamicForm: {},
  isLogout: false,
  notifications: [],
  programData: {},
  programs: [],
  redirectUrl: {},
  socketNotification:[],
  fan: {},
  reedemPrograms: {}
};

export const reducer = (state = initialState, action) => {
  const { type, token, data, timestamp, session, exprience } = action;

  switch (type) {
    case types.SET_LOGOUT: {
      return {
        ...state,
        isLogout: action.payload,
      };
    }

    case types.SET_REDIRECT_URL: {
      return {
        ...state,
        redirectUrl: data,
      };
    }

    case types.LOGOUT: {
      return { ...state, token: null, 
        form: initialState.form, 
        session:{},
        fan: null,
        reedemPrograms: {}
      };
    }

    case types.SLOGOUT:{
      return { ...state, session:{
        ...state.session,
        [session.cname]:{
          ...state.session?.[session.cname],
          [session.pname]:false
        }
      } };
    }

    case types.USER_RESET_STATE: {
      return Object.assign({}, initialState, data);
    }

    case types.GUEST: {
      return { ...state, guest: data };
    }

    case types.LOGIN_PENDING: {
      return {
        ...state,
        isFetching: true,
        authLoader: true,
        error: {},
      };
    }
    case types.LOGIN_FALIURE: {
      return {
        ...state,
        isFetching: false,
        authLoader: false,
        error: {
          error: true,
          message: data.message,
        },
        form: initialState.form,
      };
    }
    case types.LOGIN_CANCEL: {
      return {
        ...state,
        isFetching: false,
        authLoader: false,
        error: {
          error: true,
          message: data.message,
        },
        form: initialState.form,
      };
    }
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        user: data,
        token: token,
        timestamp: timestamp,
        isFetching: false,
        authLoader: false,
        error: {},
        form: initialState.form,
        step: 6,
        new_user_flag: false,
        session:{
          ...state.session,
          [session.cname]:{
            ...state.session?.[session.cname],
            [session.pname]:true
          }
        }
      };
    }
    case types.NEW_SIGNUP_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        step: 3,
        creatorToken: token,
        form: {
          ...state.form,
          token: data.token,
          publicKey: data.publicKey,
        },
      };
    }
    case types.NEW_FAN_SIGNUP_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        authLoader:false,
        step: 1,
        form: {
          ...state.form,
          token: token,
          publicKey: data.publicKey,
        },
      };
    }
    case types.MAGIC_SIGNUP_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    }
    case types.MAGIC_SIGNUP_FALIURE: {
      return {
        ...state,
        isFetching: false,
        error: data,
        step: 3,
      };
    }
    case types.MAGIC_SIGNUP_CANCEL: {
      return {
        ...state,
        isFetching: false,
        error: {
          error: true,
          message: data.message,
        },
        form: initialState.form,
        step: 4,
      };
    }
    case types.MAGIC_SIGNUP_SUCCESS: {
      return {
        ...state,
        user: data,
        token: token,
        timestamp: timestamp,
        isFetching: false,
        error: {},
        form: initialState.form,
        step: 4,
        new_user_flag: true,
      };
    }
    case types.SIGNUP_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    }
    case types.SIGNUP_FALIURE: {
      return {
        ...state,
        isFetching: false,
        error: {
          error: true,
          message: data.message,
        },
      };
    }
    case types.SIGNUP_SUCCESS: {
      return {
        ...state,
        user: data,
        token: token,
        timestamp: timestamp,
        isFetching: false,
        error: {},
        form: initialState.form,
        new_user_flag: true,
        step: 4,
        session:{
          ...state.session,
          [session.cname]:{
            ...state.session?.[session.cname],
            [session.pname]:true
          }
        }
      };
    }
    case types.UPDATE_USER_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    }
    case types.UPDATE_USER_FALIURE: {
      return {
        ...state,
        isFetching: false,
        error: {
          error: true,
          message: data.message,
        },
      };
    }
    case types.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        user: {
          user: data,
          token: state.user.token,
        },
        token: state.token,
        isFetching: false,
        error: {},
        success: {
          success: true,
          message: "Display name updated successfully",
        },
      };
    }

    case types.CHANGE_LOADER_STATUS: {
      return {
        ...state,
        isFetching: false,
        error: {},
        success: {
          success: true,
          message: "Display name updated successfully",
        },
      };
    }

    case types.UPDATE_USER_OBJECT_SUCCESS: {
      return {
        ...state,
        user: {
          user: data,
          token: state.user.token,
        },
        token: state.token,
        isFetching: false,
        error: {},
      };
    }

    case types.FETCH_FAN_PROGRAM_SUCCESS: {
      return {
        ...state,
        programData: data,
      };
    }

    case types.ADD_FORM: {
      return { ...state, form: data };
    }
    case types.RESET_FORM: {
      return { ...state, form: initialState.form };
    }
    case types.ADD_NEW_USER_FLAG: {
      return { ...state, new_user_flag: data };
    }
    case types.ADD_FORM_STEP: {
      return { ...state, step: data };
    }
    case types.AUTH_YOUTUBE: {
      return { ...state, youtube: data };
    }
    case types.AUTH_SPOTIFY: {
      return { ...state, spotify: data };
    }
    case types.AUTH_DISCORD: {
      return { ...state, discord: data };
    }
    case types.AUTH_TWITTER: {
      return { ...state, twitter: data };
    }
    case types.AUTH_INSTAGRAM: {
      return { ...state, instagram: data };
    }
    case types.AUTH_TIKTOK: {
      return { ...state, tiktok: data };
    }
    case types.AUTH_SHOPIFY: {
      return { ...state, shopify: data };
    }
    case types.AUTH_ERROR: {
      return { ...state, error: data };
    }
    case types.AUTH_SUCCESS: {
      return { ...state, success: data };
    }
    case types.API_TOKEN_EXPIRE: {
      return { ...state, token: null, session:{}};
    }
    case types.LOG_DATA: {
      return { ...state, logs: data };
    }
    case types.SET_JWT_TOKEN: {
      const urlString = window.location.href;
      const cname = urlString.split('/')?.[3];
      const pname = urlString.split('/')?.[4];

      return { ...state, token: data, session:{
          ...state.session,
          [cname]:{
            ...state.session?.[cname],
            [pname]:true
          }
        }
      };
    }
    case types.FETCH_FAN_SEARCH_PENDING: {
      return {
        ...state,
        searchLoading: true,
      };
    }
    case types.FETCH_FAN_SEARCH_SUCCESS: {
      return {
        ...state,
        search: data,
        searchLoading: false,
      };
    }
    case types.FETCH_FAN_SEARCH_MORE: {
      return {
        ...state,
        search: {
          ...state.search,
          creators: state.search.creators.concat(data.creators),
          nextIndex: data.nextIndex ?? 0,
        },
        searchLoading: false,
      };
    }
    case types.FETCH_FAN_SEARCH_FAILURE: {
      return {
        ...state,
        searchLoading: false,
      };
    }
    case types.FETCH_CREATOR_ASSETS_SUCCESS: {
      return {
        ...state,
        assets: {
          ...state.assets,
          backgroundImage:
            data.backgroundImage === null
              ? background_img
              : `${data.backgroundImage}`,
          logo: data.logo === null ? logo : `${data.logo}`,
          profileImage:
            data.profileImage === null ? logo : `${data.profileImage}`,
          icon: data.icon === null ? icon : `${data.icon}`,
          favicon: data.favicon === null ? favicon : `${data.favicon}`,
        },
      };
    }
    case types.FETCH_CREATOR_DATA_SUCCESS: {
      return {
        ...state,
        creatorData: data,
      };
    }

    case types.ADD_TYPE_FORM: {
      return {
        ...state,
        typeForm: data,
      };
    }

    case types.TYPE_FORM_SUCCESS: {
      return {
        ...state,
        typeForm: {},
        typeFormLoading: false,
      };
    }

    case types.TYPE_FORM_PENDING: {
      return {
        ...state,
        typeFormLoading: true,
      };
    }

    case types.TYPE_FORM_FAILURE: {
      return {
        ...state,
        typeFormLoading: false,
      };
    }

    case types.FETCH_SIGNUP_INFO_SUCCESS: {
      return {
        ...state,
        dynamicForm: data,
      };
    }

    case types.FETCH_SIGNUP_INFO_FAILURE: {
      return {
        ...state,
        dynamicForm: {},
      };
    }

    case types.FETCH_NOTIFICATION_PENDING: {
      return {
        ...state,
        notifications: [],
      };
    }

    case types.FETCH_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notifications: data,
      };
    }

    case types.FETCH_NOTIFICATION_FAILURE: {
      return {
        ...state,
        notifications: [],
      };
    }

    case types.UPDATE_CREATOR_OBJECT_SUCCESS: {
      return {
        ...state,
        user: data,
      };
    }

    /** SET CREATOR ASSETS */

    case types.FETCH_CREATOR_ASSET_PENDING: {
      return {
        ...state,
      };
    }

    case types.FETCH_CREATOR_ASSET_SUCCESS: {
      return {
        ...state,
        user: {
          user: { ...state.user.user, programs: [data.data] },
          token: state.user.token,
        },
      };
    }

    case types.FETCH_CREATOR_ASSET_FAILURE: {
      return {
        ...state,
      };
    }

    case types.ADD_NOTIFICATION_QUEUE: {
      return {
        ...state,
        socketNotification:[...state.socketNotification, ...data]
      };
    }

    case types.REMOVE_NOTIFICATION_QUEUE: {
      const notiications = state.socketNotification.slice(1);
      return {
        ...state,
        socketNotification:notiications
      };
    }

    case types.ADD_NOTIFICATION_DATA:{

      return {
        ...state,
        socketNotification:data
      };
    }

    case types.AUTH_LOADER: {
      return {
        ...state,
        authLoader: data
      }
    }

    case types.SYNC_STORAGE: {
      return {
        ...state, 
        session: data,
        token: token?.token
      }
    }

    case types.GET_ALL_REFERRALS_PROGRAMS: {
      return {
        ...state,
        programs: data
      }
    }

    case types.SET_TOKEN: {
      return {
        ...state,
        token: data
      }
    }

    case types.SET_REDEEM_PROGRAMS: {
      return {
        ...state,
        reedemPrograms: {...state.reedemPrograms, [data?.id]: data?.info},
        fan: data?.fanInfo
      }
    }

    default:
      return state;
  }
};
