/**
 * @format
 */
import axios from "axios";
import store from "@store/configureStore";
import { getAppName } from "@helpers/common";

export default class Api {
  _api = null;
  _getAccessTokenSilently = null;
  _ip = null;
  static requestInterceptor;

  static init = ({ url }) => {
    try {
      this._api = axios.create({
        baseURL: url,
        timeout: 10000,
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY
      }
      });
    } catch (error) {
      return error;
    }
  };

  static clearClientToken() {

    // Eject the interceptor to remove the token
    this._api.interceptors.request.eject(this.requestInterceptor);
  }

  static setClientToken(token) {

    // Clear any existing interceptor before setting a new token
    this.clearClientToken();

    // Set the new token
    this.requestInterceptor = this._api.interceptors.request.use(
      function (config) {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      }
    );
  }

  static VerifyAuthUserToken = async (getAccessTokenSilently) => {
    // Add a response interceptor
    if (getAppName() !== "creator") {
      this._api.interceptors.response.use(
        async  function (response) {
          if (response.status === 401) {
              store.dispatch({
                type: "API_TOKEN_EXPIRE",
                payload: {},
              });            
          }
          return response;
        },
        async function (error) {
          if (error.response.status === 401) {
              store.dispatch({
                type: "API_TOKEN_EXPIRE",
                payload: error,
              });
            
            return Promise.reject(error);
          }
          return Promise.reject(error);
        }
      );
    } else {
      if(!this._getAccessTokenSilently){
        this._getAccessTokenSilently = getAccessTokenSilently;
        
        this._api.interceptors.response.use(
          async  function (response) {
            if (response.status === 401) {
              try {
                const newAccessToken = await getAccessTokenSilently({ detailedResponse: true });
                store.dispatch({
                  type: "SET_JWT_TOKEN",
                  data: newAccessToken.access_token,
                });
                //window.location.reload();
                
              } catch(e){
                store.dispatch({
                  type: "API_TOKEN_EXPIRE",
                  payload: {},
                });
                //window.location.reload();
              }
              
            }
            return response;
          },
          async function (error) {
            if (error.response.status === 401) {
              try {
                const newAccessToken = await getAccessTokenSilently({ detailedResponse: true });
                store.dispatch({
                  type: "SET_JWT_TOKEN",
                  data: newAccessToken.access_token,
                });
                //window.location.reload();
              } catch(e){
                store.dispatch({
                  type: "API_TOKEN_EXPIRE",
                  payload: error,
                });
                //window.location.reload();
              }
              
              return Promise.reject(error);
            }
            return Promise.reject(error);
          }
        );
      }
    }
    
    
  };

  static setClientIP = (ip) => {
    this._ip = ip;
  }

  /*************** Fan API  ******************/

  static login = async (data) => {
    try {
      const response = await this._api.post("/auth/fan/signin", data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static signup = async (data) => {
    try {
      const response = await this._api.post("/auth/fan/signup", data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static score = async (programId) => {
    try {
      const response = await this._api.get(
        `/programs/${programId}/programFan`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static fanFind = async () => {
    try {
      const response = await this._api.get(`fans`);
      return response;
    } catch (error) {
      return error.response;
    }
  };
  //new
  static updateCreator = async (data, id) => {
    try {
      const response = await this._api.patch(`creators`, data);

      return response;
    } catch (error) {
      return error.response;
    }
  };

  static leaderboard = async (programId, params) => {
    try {
      const response = await this._api.get(
        `/programs/leaderboard/${programId}`,
        { params: params }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static resolveOauth = async (
    creator_id,
    touchpoint,
    touchpointId,
    code,
    redirect_uri
  ) => {
    try {
      let data = {
        oauthData: {
          redirectUri: redirect_uri,
          query: "info",
        },
      };
      if (touchpoint === "facebook" || touchpoint === "youtube") {
        data["oauthData"]["authorizationCode"] = code;
      } else {
        data["oauthData"]["authToken"] = code;
      }

      const response = await this._api.patch(
        `fans/resolve-oauth/${touchpoint}/${touchpointId}/${creator_id}`,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static shopifyOauth = async (data) => {
    try {
      const response = await this._api.post(`/shopify/merchants/connect`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static authorizeTouchpoint = async (touchpointId, creatorId) => {
    try {
      const response = await this._api.patch(
        `/fans/authorize-touchpoint/${touchpointId}/${creatorId}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static resolveOauthCreator = async (touchpoint, data) => {
    try {
      const response = await this._api.patch(
        `creators/touchpoints/init/${touchpoint}`,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };
  // Shopify If not setScore
  static shopifyScore = async (data) => {
    try {
      const response = await this._api.patch(
        "/creators/touchpoints/action-items/init/shopify/storeWide",
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };
  //Shopify Featured
  static shopifyFeatured = async (data) => {
    try {
      const response = await this._api.patch(
        `/creators/touchpoints/action-items/init/shopify/featured`,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };
  static updateFan = async (fanId, body) => {
    try {
      const response = await this._api.patch(`fans/${fanId}`, body);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static fanTouchpoints = async (programId) => {
    try {
      const response = await this._api.get(`/fans/touchpoints/${programId}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static fanRedeem = async (programId) => {
    try {
      const response = await this._api.get(`/fans/rewards/${programId}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static fanSearch = async (index, limit) => {
    try {
      const response = await this._api.get(
        `creators/creators/${index}/${limit}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static purchaseRadeemPoint = async (rewardId, creatorId) => {
    try {
      const response = await this._api.patch(
        `/fans/rewards/purchase/${rewardId}/${creatorId}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  /******************** Creator API ****************/

  static creatorSignup = async (data) => {
    try {
      const response = await this._api.post("/auth/creator/signup", data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static creatorSignin = async (data) => {
    try {
      const response = await this._api.post("/auth/creator/signin", data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static creatorTouchpoints = async (creatorId) => {
    try {
      const response = await this._api.get(`/creators/touchpoints`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static fanReferrals = async (touchpoint, actionItem, body) => {
    try {
      const response = await this._api.patch(
        `/creators/touchpoints/action-items/init/${touchpoint}/${actionItem}`,
        body
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static creatorTouchpointInit = async (touchpoint, actionItem, body) => {
    try {
      const response = await this._api.patch(
        `/creators/touchpoints/action-items/init/${touchpoint}/${actionItem}`,
        body
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deactivateTouchpointActionItem = async (actionItemId, body) => {
    try {
      const response = await this._api.patch(
        `/creators/touchpoints/action-items/deactivate/${actionItemId}`,
        body
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static creatorTypeform = async (data) => {
    try {
      const response = await this._api.patch(
        `creators/touchpoints/init/typeform`,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static creatorUpdateTypeform = async (data) => {
    try {
      const response = await this._api.patch(
        `creators/touchpoints/action-items/${data.id}`,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getActionItemDetail = async (id) => {
    try {
      const response = await this._api.get(
        `creators/touchpoints/action-items/${id}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static submitCreatorQrcodeEvent = async (data) => {
    try {
      const response = await this._api.patch(
        `creators/touchpoints/action-items/init/qrCode/event`,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static creatorDeleteActionItem = async (data) => {
    try {
      const response = await this._api.patch(
        `creators/touchpoints/action-items/${data.id}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static creatorLeaderboard = async (creatorId, limit, index) => {
    try {
      const response = await this._api.get(
        `/creators/leaderboard/${creatorId}/${limit}/${index}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static checkCreatorUsername = async (username) => {
    try {
      const response = await this._api.get(
        `/creators/verify-username-exists/${username}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static fetchCreatorAssets = async (creatorId, programId) => {
    try {
      const response = await this._api.get(`/programs`, {
        params: { creatorId, programId },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static creatorUploadAssets = async (creatorId, programId, data) => {
    try {
      const response = await this._api.patch(
        `/programs/${creatorId}/${programId}/assets`,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static findCreatorByUsername = async (username) => {
    try {
      const response = await this._api.get(`/creators/${username}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static filterByCreator = async (filter) => {
    try {
      const response = await this._api.get(`/creators`, { params: filter });
      //{filter:{where:{username:username}, limit:1}}
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static creatorFind = async () => {
    try {
      const response = await this._api.get(`creators`, {
        params: { id: "Ehh7GGpqwRPKjBMZHwGRnvQUzf4mtJfUV7WhJCPBjYCa" },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getUsernameByTouchPoint = async (touchpoint, touchpointId) => {
    try {
      const response = await this._api.get(
        `fans/touchpoints/username/${touchpoint}/${touchpointId}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static qrcodeActionItemScanerEvent = async (data, creatorId) => {
    try {
      const response = await this._api.patch(
        `/fans/touchpoints/action-items/execute/${creatorId}/qrCode/scanner-event`,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static subscribeActionItemEvent = async (creatorId) => {
    try {
      const response = await this._api.patch(
        `/fans/touchpoints/action-items/execute/${creatorId}/featured/subscribe`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  /** fans resources */

  static getFans = async (fanId) => {
    try {
      const response = await this._api.get(`fans/${fanId}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getFanProgram = async (params) => {
    try {
      const response = await this._api.get(`/programs/byUsername`, {
        params: params,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };
  /** creators resources */

  static patchCreators = async (resource, data) => {
    try {
      const response = await this._api.patch(`creators/${resource}`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static updateFansExtra = async (data) => {
    try {
      const response = await this._api.post(`fans/info`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static fetchCreators = async (resource, params) => {
    try {
      const response = await this._api.get(`creators/${resource}`, {
        params: params,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static fetchNotifications = async (creator_id) => {
    try {
      const response = await this._api.get(`fans/notifications/${creator_id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static submitSurvey = async (creator_id, data) => {
    try {
      const response = await this._api.patch(
        `fans/touchpoints/action-items/execute/${creator_id}/featured/survey`,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static followCheck = async (creator_id, touchpoint, touchpointId) => {
    try {
      const response = await this._api.patch(
        `fans/follow-check/${touchpoint}/${touchpointId}/${creator_id}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  /**
   * Authorizee
   */

  static authorizeActionItem = async (data) => {
    try {
      const response = await this._api.post(`shares`, {...data, ip: this._ip});
      return response;
    } catch (error) {
      return error.response;
    }
  }

  static verificationAction = async (data) => {
    try {
      const response = await this._api.post(`auth/verify`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }
  
  static phoneAuthentication = async (data) => {
    try {
      const response = await this._api.post(`auth/encore/phone`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  static emailAuthentication = async (data) => {
    try {
      const response = await this._api.post(`auth/encore/email`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  static notifyMe = async (data) => {
    try {
      const response = await this._api.post(`fanbackpack/subscription`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  /**
   * Program
   */

  static submitProgram = async (data) => {
    try {
      const response = await this._api.post(`programs`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  /** Creator TouchPoints */

  static submitCreatorTouchpoint = async (data) => {
    /**
     * {
        "programId": "d34cdecc-b0ca-4de9-ab83-2608115291fa",
        "type": "clicks",
        "name": "Media",
        "authorized": true,
        "active": true
        }
     */
    try {
      const response = await this._api.post(`/touchpoints`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static updateCreatorTouchpoint = async (data) => {
    /**
     * {
     * "id":"String",
        "programId": "d34cdecc-b0ca-4de9-ab83-2608115291fa",
        "type": "clicks",
        "name": "Media",
        "authorized": true,
        "active": true
        }
     */
    try {
      const response = await this._api.put(`/touchpoints`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteCreatorTouchpoint = async (data) => {
    /**
     * {
        "id":"1a1dd209-4523-44f0-a43d-aa3014b3c67b",
        "programId": "d34cdecc-b0ca-4de9-ab83-2608115291fa",
        "type": "referrals"
      }
     */
    try {
      const response = await this._api.delete(`/touchpoints`, { data });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static fetchCreatorTouchpoint = async (programId) => {
    try {
      const response = await this._api.get(
        `/touchpoints/creator/program/${programId}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  /** Creator Touchpoints Actions */

  static submitCreatorTouchpointAction = async (data) => {
    /**
     * {
        "id": "string",
        "programId": "string",
        "cbpId": "string",
        "tpId": "string",
        "type": "string",
        "action": "string",
        "title": "string",
        "points": 0,
        "description": "string",
        "config": {
          "limit": 0,
          "multiplier": 0,
          "timeMeasurement": 0,
          "supply": 0,
          "active": true,
          "token": "string",
          "url": "string",
          "postToken": "string",
          "postTimestamp": "string",
          "seed": "string",
          "redirectUrl": "string"
        },
        "touchpoint": {
          "id": "string",
          "programId": "string",
          "type": "string",
          "name": "string",
          "authorized": true,
          "active": true
        }
      }
     */
    try {
      const response = await this._api.post(`/touchpoints/actions`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static updateCreatorTouchpointAction = async (data) => {
    /**
     * {
        "id": "string",
        "programId": "string",
        "tpId": "string",
        "action": "string",
        "type": "string",
        "title": "string",
        "points": 0,
        "description": "string",
        "config": {
          "limit": 0,
          "multiplier": 0,
          "timeMeasurement": 0,
          "supply": 0,
          "active": true,
          "token": "string",
          "url": "string",
          "postToken": "string",
          "postTimestamp": "string",
          "seed": "string",
          "redirectUrl": "string"
        }
      }
     */
    try {
      const response = await this._api.put(`/touchpoints/actions`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteCreatorTouchpointAction = async (data) => {
    /**
     * {
        "id": "string",
        "programId": "string",
        "tpId": "string",
      }
     */
    try {
      const response = await this._api.delete(`/touchpoints/actions`, { data });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getCreatorTouchpointAction = async (actionId, params) => {
    /**
     * {
        "programId": "string",
        "tpId": "string",
      }
     */
    try {
      const response = await this._api.get(`/touchpoints/actions/${actionId}`, {
        params,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  /** Creator Progrram */
  static updateCreatorProgram = async (programId, data) => {
    /**
     * {
     * "id":"programId",
        "creatorId": "d34cdecc-b0ca-4de9-ab83-2608115291fa",
        "website": "https:",
        }
     */
    try {
      const response = await this._api.put(`/programs/${programId}`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getCreatorProgram = async (params) => {
    /**
     * {
     * "id":"programId",
        "creatorId": "d34cdecc-b0ca-4de9-ab83-2608115291fa",
        "website": "https:",
        }
     */
    try {
      const response = await this._api.get(`/programs`, { params: params });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static submitCreatorSocial = async (data) => {
    /**
     * {
      "socialType": "facebook",
      "creatorId": "64e75f8c9ab0786da39c1cbd",
      "authToken": "1234",
      "redirectUri": "somewebsite.com",
      "touchpointDetails": {
          "programId": "08099885-cd01-471f-9432-8baca4533979",
          "type": "facebook",
          "name": "Facebook",
          "authorized": "true",
      }
     */
    try {
      const response = await this._api.post(`/creators/socials`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static submitFanSocial = async (data) => {
    /**
     * {
          "socialType": "instagram",
          "fanId": "8324832042",
          "authToken": "1234",
          "redirectUri": "somewebsite.com",
                      "shareDetails": {
                          programId: "123",
                          type: "instagram",
                          name: "Instagram",
                          tpId: "83294",
                          actionId: "0139",
                          pointsEarned: 500,
                      },
              }
      }
     */
    try {
      const response = await this._api.post(`/fans/socials`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  /** Creator Progrram */
  static submitFanRedemptions = async (data) => {
    /**
       {
        "rewardId": "123456789",
        "fanId": "9847243",
        "programId": "238491001",
        "points": "500",
        "title": "Water Bottle"
    } */
    try {
      const response = await this._api.post(`/redemptions`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  /** Touchpoint action engage */

  static touchpointActionEgnage = async (data) => {
    /**
     * {
     * query: 'refer-friend',
        programId,
        creatorId,
        actionId: `${v4()}`,
        referrerId: 'abc123',
        refereeId: 'xyz789'
        }
     */
    try {
      const response = await this._api.put(`/touchpoints/actions/engage`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static mixpanelEventsSend = async (data) => {
    try {
      const response = await this._api.post(`/programs/mixpanel/sendEvent`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getAllReferralsPrograms = async () => {
    try {
      const response = await this._api.get(`programs/referral`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static redemptionProgram = async (data) => {
    try {
      const response = await this._api.post(`/redemptions/referral`, data);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static downloadReport = async (param) => {
    try {
      const response = await this._api.put(`/reporting/run-report/creator`, param);
      return response;
    } catch (error) {
      return error.response;
    }
  };

}
