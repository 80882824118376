import { useAsync } from "@hooks/useAsync";
import { useCallback, useEffect } from "react";
import Api from "@services/api";
import { AxiosError, AxiosResponse } from "axios";
import { Item } from "@components/EarnItemCard/types";
import { useProgramContext } from "@hooks/useProgramContext";
let verifyStatus = 0;


interface UseSmsSubscriptionProps {
  phoneNumber?: string;
  verifiedCode?: string;
  item?: Item;
  onFinishItem?: () => void
}

export const useFetchPhoneCodes = ({
  phoneNumber,
  verifiedCode,
  item,
  onFinishItem
}: UseSmsSubscriptionProps) => {
  const { programId, userId } = useProgramContext();

  const authorizeData = {
    programId,
    fanId: userId,
    actionId: item?.id || '',
    tpId: item?.touchpoint?.id || "",
    pointsEarned: item?.points || 0,
    details:{
      phoneNumber:phoneNumber
    }
  };

  const authorizeFn = () => Api.authorizeActionItem(authorizeData);

  const sendCode = useCallback(() => {
    const data = {
      type: "sms",
      query: "sendToken",
      phoneNumber,
    };
    return  Api.verificationAction(data);
  }, [phoneNumber]);

  useEffect(() => {
    verifyStatus = 0;
  }, [phoneNumber])

  const {
    data: sendCodeData,
    isLoading: sendCodeLoading,
    error: sendCodeError,
    isError: sendCodeIsError,
    reDoTask: reSendCode,
  } = useAsync<AxiosResponse, AxiosError>({
    asyncFn: sendCode,
    skip: !phoneNumber,
  });

  const verifyCode = useCallback(() => {
    const data = {
      type: "sms",
      query: "verifyToken",
      phoneNumber,
      verifyCode: verifiedCode,
    };
    return Api.verificationAction(data);
  }, [phoneNumber, verifiedCode]);

  const {
    data: verifyCodeData,
    isError: verifyCodeIsError,
    isLoading: verifyCodeIsLoading,
    error: verifyCodeError,
    reDoTask: reVerifyCode,
  } = useAsync<AxiosResponse, AxiosError>({
    asyncFn: verifyCode,
    skip: !phoneNumber || !verifiedCode,
    onSuccess: (data) => {
      verifyStatus = data?.status;
      if (data.status === 200) {
        return authorizeFn().then(() => {
          if (onFinishItem) {
            onFinishItem();
          }
        });
      }
      return new Promise((accept) => {
        accept(true);
      });
    },
  });

  return {
    sendCodeData,
    sendCodeError: (() => {
      if (sendCodeError) {
        return sendCodeError;
      }
      if (sendCodeData) {
        if (sendCodeData.status === 500 && sendCodeData.data.status === 429) {
          return { messages: ["Too many requests"] };
        }
        if (sendCodeData.status !== 200) {
          return sendCodeError || { message: ["Unable to send code"] };
        }
      }
      return null;
    })(),
    sendCodeIsError,
    sendCodeLoading,
    verifyCodeData,
    verifyCodeIsError,
    verifyCodeIsLoading,
    verifyCodeError,
    reSendCode,
    reVerifyCode,
    verifyStatus
  };
};
