import Reward from "@containers/fans/Reward";
import EnterToWin from "@containers/fans/EnterToWin";
import EcommerceToWin from "@containers/fans/E-commerce";
import Management from "@services/management-service";

const TemplatePage = () => {
  const management = Management.get();
  const type = management?.program?.config?.type || "loyalty";

  switch(type){
    case "etw":
      return <EnterToWin />;
      case "ecommerce":
      return <EcommerceToWin />;
    default:
      return <Reward />;
  }
};

export default TemplatePage;
