import React from "react";
import { Title } from "./components/title";
import { Item } from "./types";

interface EarnItemCardProps {
  disabled?: boolean;
  dataTestId?: string;
  disabledCard?:boolean;
  // The toggle can be clicked once clicked it can
  // show the content that is the isOpened
  isOpened?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  title: string;
  item?: Item;
  description?: string;
  backgroundColor?: string;
  icon?: string;
}

export const EarnItemCard = ({
  disabled = false,
  dataTestId,
  disabledCard,
  description,
  isOpened,
  onClick,
  children,
  title,
  item,
  backgroundColor = "bg-tile",
  icon,
}: EarnItemCardProps) => {
  return (
    <>
    {disabledCard?children:
    <article
      data-testid={`card-${dataTestId}`}
      className={`relative ${backgroundColor} ${disabled && "opacity-50 "} 
      ${description ? "p-[4px_10px]":"p-[10px_10px]"}
      transition-all duration-700 ease-in-out rounded-[10px] cursor-pointer`}
    >
      <div
        className={`${
          isOpened
            ? "max-h-[100vh] transition-[max-height] ease-in-out duration-1000 "
            : ` lg:max-h-[${description ? 55 : 40}px] max-h-[${
                description ? 55 : 40
              }px]`
        } mt-2  p-[5px_0px]  transition-all duration-700 ease-in-out rounded-[10px] relative cursor-pointer overflow-hidden`}
      >
        <Title title={title} item={item} onClick={onClick} icon={icon} />
        {!!description &&
        <p data-testid={`desc-${item?.action}`}
          className={`text-[15px] font-light tracking-[0.02em] leading-[18px] mt-[5px] tile-inlay-primary description_link`}>
          {description}
        </p>}
      </div>
      <div
        className={`${
          isOpened
            ? "max-h-[100vh] transition-[max-height] ease-in-out duration-1000 "
            : "lg:max-h-[0px] max-h-[0px] overflow-hidden"
        }  pb-[5px] transition-all duration-700 ease-in-out rounded-[10px] relative cursor-pointer`}
      >
        {children}
      </div>
    </article>}
    </>
  );
};
