import Api from "@services/api";

const types = {
  FETCH_SCORES_PENDING: "FETCH_SCORES_PENDING",
  FETCH_SCORES_FALIURE: "FETCH_SCORES_FALIURE",
  FETCH_SCORES_SUCCESS: "FETCH_SCORES_SUCCESS",
  UPDATE_SCORES_SUCCESS: "UPDATE_SCORES_SUCCESS",
};
export const actions = {
  fetchScores: async (dispatch, programId) => {
    dispatch({ type: types.FETCH_SCORES_PENDING });
    const json = await Api.score(programId);
    if (json === undefined) {
      dispatch({
        type: types.FETCH_SCORES_FALIURE,
        data: { message: ["Can't get data from server"] },
      });
    } else if (200 !== json.status) {
      dispatch({ type: types.FETCH_SCORES_FALIURE, data: json.data });
    } else {
      dispatch({ type: types.FETCH_SCORES_SUCCESS, data: json.data?.data });
    }
    return json;
  },
};

const initialState = {
  points_available: 0,
  lifetime_points: 0,
  name: "",
  fans_rank: 0,
  total_fans_rank: 0,
  points_redeemed: 0,
  isFetching: false,
  error: {},
  data: {},
};

export const reducer = (state = initialState, action) => {
  const { type, token, user, data } = action;

  switch (type) {
    case types.FETCH_SCORES_PENDING: {
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    }
    case types.FETCH_SCORES_FALIURE: {
      return {
        ...state,
        isFetching: false,
        error: data,
      };
    }
    case types.FETCH_SCORES_SUCCESS: {
      return {
        ...state,
        lifetime_points: data.lifetimePoints,
        points_redeemed: data.points,
        fans_rank: data.rank,
        total_fans_rank: data.totalFans,
        data: data,
      };
    }
    case types.UPDATE_SCORES_SUCCESS: {
      return {
        ...state,
        lifetime_points: data.score.lifetimeScore,
        points_redeemed: data.score.points,
        fans_rank: data.score.rank,
        total_fans_rank: data.score.totalFans,
        data: {
          ...state.data,
          socials:{
            ...state.data.socials,
            ...data.score.socials
          }
        }
      };
    }

    default:
      return state;
  }
};
