import Management from "@services/management-service";
import { useSelector } from "react-redux";

const Background = (props) => {
  const management = Management.get()
  const background_image = management?.program?.assets?.landingPageImage;
  const cname = management?.creator?.username;
	const pname = management?.program?.name;

  const session = useSelector((state: any) => state.user.session);
	const isLogin = session?.[cname]?.[pname];

  if (background_image && !isLogin) {
    return (
      <div {...props} className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${background_image})` }}>
        {props.children}
      </div>
    );
  }
  return props?.children
  
};

export default Background;
