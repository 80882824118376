import React, { useState } from "react";
import Loader from "@components/Loader";
import Api from "@services/api";
import { useSelector } from "react-redux";

const SubscriptionEmail = (props) => {
    const {handleSubscribe, isAuthorizeLoader, data} = props;
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [codeError, setCodeError] = useState("");
	const [code, setCode] = useState("");
	const [step, setStep] = useState(false);
	const [loader, setLoader] = useState(false);
	const [resendLoader, setResendLoader] = useState(false);
	const creator = useSelector((state: any) => state.creator);
	const template = creator?.program?.config?.type;

	const onChangeEmail = () => {
		setStep(false);
		setCode("");
		setCodeError("");
	};

	const handleSendCode = async () => {
		if (!validation(email)) return;
		setLoader(true);
		const data = {
			type: "email",
			query: "sendToken",
			email: email,
		};
		const res = await Api.verificationAction(data);
		if (res?.status == 200) {
			setResendLoader(false);
			setEmailError("");
			setStep(true);
			setLoader(false);
		} else {
			setEmailError(res?.data?.message?.[0] || "Invalid email address");
			setLoader(false);
		}
	};

	const handleResendCode = async () => {
		setResendLoader(true);
		const data = {
			type: "email",
			query: "sendToken",
			email: email,
		};
		const res = await Api.verificationAction(data);
		if (res?.status == 200) {
			setResendLoader(false);
			setEmailError("");
			setStep(true);
		} else {
			setEmailError(res?.data?.message?.[0] || "Invalid email address");
			setResendLoader(false);
		}
	};

	const handleVerifyCode = async () => {
		setLoader(true);
		const data = {
			type: "email",
			query: "verifyToken",
			email: email,
			verifyCode: code,
		};
		const res = await Api.verificationAction(data);
		setLoader(false);
		if (res?.status == 200 && res?.data?.status == "approved") {
			setCodeError("");
			handleSubscribe("email", email);
		} else if (res?.status === 409) {
			setCodeError("Email already associated with another user");
		} else {
			setCodeError("Invalid verification code");
		}
	};

	const validation = (value: any) => {
		const validRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

		if (!validRegex.test(value)) {
			setEmailError("Invalid email address");
			return false;
		} else {
			setEmailError("");
			return true;
		}
	};

	return (data?.email || data?.extraInfo?.email) ?
    <div className={`mt-3 bg-tile-form ${template == "loyalty" ? "opacity-50" : ""} px-3 flex items-center justify-between rounded-[10px]`}>
			<input
				onKeyDown={(e) => {
					if (e.keyCode === 13) {
						handleSubscribe("email", (data?.email || data?.extraInfo?.email));
					}
				}}
				type="text"
				disabled={true}
				placeholder={"Email Address"}
				value={data?.email || data?.extraInfo?.email}
				className={`w-full h-[40px] bg-transparent disabled:opacity-100 rounded-[10px] tile-inlay-primary placeholder:tile-inlay-primary placeholder:text-[14px] shadow-none outline-none`}
			/>
			<button
				onClick={() => handleSubscribe("email", data?.email || data?.extraInfo?.email)}
				disabled={isAuthorizeLoader}
				className={`bg-btn flex items-center justify-center w-[80px] h-[25px] rounded-full btn-inlay-primary py-[6px] px-[12px]`}
			>
				{isAuthorizeLoader ? (
					<Loader height={20} width={20} />
				) : (
					"SUBMIT"
				)}
			</button>
        </div>:
        <div onClick={(e) => {
					e.stopPropagation();
				}}>
			<div
				className={`${template == "ecommerce" ? "bg-tile-form" : "bg-tile-form"} ${template == "loyalty" ? "opacity-50" : ""} px-3 mt-3 flex items-center justify-between rounded-[10px]`}
			>
				<input
					type="text"
					placeholder={"Email Address"}
					onChange={(e) => setEmail(e.target.value)}
					onKeyDown={(e) => {
						if (e.keyCode === 13) {
							handleSendCode();
						}
					}}
					value={email}
					disabled={step}
					className={`w-full h-[40px] rounded-[10px] bg-transparent disabled:opacity-100 tile-inlay-primary placeholder:text-[black]/40 placeholder:text-[14px] shadow-none outline-none`}
				/>
				<>
					{!step ? (
						<button
							onClick={handleSendCode}
							disabled={loader}
							className={`bg-btn ${
								email == "" && "opacity-30"
							} flex items-center justify-center w-[80px] h-[25px] rounded-full btn-inlay-primary py-[6px] px-[12px]`}
						>
							{loader ? (
								<Loader height={20} width={20} />
							) : (
								"SUBMIT"
							)}
						</button>
					) : (
						<button
							onClick={onChangeEmail}
							className={`bg-btn flex items-center justify-center w-[80px] h-[25px] rounded-full btn-inlay-primary py-[6px] px-[12px]`}
						>
							CHANGE
						</button>
					)}
				</>
			</div>
			{emailError && (
				<p
					className="font-normal text-[10px] mt-1 text-center text-red-500 "
				>
					{emailError}
				</p>
			)}
			{step && (
				<>
					<div
						className="py-1"
					>
						<div className={`${template == "ecommerce" || template == "etw" ? "bg-tile-form" : "bg-body"} ${template == "loyalty" ? "opacity-50" : ""} px-3 flex items-center justify-between rounded-[10px]`}>
							<input
								onChange={(e) => setCode(e.target.value)}
								onKeyDown={(e) => {
									if (e.keyCode === 13) {
										handleVerifyCode();
									}
								}}
								type="text"
								value={code || ""}
								className={`w-full h-[40px] rounded-[10px] disabled:opacity-100 bg-transparent tile-inlay-primary placeholder:text-[black]/40 placeholder:text-[14px] shadow-none outline-none`}
								placeholder="Verification Code"
							/>
							<button
								disabled={code == "" || loader || (code || "").length < 6}
								onClick={handleVerifyCode}
								className={`bg-btn ${
									(code || "").length < 6 && "opacity-30"
								} flex items-center justify-center w-full max-w-[80px] min-w-[80px] h-[25px] rounded-full btn-inlay-primary py-[6px] px-[12px]`}
							>
								{loader ? (
									<Loader height={20} width={20} />
								) : (
									"SUBMIT"
								)}
							</button>
						</div>
					</div>
					{codeError && (
				<p
					className="font-normal text-[10px] mt-1 text-center text-red-500 "
				>
					{codeError}
				</p>
			)}
					<div
					>
						<p className={`tile-inlay-primary font-normal text-[14px] lg:text-[16px] leading-5 text-center mt-2`}>
							Didn't receive a code?
						</p>
						{resendLoader ? (
							<div className="mt-2">
								<Loader height={20} width={20} color={"#000"} />
							</div>
						) : (
							<p
								onClick={handleResendCode}
								className={`font-[700] text-[14px] lg:text-[16px] leading-8 tile-inlay-primary text-center cursor-pointer`}
							>
								Resend
							</p>
						)}
					</div>
				</>
			)}
		</div>
};
export default SubscriptionEmail;
