import { io, Socket } from "socket.io-client";
import service from "@services/callback-service";
import emitter from "@services/emitter-service";
import store from "@store/configureStore";
import Mixpanel from "./mixpanel-events";
import mTouchpoints from '@model/touchpoints-model';
import logo_letter from "@assets/logo_letter.png";
import { toast } from "react-toastify";

class SocketService {
  private accessToken: string | null = null;
  private socket: Socket | null = null;

  private static instance: SocketService;

  // The method to get or create the Singleton instance
  public static getInstance(): SocketService {
    if (!SocketService.instance) {
      SocketService.instance = new SocketService();
    }
    return SocketService.instance;
  }

  init(token: string) {
    this.accessToken = token;
    // Only initialize the socket if it hasn't been initialized yet
    if (!this.socket) {
      this.socket = io(process.env.REACT_APP_SOCKET_URL, {
        reconnection: true,
        reconnectionAttempts: 3,
        extraHeaders: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });

      this.socket.on("connect", this.onConnect.bind(this));
      this.socket.on("disconnect", this.onDisconnect.bind(this));
      this.socket.on("programFan", this.onProgramFan.bind(this));
      this.socket.on("programFanRedeem", this.onProgramFanRedeem.bind(this));
      this.socket.on("error", this.onError.bind(this));
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket = null;
    }
  }
  

  onConnect() {
    // Socket connected
  }

  onDisconnect(reason: string) {
    // You can handle reconnection logic here if needed.
  }

  onProgramFan(data: any) {
    if (data?.action?.action === "sms" || data?.action?.action === "email") {
      emitter.emit('fetchProgramFan')
    }
    emitter.emit('typeFormPopup',{isOpen:false})
     // Handle incoming messages here.

    //This will trigger complete threshold when it will get last completed touchpoint for a specific program via socket
    if (mTouchpoints.getFilterData().usedItem + 1 === mTouchpoints.getFilterData().totalItem) {
      Mixpanel.complete();
    }

    const score = {
      score: {
        lifetimeScore:data?.programFan?.lifetimePoints,
        points:data?.programFan?.points,
        rank:data?.programFan?.rank,
        totalFans:data?.programFan?.totalFans,
        socials:data?.programFan?.socials,
      }
    }
    service.updateScore(score);
    service.updateActionItem(data?.action);

    const socketData = {
      score:data?.programFan,
      data:data?.action
    }
    if (store.getState().creator?.program?.config?.type !== "ecommerce"){
    service.addToNotificationQueue(socketData);
    }
  }

  onProgramFanRedeem(data:any){
    const score = {
      score: {
        lifetimeScore:data?.programFan?.lifetimePoints,
        points:data?.programFan?.points,
        rank:data?.programFan?.rank,
        totalFans:data?.programFan?.totalFans,
      }
    }
    service.updateScore(score);

    const socketData = {
      score:data?.programFan,
      data:data?.redemption,
      type:"redeem"
    }
   
    emitter.emit("NotificationPopup",[socketData])
  }

  onError(data: any) {
    toast.error(data?.msg, {
      icon: () => <img alt="logo" src={logo_letter} />,
    });
  }

  sendMessage(ev) {
    if (this.socket && this.socket.connected) {
      this.socket.emit('mixpanel_event', JSON.stringify(ev));
    } else {
      console.error('Socket is not connected. Cannot send message.');
    }
}}
// Export the Singleton instance
const socketService = SocketService.getInstance();

export default socketService;
