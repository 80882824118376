import Buttonlink from '@components/Button';
import FullName from '@components/FullName';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import common from "@common/common";
import mail from "@services/mail-service"
import ProgramBox from '@components/ProgramBox';
import { useParams } from "react-router-dom";
import Mixpanel from '@services/mixpanel-events';

const MobileHeadEcommerce = (props: any) => {
    const { actions } = require("@redux/UserRedux");
    const fanId = useSelector((state: any) => (state.user?.user?.user?.id));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {cname, pname} = useParams();
    const session = useSelector((state: any) => state.user.session);
	const token = session?.[cname]?.[pname];
    const handleLogout = () => {
        Mixpanel.send("Logged Out");
        dispatch(actions.setLogout(true));
        navigate(`/${cname}/${pname}/logout`);
    };

    const contactUs = () => {
        Mixpanel.send('Menu Item Tapped', {name: "Contact Us"})
        window.location.href = `mailto:${process.env.REACT_APP_CONTACT_US_MAIL}`;
    }

    return (
        <>
            <div className='flex grow mt-4 w-full'>
                <div className="flex flex-col grow">
                    {(token || props?.backpack) &&
                    <div className="bg-tile rounded-xl w-full h-[121px] flex flex-col gap-3 justify-center items-center">
                        <p className={`font-semibold text-[20px] leading-7 tile-inlay-primary`}>
                            <FullName />
                        </p>
                        <Buttonlink
                            onClick={handleLogout}
                            className="cursor-pointer uppercase w-[137px] text-[14px] p-[7px_32px_8px_32px] btn-inlay-primary bg-btn text-center rounded-full"
                            text="log out"
                        />
                    </div>}
                    <div className="flex flex-col mt-9 gap-[25px] justify-center items-center">
                        <div>
                            <a
                                target="_blank"
                                onClick={() => Mixpanel.send('Menu Item Tapped', {name: "Terms of Use"})}
                                href={common.term_of_service_url}
                                className="block body-inlay-primary font-normal leading-5 text-center text-[16px] cursor-pointer relative" rel="noreferrer">
                                Terms of Use
                            </a>
                        </div>
                        <div>
                            <Buttonlink
                                onClick={contactUs}
                                className="block body-inlay-primary font-normal leading-5 text-center text-[16px] cursor-pointer relative"
                                text="Contact Encore"
                            />
                        </div>
                        <div>
                            <a
                                onClick={() => {
                                    mail.summary(fanId)
                                    Mixpanel.send('Menu Item Tapped', {name: "Request Data Summary"})
                                }}
                                className="block body-inlay-primary font-normal leading-5 text-center text-[16px] cursor-pointer relative">
                                Request Data Summary
                            </a>
                        </div>
                        <div>
                            <a
                                onClick={() => {
                                    mail.removal(fanId)
                                    Mixpanel.send('Menu Item Tapped', {name: "Request Data Removal"})
                                }}
                                className="block body-inlay-primary font-normal leading-5 text-center text-[16px] cursor-pointer relative">
                                Request Data Removal
                            </a>
                        </div>
                    </div>
                    <div className='grow lg:mt-0 mt-10 items-end justify-center flex'>
                    <ProgramBox isLink={true} box="POWERED_BY"></ProgramBox>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileHeadEcommerce