import React from 'react'
import { Dialog, Transition } from "@headlessui/react";

const TypeFormPopup = React.forwardRef((props, ref) => {
    const [isTypeFormOpen, setTypeFormOpen] = React.useState(false);
    const [data, setData]:any = React.useState({});

    React.useImperativeHandle(ref, () => ({
        open(data) {
            setData(data);
            setTypeFormOpen(true);
        },
        close() {
            setTypeFormOpen(false);
            setData({})
        },
      }));
  return (
    <> 
    {
        data?.isOpen &&
            <Transition appear show={isTypeFormOpen} as={React.Fragment}>
                <Dialog as="div" className="relative z-50" onClose={()=>setTypeFormOpen(false)}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/60" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center lg:p-4 text-center">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="lg:w-screen lg:h-[calc(100vh-50px)] w-screen h-screen relative transform lg:overflow-hidden lg:p-6 text-left align-middle transition-all">
                        <div className="lg:relative lg:right-0 absolute right-5">
                            <button
                            type="button"
                            className="text-white rounded-md outline-none hover:text-white hover:opacity-70"
                            onClick={() => setTypeFormOpen(false)}
                            >
                            <span className="sr-only">Close panel</span>
                            <svg className="w-8 h-8 tile-inlay-primary lg:absolute lg:-top-5 lg:right-0"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg>
                            </button>
                        </div>
                        <iframe src={data.data} width={'100%'} height={'100%'}></iframe>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </Dialog>
            </Transition>
    }
    </>
  )
})

export default TypeFormPopup