import React from "react";
import { convertToTitleCase, isProgramActive, numberToWords, openEncoreWebsite } from "@helpers/common";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "@assets/logo_letter.png";
import blackLogo from "@assets/encore_activate_icon.png"
import time from "@assets/time.png";
import secure from "@assets/secure.png";
import reward from "@assets/rewards.png";
import common from "@common/common";
import Mixpanel from "@services/mixpanel-events";
import mTouchpoints from '@model/touchpoints-model';

const ProgramBox = (props) => {
	const creator = useSelector((state: any) => state?.creator);
	const isActive = isProgramActive(creator?.program);
	const programData = creator.program?.config?.data;
	const programAssets = creator.program?.assets;
	const creatorName = creator?.creator?.displayName || convertToTitleCase(creator?.creator?.username) || "";
	const {winnerList,isLink, currentScreen, opacity, etw} = props;
	const remaining = mTouchpoints?.getFilterData()?.totalItem - mTouchpoints?.getFilterData()?.usedItem;
	const fullDisc = programData?.tiers?.[Object.keys(programData?.tiers)?.length-1]?.disc?.heading;

	const thresholdToWords = (threshold) => {
		const result = numberToWords(threshold);
		  return result;
	  };
	  
	const resultInWords = thresholdToWords(programData?.limit);

	const LANDING_PAGE_HEADING_TITLE = () => {
		if (!isActive) {
			// return programData.deactivateprogramtext;
			return 'This activation has ended.';
		}
		if (programData) {
		return programData?.landing_page?.heading;
	} else {
		return <>Join the {props.children} community</>;
	}};

	const LANDING_PAGE_DESCRIPTION = () => {
		if (!isActive) {
			// return programData.deactivateprogramdescription;
			return 'Thanks for your participation';
		} 
		if (programData) {
			return programData?.landing_page?.subheading;
		}
	};

	const LANDING_PAGE_SECOND_DESCRIPTION = () => {
		if (programData && isActive) {
			return programData?.landing_page?.description;
		}
	};

	const DASHBOARD_HEADING = () => {
		if (programData) {
			if (winnerList){
				if (currentScreen >= 0) {
					switch (currentScreen) {
						case 0:
						 return programData?.tiers?.[0]?.before?.heading?.replace("{tier[last].disc.heading}", fullDisc);
						case Object.keys(programData?.tiers).length:
							return programData?.tiers?.[currentScreen-1]?.after?.heading;
						default:
							return programData?.tiers?.[currentScreen-1]?.after?.heading?.replace("{disc.heading}", programData?.tiers?.[currentScreen-1]?.disc?.heading)
					  }
				}
				return programData?.tiers?.[0]?.after?.heading?.replace("{disc.heading}", fullDisc);
			} else {
				return programData?.tiers?.[0]?.before?.heading?.replace("{disc.heading}", fullDisc);
			}
		}
	};

	const DASHBOARD_SUBHEADING = () => {
		if (programData) {
			if (winnerList){
				return programData?.tiers?.[0]?.after?.subheading;
			} else {
				return programData?.tiers?.[0]?.before?.subheading;
			}
		}
	};

	const DASHBOARD_DESCRIPTION = () => {
		if (programData) {
			if (currentScreen >= 0) {
				switch (currentScreen) {
					case 0:
						return programData?.tiers?.[0]?.before?.description?.replace("{remainingLimit}", numberToWords(remaining)).replace("items", remaining === 1 ? "last item":"more items")
					case Object.keys(programData?.tiers).length:
						return programData?.tiers?.[currentScreen-1]?.after?.description?.replace("{disc.heading}", fullDisc);
					default:
						return programData?.tiers?.[currentScreen-1]?.after?.description?.replace("{tier[last].disc.heading}", fullDisc)
						?.replace("{remainingLimit}", numberToWords(remaining))?.replace("items", remaining === 1 ? "last item":"more items")
				  }
			}
			if (winnerList){
				return programData?.tiers?.[0]?.after?.description?.replace("{disc.heading}", fullDisc)
				.replace("{limit}", resultInWords);
			} else {
				return programData?.tiers?.[0]?.before?.description?.replace("{disc.heading}", fullDisc)
				.replace("{limit}", resultInWords);
			}
		}
	};

	const ACTION_COMPLETE_HEADING = () => {
		if (programData) {
			return programData?.tiers?.[0]?.popup?.heading;
	}
};

	const ACTION_COMPLETE_DESCRIPTION = () => {
		if (programData) {
			return programData?.tiers?.[0]?.popup?.description;
	}
};

const ACTION_COMPLETE_REDIRECT_HEADING = () => {
	if (programData) {
		return programData?.tiers?.[0]?.popup?.extra?.heading;
}
};

const ACTION_COMPLETE_REDIRECT_DESCRIPTION = () => {
	if (programData) {
		return programData?.tiers?.[0]?.popup?.extra?.description;
}
};

const DISCOUNT_DISCRIPTION = () => {
	if (programData) {
		return programData?.tiers?.[currentScreen-1].disc?.heading;
}
}

const EMOJIS = () => {
	return (
		<div className="w-full max-w-sm flex justify-evenly mx-auto items-center pt-5">
			<div className="flex justify-center items-center flex-col space-y-2">
				<img src={reward} alt="reward" width={18} height={18} />
				<p className="font-normal leading-[18px] text-[14px] uppercase tracking-[0.03em] body-inlay-primary">get rewards</p>
			</div>
			<div className="flex justify-center items-center flex-col space-y-2">
				<img src={time} alt="time" width={12} height={8} />
				<p className="font-normal leading-[18px] text-[14px] uppercase tracking-[0.03em] body-inlay-primary">save time</p>
			</div>
			<div className="flex justify-center items-center flex-col space-y-2">
				<img src={secure} alt="secure" width={14} height={14} />
				<p className="font-normal leading-[18px] text-[14px] uppercase tracking-[0.03em] body-inlay-primary">safe & secure</p>
			</div>
		</div>
	)
}

const BEFORE_LOGIN = () => {
	return (
		<div className="flex justify-center mt-[26px] mb-[50px]">
			<div className="text-[36px] tracking-[0.02em] leading-[43px] body-inlay-primary">
				{programData?.landing_page?.heading?.replace("{tier[last].disc.heading}", fullDisc)}
			</div>
		</div>
	)
};

const LOGIN_FOOTER = () => {
	return (
		<div className={`flex flex-col ${etw ? "gap-5": "gap-7"}`}>
			<POWERED_BY />
			<span className={`tracking-[0.02em] font-normal leading-[15px] text-[12px] ${etw ? "opacity-70": "opacity-90"} max-w-[350px] text-center ${etw ? "body-inlay-primary":"landing-page-inlay"} `}>
				By proceeding, you agree to Encore's
				<a
					target="_blank"
					href={common.term_of_service_url}
					className="underline tracking-[0.02em] font-normal leading-[15px] text-[12px] max-w-[350px] text-center mx-1"
					rel="noreferrer"
				>
					Terms of Service
				</a>
				and acknowledge that you are 18 years or older.
			</span>
		</div>
	)
}

	const POWERED_BY = () => {
        if (programData && programData?.powered_by) {
			if(isLink){
				return(
					<div className={`flex flex-col items-center justify-center gap-y-1 mt-[25px] ${!props?.title && 'opacity-50'}`}>
						<button
						className="flex flex-col items-center justify-center gap-y-1">
						<p className="font-normal tracking-[0.02em] bg-logo-color leading-5 text-[15px]">
							{props?.title ?? "Powered by"}
						</p>
						<svg 
							onClick={(e) => {
								e.preventDefault();
								openEncoreWebsite();
								Mixpanel.send("Visited Encore Website", {button_location: "Encore Logo"})
							}}
						className="h-[25px] w-[140px] bg-logo" viewBox="0 0 114 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clipPath="url(#clip0_21_3220)">
							<path d="M0 1.56104L11.9424 1.56591C11.9873 1.57167 12.0316 1.5808 12.0751 1.59323V4.76299H3.96976V15.6898H12.078V18.9269H0V1.56104Z" fill="inherit"/>
							<path d="M66.0488 19.0244C63.1707 19.0244 60.293 19.0244 57.4156 19.0244C56.1929 19.0308 54.9821 18.7849 53.8589 18.3019C52.7356 17.819 51.7241 17.1095 50.8876 16.2178C50.0511 15.326 49.4076 14.2713 48.9974 13.1195C48.5871 11.9677 48.419 10.7437 48.5034 9.52391C48.7961 4.96879 52.6761 1.28586 57.2458 1.2722C63.0995 1.25594 68.9532 1.25594 74.8068 1.2722C79.399 1.28196 83.2166 4.85952 83.5932 9.47806C83.9619 13.9893 80.7229 18.1464 76.2371 18.8995C75.7234 18.9815 75.204 19.0216 74.6839 19.0195C71.8049 19.0303 68.9258 19.0244 66.0488 19.0244ZM66.0634 4.87806C63.186 4.87806 60.3089 4.87806 57.4322 4.87806C54.4546 4.88391 52.1034 7.20391 52.0985 10.1317C52.0985 13.0868 54.44 15.4107 57.4536 15.4127C63.1915 15.4166 68.9294 15.4166 74.6673 15.4127C74.9283 15.417 75.1893 15.401 75.4478 15.3649C78.2771 14.919 80.2039 12.52 79.9922 9.72098C79.7785 6.95318 77.4722 4.87806 74.5961 4.87806C71.7512 4.87806 68.9067 4.87806 66.0624 4.87806H66.0634Z" fill="inherit"/>
							<path d="M13.7961 18.8937V1.57758H15.0381C15.6078 1.57758 16.1776 1.59319 16.7454 1.57075C17.0381 1.55904 17.2108 1.65758 17.3757 1.90441C19.6287 5.26441 21.8882 8.62018 24.1542 11.9717C24.2352 12.0927 24.3191 12.2108 24.48 12.442V1.6127H28.1493V18.9268C27.0625 18.9268 25.9942 18.9386 24.9298 18.9122C24.803 18.9122 24.6518 18.7171 24.56 18.5825C22.3109 15.2576 20.067 11.9308 17.8283 8.60197C17.7376 8.46734 17.6439 8.33465 17.4693 8.08197V18.8937H13.7961Z" fill="inherit"/>
							<path d="M96.403 12.3267C97.4908 14.4623 98.6078 16.6535 99.7669 18.9267C98.5874 18.9267 97.4898 18.8896 96.3952 18.9423C95.8225 18.9696 95.5493 18.7755 95.3074 18.2594C94.5532 16.6594 93.7239 15.0945 92.9474 13.5053C92.7874 13.1775 92.5971 13.0672 92.2391 13.0789C91.3825 13.1062 90.5249 13.0877 89.6127 13.0877V18.9023H85.923V1.62916C88.8849 1.60282 91.8498 1.2555 94.7444 1.79599C99.9474 2.7716 100.312 8.56672 97.9639 11.0243C97.5113 11.5004 96.9522 11.875 96.403 12.3267ZM89.6391 9.95111C90.9142 9.95111 92.1493 10.034 93.3688 9.9277C94.6722 9.81452 95.4947 8.77843 95.5269 7.4594C95.562 6.04867 94.8293 4.95111 93.4908 4.80087C92.2313 4.6594 90.9444 4.77062 89.6391 4.77062V9.95111Z" fill="inherit"/>
							<path d="M101.721 18.8935V1.60376H113.316V4.76864H105.451V8.65937H111.825V11.7208H105.446V15.7072H113.594V18.8925L101.721 18.8935Z" fill="inherit"/>
							<path d="M46.3591 7.76586H42.6684C42.6254 7.65367 42.5708 7.52489 42.5259 7.3922C42.0245 5.85172 41.0186 4.85562 39.3893 4.62733C37.7601 4.39903 36.3649 4.90147 35.3562 6.25757C34.7064 7.12781 34.4001 8.13464 34.2927 9.19903C34.122 10.8966 34.2576 12.5503 35.2069 14.0351C36.1825 15.5571 37.6254 16.1083 39.362 15.8771C41.0108 15.6576 42.0293 14.6517 42.5425 13.0927C42.5884 12.9542 42.7376 12.7512 42.8469 12.7483C43.9991 12.7239 45.1513 12.7327 46.3532 12.7327C46.2673 13.8007 45.921 14.8315 45.3445 15.7346C44.1171 17.6585 42.3045 18.6693 40.0888 18.9737C38.0947 19.2478 36.1552 19.0293 34.3737 18.0234C32.3347 16.8722 31.1913 15.0585 30.7142 12.8088C30.203 10.3942 30.3123 8.0283 31.404 5.76586C32.5747 3.33659 34.5835 2.00098 37.1981 1.57562C39.3923 1.21952 41.5142 1.43806 43.4166 2.72001C45.0732 3.83513 45.9893 5.4283 46.3415 7.37074C46.3546 7.50203 46.3604 7.63393 46.3591 7.76586Z" fill="inherit"/>
							<path d="M7.25073 8.69263H10.2039V11.6712H7.25073V8.69263Z" fill="inherit" />
							</g>
							<defs>
							<clipPath id="clip0_21_3220">
							<rect width="113.951" height="20" fill="white"/>
							</clipPath>
							</defs>
						</svg>
						</button>
					</div>
				)
			}
			return (
				<>
					<div className={`flex flex-col items-center justify-center gap-y-1 mt-[25px] ${opacity === 'none' ? '' : !props?.title && 'opacity-50'}`}>
						<a
						target={"_blank"}
						rel="noreferrer noopener"
						className="flex flex-col items-center justify-center gap-y-1"
						href="https://encore.fans/">
						<p className={`font-normal tracking-[0.02em] bg-logo-color ${etw ? "leading-[17px] text-[14px]": "leading-5 text-[15px]"}`}>
							{props?.title ?? "Powered by"}
						</p>
						<svg 
						onClick={() => Mixpanel.send("Visited Encore Website", {button_location: "Encore Logo"})}
						className={`${etw ? "h-[20px] w-[130px]": "h-[25px] w-[140px]"} bg-logo`} viewBox="0 0 114 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clipPath="url(#clip0_21_3220)">
							<path d="M0 1.56104L11.9424 1.56591C11.9873 1.57167 12.0316 1.5808 12.0751 1.59323V4.76299H3.96976V15.6898H12.078V18.9269H0V1.56104Z" fill="inherit"/>
							<path d="M66.0488 19.0244C63.1707 19.0244 60.293 19.0244 57.4156 19.0244C56.1929 19.0308 54.9821 18.7849 53.8589 18.3019C52.7356 17.819 51.7241 17.1095 50.8876 16.2178C50.0511 15.326 49.4076 14.2713 48.9974 13.1195C48.5871 11.9677 48.419 10.7437 48.5034 9.52391C48.7961 4.96879 52.6761 1.28586 57.2458 1.2722C63.0995 1.25594 68.9532 1.25594 74.8068 1.2722C79.399 1.28196 83.2166 4.85952 83.5932 9.47806C83.9619 13.9893 80.7229 18.1464 76.2371 18.8995C75.7234 18.9815 75.204 19.0216 74.6839 19.0195C71.8049 19.0303 68.9258 19.0244 66.0488 19.0244ZM66.0634 4.87806C63.186 4.87806 60.3089 4.87806 57.4322 4.87806C54.4546 4.88391 52.1034 7.20391 52.0985 10.1317C52.0985 13.0868 54.44 15.4107 57.4536 15.4127C63.1915 15.4166 68.9294 15.4166 74.6673 15.4127C74.9283 15.417 75.1893 15.401 75.4478 15.3649C78.2771 14.919 80.2039 12.52 79.9922 9.72098C79.7785 6.95318 77.4722 4.87806 74.5961 4.87806C71.7512 4.87806 68.9067 4.87806 66.0624 4.87806H66.0634Z" fill="inherit"/>
							<path d="M13.7961 18.8937V1.57758H15.0381C15.6078 1.57758 16.1776 1.59319 16.7454 1.57075C17.0381 1.55904 17.2108 1.65758 17.3757 1.90441C19.6287 5.26441 21.8882 8.62018 24.1542 11.9717C24.2352 12.0927 24.3191 12.2108 24.48 12.442V1.6127H28.1493V18.9268C27.0625 18.9268 25.9942 18.9386 24.9298 18.9122C24.803 18.9122 24.6518 18.7171 24.56 18.5825C22.3109 15.2576 20.067 11.9308 17.8283 8.60197C17.7376 8.46734 17.6439 8.33465 17.4693 8.08197V18.8937H13.7961Z" fill="inherit"/>
							<path d="M96.403 12.3267C97.4908 14.4623 98.6078 16.6535 99.7669 18.9267C98.5874 18.9267 97.4898 18.8896 96.3952 18.9423C95.8225 18.9696 95.5493 18.7755 95.3074 18.2594C94.5532 16.6594 93.7239 15.0945 92.9474 13.5053C92.7874 13.1775 92.5971 13.0672 92.2391 13.0789C91.3825 13.1062 90.5249 13.0877 89.6127 13.0877V18.9023H85.923V1.62916C88.8849 1.60282 91.8498 1.2555 94.7444 1.79599C99.9474 2.7716 100.312 8.56672 97.9639 11.0243C97.5113 11.5004 96.9522 11.875 96.403 12.3267ZM89.6391 9.95111C90.9142 9.95111 92.1493 10.034 93.3688 9.9277C94.6722 9.81452 95.4947 8.77843 95.5269 7.4594C95.562 6.04867 94.8293 4.95111 93.4908 4.80087C92.2313 4.6594 90.9444 4.77062 89.6391 4.77062V9.95111Z" fill="inherit"/>
							<path d="M101.721 18.8935V1.60376H113.316V4.76864H105.451V8.65937H111.825V11.7208H105.446V15.7072H113.594V18.8925L101.721 18.8935Z" fill="inherit"/>
							<path d="M46.3591 7.76586H42.6684C42.6254 7.65367 42.5708 7.52489 42.5259 7.3922C42.0245 5.85172 41.0186 4.85562 39.3893 4.62733C37.7601 4.39903 36.3649 4.90147 35.3562 6.25757C34.7064 7.12781 34.4001 8.13464 34.2927 9.19903C34.122 10.8966 34.2576 12.5503 35.2069 14.0351C36.1825 15.5571 37.6254 16.1083 39.362 15.8771C41.0108 15.6576 42.0293 14.6517 42.5425 13.0927C42.5884 12.9542 42.7376 12.7512 42.8469 12.7483C43.9991 12.7239 45.1513 12.7327 46.3532 12.7327C46.2673 13.8007 45.921 14.8315 45.3445 15.7346C44.1171 17.6585 42.3045 18.6693 40.0888 18.9737C38.0947 19.2478 36.1552 19.0293 34.3737 18.0234C32.3347 16.8722 31.1913 15.0585 30.7142 12.8088C30.203 10.3942 30.3123 8.0283 31.404 5.76586C32.5747 3.33659 34.5835 2.00098 37.1981 1.57562C39.3923 1.21952 41.5142 1.43806 43.4166 2.72001C45.0732 3.83513 45.9893 5.4283 46.3415 7.37074C46.3546 7.50203 46.3604 7.63393 46.3591 7.76586Z" fill="inherit"/>
							<path d="M7.25073 8.69263H10.2039V11.6712H7.25073V8.69263Z" fill="inherit" />
							</g>
							<defs>
							<clipPath id="clip0_21_3220">
							<rect width="113.951" height="20" fill="white"/>
							</clipPath>
							</defs>
						</svg>
						</a>
					</div>
				</>
			);
        }
	};

	
	switch (props.box) {
		case "LANDING_PAGE_HEADING_TITLE":
			return LANDING_PAGE_HEADING_TITLE();
		case "LANDING_PAGE_DESCRIPTION":
			return LANDING_PAGE_DESCRIPTION();
		case "POWERED_BY":
			return POWERED_BY();
		case "DASHBOARD_HEADING":
			return DASHBOARD_HEADING();
		case "DASHBOARD_SUBHEADING":
			return DASHBOARD_SUBHEADING();
		case "DASHBOARD_DESCRIPTION":
			return DASHBOARD_DESCRIPTION();
		case "ACTION_COMPLETE_HEADING":
			return ACTION_COMPLETE_HEADING();
		case "ACTION_COMPLETE_DESCRIPTION":
			return ACTION_COMPLETE_DESCRIPTION();
		case "LANDING_PAGE_SECOND_DESCRIPTION":
			return LANDING_PAGE_SECOND_DESCRIPTION();
		case "ACTION_COMPLETE_REDIRECT_HEADING":
			return ACTION_COMPLETE_REDIRECT_HEADING();
		case "ACTION_COMPLETE_REDIRECT_DESCRIPTION":
			return ACTION_COMPLETE_REDIRECT_DESCRIPTION();
		case "DISCOUNT_DISCRIPTION":
			return DISCOUNT_DISCRIPTION();
		case "BEFORE_LOGIN":
			return BEFORE_LOGIN();
		case "LOGIN_FOOTER":
			return LOGIN_FOOTER();
		case "EMOJIS":
			return EMOJIS();
		default:
			return <></>;
	}
};

export default ProgramBox
