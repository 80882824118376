import React from 'react'
import getRewarded from '@assets/getrewarded.png'
import SafeSecure from '@assets/safe&secure.png'
import SpamFree from '@assets/spamfree.png'
import logo from '@assets/logo.png'
import SaveTime from '@assets/SaveTime.png'
import { useNavigate } from 'react-router-dom'
type Props = {}

const InfoContent = (props: Props) => {
    const navigate = useNavigate();
    return (
        <div className='h-screen flex flex-col'>
            <div className="bg-[#000] rounded-t-[20px] lg:rounded-t-[10px] p-4">
                <div className="flex justify-between">
                    <button
                        type="button"
                        className="text-white rounded-md outline-none hover:text-white hover:opacity-70"
                        onClick={()=>navigate(-1)}
                    >
                        <span className="sr-only">Close panel</span>
                        <svg width="8" height="15" className='opacity-80' viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.75 1.5L1.25 9L8.75 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className='flex justify-center items-center flex-col gap-5 pb-8 px-8'>
                    <img src={logo} className='w-[150px] h-[27px]' alt='logoencore' />
                    <h1 className='text-[24px] text-center text-white font-semibold leading-8'>
                        Earn rewards from your favorite brands.
                    </h1>
                </div>
            </div>
            <div className='flex px-7 py-9 flex-col grow gap-[25px] bg-white lg:rounded-b-[10px]'>
                <div className='flex items-center gap-5'>
                    <img src={getRewarded} className='w-[60px] h-[60px]' alt="getRewarded" />
                    <div>
                        <p className='text-[15px] uppercase font-semibold text-black leading-5'>GET REWARDED</p>
                        <p className='text-[16px] font-normal text-black leading-5'>Unlock exclusive access, discounts and promotions </p>
                    </div>
                </div>
                <div className='flex items-center gap-5'>
                    <img src={SafeSecure} className='w-[60px] h-[60px]' alt="getRewarded" />
                    <div>
                        <p className='text-[15px] uppercase font-semibold text-black leading-5'>SAFE & SECURE</p>
                        <p className='text-[16px] font-normal text-black leading-5'>Share feedback and other data directly with your favorite brands</p>
                    </div>
                </div>
                <div className='flex items-center gap-5'>
                    <img src={SpamFree} className='w-[60px] h-[60px]' alt="getRewarded" />
                    <div>
                        <p className='text-[15px] uppercase font-semibold text-black leading-5'>SPAM-FREE</p>
                        <p className='text-[16px] font-normal text-black leading-5'>Decide what info you share and who you share it with</p>
                    </div>
                </div>
                <div className='flex items-center gap-5'>
                    <img src={SaveTime} className='w-[60px] h-[60px]' alt="getRewarded" />
                    <div>
                        <p className='text-[15px] uppercase font-semibold text-black leading-5'>SAVE TIME</p>
                        <p className='text-[16px] font-normal text-black leading-5'>Connect accounts once, then log in across brands with a single tap</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoContent