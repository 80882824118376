import Lottie from 'react-lottie'
import successGif from "@assets/lotties/success.json";
import Icon from "@components/Icon";
import Pending from "@assets/Pending.png";
import timer from "@assets/pending-timer.png";
import TextCompleted from "@components/EnterToWin/TextCompleted";
import BadgeCompleted from "@components/EnterToWin/BadgeCompleted";

const Etw: React.FC<any> = (props) => {
  const { data } = props || {};
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successGif,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <></>;
  }
  return (
    <>
      {data?.[0]?.data?.action == "processing" ? (
        <div className="flex flex-col items-center justify-center text-center">
          <img
            src={Pending}
            alt="Pending"
            className="h-[101px] w-[90px] object-contain"
          />
          <h4 className="font-medium mt-10 text-white lg:text-[36px] md:text-[36px] text-[24px] text-center">
            Completion status processing
          </h4>
          <p className="text-[18px] mb-[40px] leading-[21.6px] text-[white]/70 font-normal tracking-[1.5px] mt-[10px]">
            Your score will be updated with 24 hours.
          </p>

          {data.map((item, index) => {
            const common = item?.data;
            let parts = (common?.type || "").split(":");
            return (
              <div
                key={index}
                className="relative lg:w-[420px] sm:w-[420px] md:w-[420px] w-[330px]"
              >
                <div className="bg-[#F8F4F3] w-full p-[20px] transition-all duration-700 ease-in-out  rounded-[10px] relative cursor-pointer overflow-hidden">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center justify-center">
                      {parts?.[1] && (
                        <div className="mr-2">
                          <Icon icon={parts?.[1]} />
                        </div>
                      )}
                      <h6 className="font-[550] text-left text-[16px] text-black">
                        {(common?.title || "").replace(
                          "{hashtag}",
                          common?.config?.hashtag || ""
                        )}
                      </h6>
                    </div>

                    <div className="rounded-full p-[4px] flex items-center justify-center z-10 overflow-hidden">
                      <img src={timer} alt="Check" className="h-5 w-5" />
                    </div>
                  </div>
                  {!!common?.description && (
                    <p className="font-normal text-left text-[15px] text-black opacity-80">
                      {common?.description}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center text-center">
          <Lottie options={defaultOptions} height={242} width={242} />
          <h4 className="font-medium text-white lg:text-[36px] md:text-[36px] text-[24px] text-center">
            Nice job!
          </h4>
          <p className="text-[18px] mb-[40px] leading-[21.6px] text-[white]/70 font-normal tracking-[1.5px] mt-[10px]">
            You completed a new action
          </p>
          {data.map((item, index) => {
            const common = item?.data;
            let parts = (common?.type || "").split(":");
            return (
              <div
                key={index}
                className="relative lg:w-[420px] sm:w-[420px] md:w-[420px] w-[330px]"
              >
                <div className="bg-[#F8F4F3] w-full p-[20px] transition-all duration-700 ease-in-out  rounded-[10px] relative cursor-pointer overflow-hidden">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center justify-center">
                      {parts?.[1] && (
                        <div className="mr-2">
                          <Icon icon={parts?.[1]} />
                        </div>
                      )}
                      <h6 className="font-[550] text-left text-[16px] text-black">
                        {common?.title}
                      </h6>
                    </div>

                    <BadgeCompleted item={common} />
                  </div>
                  {!!common?.description && (
                    <p className="font-normal text-left text-[15px] text-black opacity-80">
                      {common?.description}
                    </p>
                  )}
                  <TextCompleted item={common} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Etw;
