import { useState, Fragment, forwardRef, useImperativeHandle } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Survey from "@containers/fans/Survey";
import { useDispatch, useSelector } from "react-redux";
import Api from "@services/api";

const SurveyPopUp = forwardRef((props:any, ref) => {

  const { actions } = require("@redux/SurveyRedux");
  const { rewardActions } = require("@redux/EarnRewardRedux");
  const { actions: scoreAction } = require("@redux/ScoreRedux");

  const dispatch = useDispatch();
  const [data, setData] = useState<any>({})
  const [isOpen, setIsOpen] = useState(false);

  const survey = useSelector((state: any) =>
    Object.assign({}, (state.survey.survey || {}))
  );

  const creatorId = useSelector((state: any) => (state.creator.data?.id || ""));
  const fanId = useSelector((state: any) => (state.user?.user?.user?.data?.id));
    const user = useSelector((state: any) => (state.user));
    const programId = user.programData?.program?.id;

  useImperativeHandle(ref, () => ({
      open(data){
        actions.clearSelectedSearch(dispatch);
        setIsOpen(true)
        setData(data)
      },
      close(){
        setIsOpen(false)
      }

  }));

  const divideArray = (arr, searchString) => {
    const index = arr.indexOf(searchString);
  
    if (index === -1) {
      return [arr, []];
    }
  
    const leftArray = arr.slice(0, index);
    const rightArray = arr.slice(index + 1);
  
    return [{set:leftArray}, {unset:rightArray}];
  }

  const refreshData = () => {
    rewardActions.fetchEarnRewards(dispatch, creatorId);
    scoreAction.fetchScores(dispatch, programId);
  }

  const submit = async () => {
    let survayData = {};
    Object.keys(survey).map(item=>{
      if(item=="sport"){
        survayData[item] = survey[item].map(i=>i.strSport);
      } else if(item=="team") {
        survayData[item] = survey[item].map(i=>i.strTeam);
      } else if(item=="player") {
        survayData[item] = survey[item].map(i=>i.strPlayer);
      } else if(item=="social") {
        survayData[item] = divideArray(survey[item], "break");
        
      } else {
        survayData[item] = survey[item];
      }
    })
    
    const form = {
      surveyActionItemId:data?.id,
      answers:survayData
    }

    const json = await actions.submit(dispatch, creatorId, form);
    if(json?.status==200){
      setIsOpen(false);
      refreshData();
    }
  }

  const questionsData = (JSON.parse((data?.questions || "{}")) || {});
  let questions = [data?.action]; 
  Object.keys(questionsData).map((item)=>{
    if(questionsData[item]){
      //questions.push(item);
    }
  })
  
  return (
    <>
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-50" onClose={()=>setIsOpen(false)}>
        <Dialog.Panel className="w-full max-w-sm rounded bg-white">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            
            <div className="flex items-start mt-20 justify-center min-h-full p-4 text-center">
              
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-screen max-w-[700px] h-full transform overflow-hidden rounded-2xl lg:px-6 px-3 text-left align-middle shadow-xl transition-all`}>
                    <div className="flex items-center justify-end relative">
                        <button
                          onClick={()=>setIsOpen(false)}
                          className=""
                        >
                          <svg className="h-8 w-8 text-primary z-50 right-2 top-3 absolute"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg>
                        </button>
                    </div>
                  <Survey questions={questions} submit={submit} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
          </Dialog.Panel>
        </Dialog>
      </Transition>
    </>
  );
});
export default SurveyPopUp;
