import Loader from "@components/Loader";
import store from "@store/configureStore";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

let creatorType = false;
let currentUrl = window.location.href;
const params = new URL(currentUrl).searchParams;
const state = params.get("state");
if (state) {
  let token_creator_type = state.split("~");
  if (token_creator_type) {
    if (token_creator_type?.[2] && token_creator_type?.[2] == "creator") {
      creatorType = true;
    }
  }
}

interface CallBackProps { }

const CallBack: React.FC<CallBackProps | any> = (props) => {
  const type = store.getState().loader.appCreator;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();

  React.useEffect(()=>{

    if(query.get("returnTo")){
      navigate(query.get("returnTo"))
    }
    // Extract access_token from the URL fragment
   
  }, []);

  return (
    <>
      {creatorType || type ? (
        <div className="flex items-center justify-center bg-[#F6F2E9] h-screen overflow-auto">
          <Loader height={30} width={30} white={false} creator={true} />
        </div>
      ) : (
        <div className="flex items-center justify-center background h-screen overflow-auto">
          <Loader height={30} width={30} white={true} />
        </div>
      )}
    </>
  );
};

export default CallBack;
