import React from "react";
import logo from "@assets/logo.png";
import logo_letter from "@assets/logo_letter.png";
import creator_background from "@assets/creator_background.png";
import { capitalize, routerLink } from "@helpers/common";
import { Link } from "react-router-dom";
import left from "@assets/left.png";
import Loader from "@components/Loader";
import { connect } from "react-redux";

interface Props { }
interface State { }

const per_page = 12;

class SearchFans extends React.Component<Props | any, State> {
  state: State = {};

  componentDidMount() {
    this.props.fanSearch(0, per_page);
  }

  fanSearch = () => {
    const { nextIndex } = this.props;
    this.props.fanSearch(nextIndex, per_page);
  };

  render() {
    const { data, loading, nextIndex } = this.props;
    return (
      <div className="bg-[#000] h-full py-10 container mx-auto space-y-10">
        <div className="flex items-center justify-center">
          <Link
            to="/"
            className="w-8 h-8 p-2 text-xl font-semibold tracking-wider text-center text-white bg-gray-900 rounded-full cursor-pointer hover:bg-gray-800 gap-x-2">
            <img
              src={left}
              alt="left"
              className="object-contain w-full h-full"
            />
          </Link>
          <Link to="/" className="mx-auto">
            <img
              alt={"logo"}
              src={logo}
              className="xl:h-[65px] xl:w-[220px] w-[180px] object-contain mx-auto"
            />
          </Link>
          <div className="w-8 h-8" />
        </div>
        {(data || []).length === 0 && loading && (
          <Loader height={30} width={30} white={true} />
        )}
        <div className="grid grid-cols-1 pt-10 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-x-4 gap-y-10">
          {data.map((item, index) => (
            <div className="relative space-y-3" key={index}>
              <a
                href={routerLink(item.username)}
                className="relative rounded-[8px] overflow-hidden block">
                <div className="absolute bg-[#000]/40 h-full w-full hover:bg-[#000]/10" />
                <img
                  alt={"thumbnails"}
                  className="h-[250px] rounded-full w-[250px] object-contain"
                  src={item?.profileImage ? item.profileImage : logo_letter}
                />
              </a>
              <div className="flex items-center gap-x-3">
                <a
                  href={routerLink(item.username)}
                  className="relative rounded-[8px] overflow-hidden text-[#fff]/70 flex items-center gap-x-3 hover:primary">
                  <img
                    alt={"thumbnails"}
                    className="h-[42px] w-[42px] object-cover rounded-full"
                    src={item?.profileImage ? item.profileImage : logo_letter}
                  />
                  {capitalize(item.name)}
                </a>
              </div>
            </div>
          ))}
        </div>
        {nextIndex > 0 && (
          <div className="flex justify-content-center">
            <button
              disabled={loading}
              onClick={this.fanSearch}
              className={` bg-tile hover:opacity-80 rounded-full mx-auto h-[42px] w-[89px] font-medium text-[18px] text-black`}>
              {loading ? (
                <Loader height={30} width={30} white={true} />
              ) : (
                "more"
              )}
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    data: user.search.creators,
    nextIndex: user.search.nextIndex,
    loading: user.searchLoading,
  };
};

const mergeProps = (stateProps: any, dispatchProps: any) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("@redux/UserRedux");
  return {
    ...stateProps,
    fanSearch: (index, limit) => {
      return actions.fanSearch(dispatch, index, limit);
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(SearchFans);
