import React, { useState } from "react";
import Loader from "@components/Loader";
import { validateFullName } from "@helpers/common";
import Api from "@services/api";
import Management from "@services/management-service";

export const FanName = ({ user, item }) => {
	const [name, setName] = useState(null);
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState(null);
	const management = Management.get();

	const handleSubmit = async () => {
		if (!validateFullName(name)) {
			setError("Please enter your full name");
			return;
		}

		const data = {
			programId: management?.program?.id,
			fanId: user?.user?.id,
			actionId: item?.id || '',
			tpId: item?.touchpoint?.id,
			pointsEarned: item?.points || 0,
			details:{
			  fullName:name
			}
		};
		
		setLoader(true)
		await Api.authorizeActionItem(data);
		setLoader(false);
	};

	const handleNameInput = (e) => {
		setName(e.target.value);
		setError(false);
	};

	return (
		<>
			<div
				className={`mt-3 bg-tile-form px-3 flex items-center justify-between rounded-[10px]`}
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<input
					type={"text"}
					value={name || ""}
					placeholder={"Full Name"}
					onChange={handleNameInput}
					onKeyDown={(e) => {
						if (e.keyCode === 13) {
							handleSubmit();
						}
					}}
					className={`w-full h-[40px] bg-transparent rounded-[10px] tile-inlay-primary placeholder:tile-inlay-primary placeholder:text-[14px] shadow-none outline-none`}
				/>
				<button
					onClick={handleSubmit}
					disabled={!name}
					className={`bg-btn flex items-center justify-center w-[80px] h-[25px] rounded-full btn-inlay-primary py-[6px] px-[12px] disabled:opacity-30`}
				>
					{loader ? <Loader height={20} width={20} /> : "SUBMIT"}
				</button>
			</div>
			{error && (
				<p className="font-normal text-[10px] mt-1 text-center text-red-500 ">
					{error}
				</p>
			)}
		</>
	);
};
