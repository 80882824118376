import Api from "@services/api";
import NotificationService from "@services/notification-service";

const types = {
  SUBMIT_CREATOR_QRCODE_FORM_PENDING: "SUBMIT_CREATOR_QRCODE_FORM_PENDING",
  SUBMIT_CREATOR_QRCODE_FORM_SUCCESS: "SUBMIT_CREATOR_QRCODE_FORM_SUCCESS",
  SUBMIT_CREATOR_QRCODE_FORM_FAILURE: "SUBMIT_CREATOR_QRCODE_FORM_FAILURE",
  LOAD_CREATOR_QRCODE_TOUCHPOINTS: "LOAD_CREATOR_QRCODE_TOUCHPOINTS",
  DELETE_CREATOR_QRCODE_TOUCHPOINT:"DELETE_CREATOR_QRCODE_TOUCHPOINT",
  VIEW_CREATOR_QRCODE_TOUCHPOINT:"VIEW_CREATOR_QRCODE_TOUCHPOINT",
  UPDATE_CREATOR_QRCODE_TOUCHPOINT:"UPDATE_CREATOR_QRCODE_TOUCHPOINT",
  ADD_QRCODE_FORM: "ADD_QRCODE_FORM",
  CLEAR_QRCODE_FORM: "CLEAR_QRCODE_FORM",
};
export const actions = {
  submitQrcode: async (dispatch, data) => {
    dispatch({ type: types.SUBMIT_CREATOR_QRCODE_FORM_PENDING });
    const json = await Api.submitCreatorQrcodeEvent(data);
    if (json !== undefined) {
      if (200 === json.status) {
        NotificationService.success("Touchpoint created successfully.");
        dispatch({
          type: types.SUBMIT_CREATOR_QRCODE_FORM_SUCCESS,
          data: json.data,
        });
      } else {
        NotificationService.error(json.data?.error?.message);
        dispatch({ type: types.SUBMIT_CREATOR_QRCODE_FORM_FAILURE, data: "" });
      }
    } else {
      dispatch({ type: types.SUBMIT_CREATOR_QRCODE_FORM_FAILURE, data: "" });
    }
    return json;
  },
  deleteTouchPoint: async (dispatch, data) => {
    const json = await Api.patchCreators(`touchpoints/action-items/deactivate/${data.id}`);
    if (json !== undefined) {
      if (200 === json.status) {
        NotificationService.success("Touchpoint deleted successfully.");
        dispatch({ type: types.DELETE_CREATOR_QRCODE_TOUCHPOINT, data });
      } else {
        NotificationService.error(json.data?.error?.message);
      }
    } else {
      NotificationService.error("Something went wrong.");
    }
    return json;
  },
  addForm: data => {
    return { type: types.ADD_QRCODE_FORM, data };
  },

  clearForm: () => {
    return { type: types.CLEAR_QRCODE_FORM };
  },
  loadTouchPoints:(data)=>{
    return { type: types.LOAD_CREATOR_QRCODE_TOUCHPOINTS, data };
  },
  viewTouchpoint:(data)=>{
    return {type:types.VIEW_CREATOR_QRCODE_TOUCHPOINT, data}
  },
  updateTouchpointFrom:(data)=>{
    return {type:types.UPDATE_CREATOR_QRCODE_TOUCHPOINT, data}
  }
};
const initialState = {
  data: {},
  form: {},
  error: "",
  isSubmitting: false,
  touchpoints:[],
  touchpoint:{},
  touchpointFrom:{},
  typeForm: {
    data: [],
    isFetching: false,
    isDeleting: false,
    isSubmitting: {},
    total: 0,
  },
};
export const reducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.SUBMIT_CREATOR_QRCODE_FORM_PENDING: {
      return {
        ...state,
        error: "",
        isSubmitting: true,
      };
    }

    case types.SUBMIT_CREATOR_QRCODE_FORM_SUCCESS: {
      const typeForm = data.typeformActionItem;

      return {
        ...state,
        error: "",
        isSubmitting: false,
        form: {},
        typeForm: {
          ...state.typeForm,

          data: [...(state.typeForm?.data || []), typeForm],
          total: state.typeForm.total + 1,
        },
      };
    }

    case types.SUBMIT_CREATOR_QRCODE_FORM_FAILURE: {
      return {
        ...state,
        isSubmitting: false,
        error: data,
      };
    }

    case types.LOAD_CREATOR_QRCODE_TOUCHPOINTS:{
      return {
        ...state,
        touchpoints: data,
      };
    }

    case types.VIEW_CREATOR_QRCODE_TOUCHPOINT:{
      return {
        ...state,
        touchpoint: data,
      };
    }

    case types.DELETE_CREATOR_QRCODE_TOUCHPOINT:{
      return {
        ...state,
        touchpoints:state.touchpoints.filter(i => i.id != data.id),
      };
    }

    case types.UPDATE_CREATOR_QRCODE_TOUCHPOINT:{
      return { ...state, touchpointFrom: data };
    }

    case types.ADD_QRCODE_FORM: {
      return { ...state, form: data };
    }

    case types.CLEAR_QRCODE_FORM: {
      return {
        ...state,
        isSubmitting: false,
        error: "",
        form: {},
      };
    }
    default:
      return state;
  }
};
