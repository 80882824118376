import { Routes, Route, useLocation } from "react-router-dom";
import SignupSteps from "@components/SignupSteps";
import { useSelector } from "react-redux";
import StepFirstScreen from "@pages/fans/StepFirstScreen";
import IndexPage from "@pages/fans/Index";
import RedeemTabPage from "@pages/fans/RedeemTab";
import RewardsPage from "@pages/fans/Rewards";
import AllFansPage from "@pages/fans/AllFans";
import FansSettingsPage from "@pages/fans/Settings";
import EarnTabPage from "@pages/fans/EarnTab";
import EditExtraInfoPage from "@pages/fans/EditExtraInfoPage";
import LeaderboardPage from "@pages/fans/Leaderboard";
import EditDisplayNamePage from "@pages/fans/EditDisplayNamePage";
import PrivacyPolicyPage from "@pages/PrivacyPolicy";
import TermsConditionsPage from "@pages/TermsConditions";
import ScannerEventPage from "@pages/ScannerEventPage";
import Callback from "@pages/CallBack";
import SocialCallback from "@pages/SocialCallback";
import LogOut from "@pages/LogOut";
import { useEffect } from "react";
import { getAppName } from "@helpers/common";
import Middleware from "@components/Middleware";
import FanLogin from "@pages/fans/Login";
import Auth from "@pages/Auth";
import Redirect from "@pages/Redirect";
import InfoContent from "@components/E-commerce/InfoContent";
import CorsVerification from "@pages/CorsVerification";

const SignupRoute = () => {
    const location = useLocation();
    const creator = useSelector((state: any) => state.creator);
    const loader = useSelector((state: any) => state.loader?.loader);
    const dynamicForm = (creator?.program?.extraInfo || {});
    const routes = Object.keys(dynamicForm).reverse();
    const program = location.pathname.split("/");

    const cname = program?.[1];
    const pname = program?.[2];

    const ContextPage = ({ children }) => {
      return loader ? <Callback /> : children;
    };


    return (
      <>
        <Routes>
        <Route path="/callback/auth0" element={<CorsVerification />} />
        <Route path="/callback/:type" element={<SocialCallback />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/redirect" element={<Redirect />} />
        <Route path="/:type/callback" element={<Callback />} />
        <Route path="/p/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/p/terms-conditions" element={<TermsConditionsPage />} />
        <Route path="/reward-info" element={<InfoContent />} />
        
        <Route
					path="/logout"
					element={
							<LogOut />
					}
				/>
        {getAppName()!=="creator" &&
        <>
        <Route path=":cname">
          <Route index={true} element={<IndexPage />}></Route>
        </Route>
        <Route path=":cname/:pname">
        <Route index={true} element={<ContextPage><IndexPage /></ContextPage>}></Route>
        <Route path="earn" element={<RewardsPage />} />
        <Route path="logout" element={<LogOut />} />
				<Route path="redeem" element={<RedeemTabPage />} />
        {/* <Route path="earn" element={<EarnTabPage />} /> */}
        <Route path="leaderboard" element={<LeaderboardPage name="title" setPageYOffset={() => {}} />} />
        <Route path="scanner/event" element={<ScannerEventPage />} />
        <Route
					path="leaderboard/all-fans"
					element={
							<AllFansPage />
					}
				/>
        <Route
					path="settings"
					element={
							<FansSettingsPage />
					}
				/>
        <Route
					path="settings/edit/fan-info"
					element={
							<EditExtraInfoPage />
					}
				/>
				<Route
					path="settings/edit/display-name"
					element={
							<EditDisplayNamePage />
					}
				/>
        <Route
					path="login"
					element={
							<FanLogin />
					}
				/>

      <Route
          path="auth/last"
          element={
            <StepFirstScreen next={routes?.[0] ? `/${cname}/${pname}/auth/${routes[0]}` : ''} />
          }
        />

        {routes.map((key, index) => {
        const currentLink = routes?.[index] ? `/${cname}/${pname}/auth/${routes[index]}` : '';
        const lastStep = index === routes.length - 1; // Check if this is the last step

        return (
            <Route
              key={key}
              path={`auth/${key}`}
              element={
                <SignupSteps
                  index={key}
                  current={currentLink}
                  form={dynamicForm[key]}
                  lastStep={lastStep}
                />
              }
            />
          );
        })}
        </Route>
        </>}
        <Route path="*" element={<></>} />
       
      </Routes>
      <Middleware />
      </>
    );
};

export default SignupRoute;
