import { connect, useSelector } from "react-redux";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Success from "@pages/Success";
import Error from "@pages/Error";
import Header from "@components/Header";
import CreatorHeader from "@components/CreatorHeader";
import Callback from "@pages/CallBack";
import { useEffect, useRef, useState } from "react";
import ProfileEarnPage from "@pages/ProfileEarn";
import ProfileRedeemPage from "@pages/ProfileRedeem";
import Api from "@services/api";
import CallbackService from "@services/callback-service";
import { ToastContainer } from "react-toastify";
import store from "@store/configureStore";
import { fetchIP, getApp, getAppName, setCookie } from "@helpers/common";
import SearchFansPage from "@pages/fans/Search";
import FaqsPage from "@pages/fans/Faqs";
import InviteFriendPage from "@pages/fans/InviteFriend";
import SignupRoute from "@components/SignupRoute";
import CreatorAssetsScreen from "@pages/CreatorAssetsScreen";
import WaitList from "@pages/Waitlist";
import SigninPage from "@pages/fans/Signin";
import RewardsPage from "@pages/fans/Rewards";
import { useDispatch } from "react-redux";
import socketService from "@services/socket-service";
import emitter from "@services/emitter-service";
import Notification from "@components/Notification";
import { useAuth0 } from "@auth0/auth0-react"
import ActionComplete from "@components/EnterToWin/ActionComplete";
import StatusProcessing from "@components/EnterToWin/StatusProcessing";
import ActionPending from "@components/EnterToWin/ActionPending";
import SocialResponse from "@components/Notification/SocialResponse";
import TypeFormPopup from "@components/EnterToWin/TypeFormPopup";
import Management from "@services/management-service";
import Mixpanel from "@services/mixpanel-events";

const publicScreen = [
  "/signin",
  "/signup",
  "/auth",
  "/sign-in/step-1",
  "/profile",
  "/profile/redeem",
  "/twitter/callback",
  "/twitch/callback",
  "/instagram/callback",
  "/shopify/callback",
  "/privacy-policy",
  "/terms-conditions",
  "/landing",
  "/waitlist",
  "/callback",
  "/auth",
  "/redirect"
];

const privateScreen = ["/signin", "/signup", "/callback", "/auth", "/redirect"];

Api.init({ url: process.env.REACT_APP_API_BASE_URL });

const Router = (props) => {
  const {
    jwt_token,
    loader,
    setloader,
    fetchCreatorData,
    fanId,
    creatorId,
    scannerEventPayload,
    username,
    appLoader,
    user    
  } = props;

  const { actions } = require("@redux/ScoreRedux");
  const dispatch = useDispatch();
  const popupRef = useRef(null);
  let location = useLocation();
  const winPopupRef = useRef(null);
  const typeFormRef = useRef(null);
  const pendingPopupRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();
  const session = useSelector((state) => state.user.session);
  const  management = Management.get();
  const programId = management?.program?.id;
  const themeType = management?.program?.config?.type || "loyalty";
  const cname = management?.creator?.username;
  const pname = management?.program?.name;
  const isLogin = session?.[cname]?.[pname];
  const token = user?.token;
  const pubKey = management?.creator?.integrations?.klaviyo?.apiPublicKey;

  const [ip, setIp] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(()=>{
    Management.fetch();
    if (cname && pname && !isLogin && themeType === "etw") {
      const timer = setTimeout(() => {
        Mixpanel.send('Landing Page Viewed');
        }, 2000);
      return () => clearTimeout(timer);
    }
  },[])

  useEffect(()=>{
    if(token && isLogin){
      Management.earn();
    }
    
  },[token, isLogin]);

  useEffect(() => {
    if (pubKey) {
      const script = document.createElement('script');
      script.async = true;
      script.type = 'text/javascript';
      script.src = `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${pubKey}`;
      document.body.appendChild(script);
    }
  }, [pubKey])

  useEffect(() => {
    const anonId = searchParams.get('anonId');
    if (anonId) {
      setCookie("encore_uid", anonId, 365);
    }
  }, [searchParams])

  useEffect(() => {
    if (cname && pname) {
      if (!user?.token) {
        dispatch({type: "LOGOUT"})
        navigate(`/${cname}/${pname}`)
      }
    }
  }, [user?.token])  
  
  const navigate = useNavigate();
  if (token) {
    Api.setClientToken(token);
    //if(themeType!="ecommerce"){
      Api.VerifyAuthUserToken(getAccessTokenSilently);
    //}
  }

  useEffect(() => {
    if (token) {
      Api.setClientToken(token);
      if(getAppName() !== "creator" && getAppName() !== "marketplace"){
        emitter.on("NotificationPopup", onNotificationPopup);
        emitter.on("WinNotificationPopup", onWinNotificationPopup);
        emitter.on("pendingNotificationPopup", onPendingNotificationPopup);
        emitter.on("typeFormPopup", onTypeFormPopup);
        emitter.on("fetchProgramFan", fetchProgramFan);
        socketService.init(token);
      }
    } else {
      if(getAppName() !== "creator"){
        socketService.disconnect();
      }
    }
  }, [token, themeType]);

  useEffect(() => {
    if(!token){
      CallbackService.setInviteToken(searchParams);
    }
  }, []);

  useEffect(() => {
    if (isLogin && token && getAppName() !== "creator") {
      //CallbackService.fetchUser(fanId);
      if(programId){
        fetchScores(programId)
      }
      
    }
  }, [isLogin,token]);

  const fetchScores = async (programId) =>{
    const json = await actions.fetchScores(dispatch, programId);
    if(json.status!==200){
      dispatch({type: "SLOGOUT", session: {cname: cname, pname: pname}});
      navigate(`/${cname}/${pname}`)
    }
  }

  const fetchProgramFan = () => {
    actions.fetchScores(dispatch, programId);
  }

  useEffect(() => {
    if (token && scannerEventPayload?.id) {
      const data = {
        query: 'scanner-event',
						programId: programId,
						creatorId: creatorId,
						fanId:fanId,
						actionId: scannerEventPayload.id,
						eventId: scannerEventPayload.eventId,
						seed: scannerEventPayload.seed
      }
      const timer = setTimeout(() => {
        CallbackService.scannerEventWebhook(data);
        }, 1500);
        return () => clearTimeout(timer);
     
    }
  }, [token]);

  useEffect(() => {
    if (
      window.location.hostname !== process.env.REACT_APP_MAIN_DOMAIN &&
      getAppName() !== "www"
    ) {
      if (getAppName() == "creator") {
        if (jwt_token) {
          const fetchCreator = async () => {
            const filter = {
              filter: { where: { username: username }, limit: 1 },
            };
            await fetchCreatorData(filter);
          };
          //fetchCreator();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (
      window.location.hostname !== process.env.REACT_APP_MAIN_DOMAIN &&
      getAppName() !== "www"
    ) {
      if (getAppName() == "creator") {
        if(["/settings", "/touchpoints", "/accounts", "/home/touchpoints", '/home'].includes(location.pathname))
        if (!jwt_token) {
          navigate("/");
        }
      }
    }
  }, [jwt_token]);

  

  useEffect(() => {
    if (jwt_token) {
      if (getAppName() == "creator") {
        store.dispatch({
          type: "SET_APP_CREATOR",
          data: true,
        });
        CallbackService.fetchCreator();
        if (location.pathname == "/touchpoints") {
          const creator = store.getState().user.user;

          const totalTypeForm = store.getState().creator.typeForm.total;
          const totalQr = (store.getState().QrScan.touchpoints || []).length;
          if (creator?.user?.username) {
            CallbackService.fetchCreatorAssets()
              .then((res) => {
                if (res !== undefined) {
                  if (
                    !(
                      creator?.user.twitterId ||
                      creator?.user.instagramId ||
                      creator?.user.tiktokId ||
                      creator?.user.twitchId ||
                      creator?.user.shopifyId
                    ) &&
                    totalTypeForm == 0 &&
                    totalQr == 0
                  ) {
                    navigate("/settings");
                  } else {
                    if (res.status == 200) {
                      if (res.data) {
                        if (!res.data.profileImage) {
                          navigate("/settings");
                        }
                      }
                    }
                  }
                  const timer = setTimeout(() => {
                    store.dispatch({ type: "SET_GLOBAL_LOADER", data: false });
                  }, 4000);
                  return () => clearTimeout(timer);
                }
              })
              .catch((error) => {
                store.dispatch({ type: "SET_GLOBAL_LOADER", data: false });
              });
          }
        }
      } else {
        store.dispatch({
          type: "SET_APP_CREATOR",
          data: false,
        });
      }
    }
  }, [jwt_token]);

  useEffect(() => {
    if (token) {
      if (privateScreen.includes(location.pathname)) {
        setloader(true);
        // navigate("/");
        const timer = setTimeout(() => {
          setloader(false);
        }, 1000);
        return () => clearTimeout(timer);
      }
    } else {
      
      if (
        ![...publicScreen, "/", "/success", "/error", "/auth/last"].includes(
          location.pathname
        )
      ) {
        setloader(true);
        //navigate("/");
        const timer = setTimeout(() => {
          setloader(false);
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
  }, [token]);

  const ContextPage = ({ children }) => {
    return appLoader || loader ? <Callback /> : children;
  };

  const onNotificationPopup = (data) => {
    popupRef?.current?.open(data)
  }

  const onWinNotificationPopup = () => {
    winPopupRef?.current?.open([{data:"onWinNotificationPopup"}]);
  }

  const onTypeFormPopup = (data)=>{
    if(data?.isOpen){
      typeFormRef.current.open(data)
    }else{
      if(typeFormRef.current){
        typeFormRef.current.close({})
      }
     
    }
  }

  const onPendingNotificationPopup = (data) => {
    if(data?.isOpen){
      pendingPopupRef?.current?.open(data);
    } else {
      pendingPopupRef?.current?.close();
    }
    
  }

  useEffect(() => {
    Mixpanel.init(cname, pname, themeType, token ? fanId: null, ip?.ip);
    Api.setClientIP(ip?.ip);
    if (!ip) {
      fetchIP(setIp);
    }
  }, [cname, pname, fanId, token, ip?.ip])

  useEffect(() => {
    if (token) {
      if((props.user.socketNotification || []).length>0){
        emitter.emit("NotificationPopup",[props.user.socketNotification?.[0]])
      }
    }
  }, [(props.user.socketNotification || []).length]);

  return (
    <>
      {session?.[cname]?.[pname] &&
      <>{
        location.pathname !== "/success" &&
        !location.pathname.includes("/callback/") &&
        location.pathname !== "/error" &&
        location.pathname !== "/logout" &&
        location.pathname !== "/" &&
        location.pathname !== `/${cname}/${pname}/auth/step-1` &&
        (getAppName() !== "creator" && themeType!=="ecommerce"?<Header />:<></>
        
        )}
        </>}
        {
        location.pathname !== "/success" &&
        !location.pathname.includes("/callback/") &&
        location.pathname !== "/error" &&
        location.pathname !== "/logout" &&
        location.pathname !== "/" &&
        location.pathname !== `/${cname}/${pname}/auth/step-1` &&
        (getAppName() == "creator" && <CreatorHeader />)}
      <ToastContainer
        position="top-right"
        theme="dark"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
      {getApp(ContextPage)}

      <Routes>
        <Route
          path="sign-in/step-1"
          element={
            <ContextPage>
              <SigninPage />
            </ContextPage>
          }
        />

        <Route
          path="signin"
          element={
            <ContextPage>
              <SigninPage />
            </ContextPage>
          }
        />
        <Route
          path="dashboard"
          element={
            <ContextPage>
              <RewardsPage />
            </ContextPage>
          }
        />

        {/* signedout view routes  */}
        <Route path="/profile" element={<ProfileEarnPage />} />
        <Route path="/profile/redeem" element={<ProfileRedeemPage />} />
        <Route
          path="/success"
          element={
            <ContextPage>
              <Success />
            </ContextPage>
          }
        />
        <Route
          path="/error"
          element={
            <ContextPage>
              <Error />
            </ContextPage>
          }
        />

        <Route path="/programs" element={<SearchFansPage />} />
        <Route path="/faqs" element={<FaqsPage />} />
        <Route path="/invite-friend" element={<InviteFriendPage />} />

        {/* <Route path="/auth" element={<AuthPage />} /> */}
        <Route path="/waitlist" element={<WaitList />} />

        <Route path="*" element={<></>} />
      </Routes>

      <SignupRoute />

      <Notification ref={popupRef} />

      <ActionComplete ref={winPopupRef} />
      <ActionPending ref={pendingPopupRef} />

      <TypeFormPopup ref={typeFormRef} />

      <StatusProcessing />

      <CreatorAssetsScreen />
      {/* <SocialResponse/> */}
    </>
  );
};

const mapStateToProps = ({ user, loader, creator, score }) => {
  return {
    login_timestamp: user.timestamp,
    jwt_token: user.token,
    loader: loader.loader,
    appLoader: loader.appLoader,
    scannerEventPayload: creator.scannerEventPayload,
    fanId: score?.data?.fanId,
    programId: creator?.program?.id,
		creatorId: creator?.creator?.id,
		creator: creator,
    username: user?.user?.user?.username,
    user: user,
    themeType: creator?.program?.config?.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { actions } = require("@redux/UserRedux");
  const { actions: loaderAction } = require("@redux/LoaderRedux");
  const { actions: creatorAction } = require("@redux/CreatorRedux");
  return {
    logout: () => dispatch(actions.logout()),

    setloader: (data) => dispatch(loaderAction.setLoader(data)),
    fetchCreator: (creator_id) =>
      loaderAction.fetchCreator(dispatch, creator_id),
    fetchCreatorAssets: (creatorId) => {
      return creatorAction.fetchCreatorAssets(dispatch, creatorId);
    },
    fetchCreatorData: (creatorId) => {
      return creatorAction.fetchCreatorData(dispatch, creatorId);
    },
    fetchActionItemDetail: (id) => {
      return creatorAction.fetchActionItemDetail(dispatch, id);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
