import Loader from "@components/Loader";

interface ResendBannerProps {
  isLoading?: boolean;
  onResendCode?: () => void;
}

export const ResendBanner = ({
  isLoading,
  onResendCode,
}: ResendBannerProps) => {
  return (
    <div>
      <p
        className={`font-normal text-[14px] lg:text-[16px] leading-5 text-primary text-center mt-2 tile-inlay-primary`}
      >
        Didn't receive a code?
      </p>
      {isLoading ? (
        <div className="mt-2">
          <Loader height={20} width={20} color={"#000"} />
        </div>
      ) : (
        <p
          onClick={onResendCode}
          className={`font-[700] text-[14px] lg:text-[16px] leading-8 text-primary text-center cursor-pointer tile-inlay-primary`}
        >
          Resend
        </p>
      )}
    </div>
  );
};
