import socketService from "./socket-service";
import { getAnonymousId } from "@helpers/common";
import store from "@store/configureStore";
import Api from "./api";

class MixpanelServices {
    cname: string | undefined;
    pname: string | undefined;
    type: string | undefined;
    userId: string | undefined;
    new_user: boolean | undefined;
    ip: string | boolean;

    async init(cname, pname, type, userId, ip) {
        this.cname = cname;
        this.pname = pname;
        this.type = type;
        this.userId = userId;
        this.ip = ip
    }

    async send(eventName: String, data?: {}, user_data?:{}): Promise<void> {
        const user = store.getState()?.user.user.user ? store.getState()?.user.user.user : '';
        const isLogin = !!store.getState()?.user?.token;

        let ev = {
            "events": [
                {
                    "type": "identity",
                    "event": "identity",
                    "properties": {
                        "ip": this.ip,
                        "$device_id": getAnonymousId() || '',
                        ...(this.userId && { "$user_id": this.userId }),
                        ...(user_data && { ...user_data }),
                        "phoneNumber": user.phoneNumber ? user.phoneNumber : user.extraInfo?.phoneNumber ? user.extraInfo?.phoneNumber : '',
                        "email": user.email ? user.email : user.extraInfo?.email ? user.extraInfo?.email : '',
                    }
                },
                {
                    "type": "event",
                    "event": eventName,
                    "properties": {
                        "$device_id": getAnonymousId() || '',
                        ...(this.userId && { "$user_id": this.userId }),
                        "Program Name": this.pname,
                        "Creator Name": this.cname,
                        "Program Type": this.type,
                        "screen_height": window.screen.height,
                        "screen_width": window.screen.width,
                        "userAgent": navigator.userAgent,
                        "ip": this.ip,
                        ...(isLogin && 
                        { "new_user": store.getState()?.user?.new_user_flag }),
                        ...(data && { ...data }),
                    }
                }
            ]
        };
        if (isLogin) {
            socketService.sendMessage(ev)
        } else {
            Api.mixpanelEventsSend(ev);
        }
    }
    
    async complete() {
        let ev = {
            "events": [
                {
                    "type": "increment",
                    "event": "increment",
                    "properties": {
                        "programs_completed": 1,
                        "$user_id": this.userId,
                    }
                },
            ]
        };
        socketService.sendMessage(ev)
        this.send("Threshold Hit");
    }
}


const Mixpanel = new MixpanelServices();

export default Mixpanel;
