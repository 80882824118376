import { SubmitButton } from "@components/SubmitButton";
import { FormEvent, useState } from "react";

interface VerificationCodeProps {
  isLoading?: boolean;
  onSubmit?: (code: string) => void;
  disabled?: boolean;
  codeError?: string;
  backgroundColor?: string;
}

export const VerificationCode = ({
  isLoading = false,
  disabled = false,
  codeError,
  onSubmit,
  backgroundColor = "bg-tile-form",
}: VerificationCodeProps) => {
  const [code, setCode] = useState("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(code);
    }
  };
  return (
    <>
      <form className="py-1" onSubmit={handleSubmit}>
        <div
          // ${ template == "ecommerce" ? "bg-primary" : "bg-body"}
          // ${template == "loyalty" ? "opacity-50" : ""}
          className={`${backgroundColor} pl-3 pr-[16px] flex items-center justify-between rounded-[10px]`}
        >
          <input
            onChange={(e) => setCode(e.target.value)}
            type="text"
            value={code || ""}
            className={`w-full h-[40px] rounded-[10px] disabled:opacity-100 bg-transparent text-primary placeholder:text-[black]/40 placeholder:text-[14px] shadow-none outline-none`}
            placeholder="Verification Code"
          />
          <SubmitButton
            isLoading={isLoading}
            disabled={disabled || code.length < 6}
          />
        </div>
      </form>
      {codeError && (
        <p className="font-normal text-[10px] mt-1 text-center text-red-500 ">
          {codeError}
        </p>
      )}
    </>
  );
};
