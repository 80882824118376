import { useEffect, useState } from "react";
import MobileHeadEcommerce from "@components/E-commerce/MobileHead";
import ActivateEncore from "@components/E-commerce/ActivateEncore";
import ProgramBox from "@components/ProgramBox";
import { useSelector, useDispatch } from "react-redux";
import Unlocked from "@components/E-commerce/Unlocked";
import mTouchpoints from '@model/touchpoints-model';
import EarnContent from "@components/E-commerce/EarnContent";
import { useParams } from "react-router-dom";
import Management from "@services/management-service";
import { screenCheck, setLoginTheme, setThemeColor } from "@helpers/common";
import { Progress } from "@components/ProgressBar";
import Mixpanel from "@services/mixpanel-events";
import Background from "@components/Backgrounnd";

const EcommerceToWin = () => {
	const { rewardActions } = require("@redux/EarnRewardRedux");
	const dispatch = useDispatch();
	const {cname, pname} = useParams();
	const [showMenu, setShowMenu] = useState(false);
	const [show, setShow] = useState(false);
	const touchpoints = useSelector((state: any) => state.EarnReward?.data);
	const session = useSelector((state: any) => state.user.session);
	const gToken = useSelector((state: any) => state.user.token);
	const [filter, setFilter]:any = useState({});
	const token = session?.[cname]?.[pname]??false;
	const management = Management.get();
	const theme = management?.program?.config?.theme;
	let currentScreen = screenCheck(filter, management?.program?.config?.data);

	useEffect(()=>{
        setFilter(mTouchpoints.getFilterData())
    },[touchpoints])

	useEffect(() => {
		if (!token) {
			setLoginTheme(management?.program?.config?.theme)
		} else {
			setThemeColor(theme)
		}
	}, [token])

	const openMenu = () => {
		Mixpanel.send("Menu Opened")
		setShowMenu(true)
	}

	useEffect(() => {
		if(!token){
			let data = (management?.touchpoints || []);
			dispatch(rewardActions.setTouchpoints(data));
		}
		
	}, [token]);

	
	{show && token &&
		document.querySelector('#hideScroll')?.scrollTo(0, 0);
	}

	useEffect(() => {
		setFilter(mTouchpoints.getFilterData())
	  }, [touchpoints]);

	return (
		<Background>
		<div className="relative">
			{!show && token &&
				<div className="absolute bottom-[12px] right-[11px] z-50">
					<Progress filter={filter} />
				</div>
			}
			<div className={`flex flex-col pt-6 px-5 ${token ? 'pb-6': ''} no-scrollbar h-screen overflow-x-hidden font-halyard`} id="hideScroll">
				{showMenu || show ? (
					<>
						<div className="flex justify-between">
							<button
								type="button"
								onClick={() => {
									setShowMenu(false);
									setShow(false)
								}}
							>
								<span className="sr-only">Close panel</span>
								<svg width="8" height="15" className='opacity-80 body-inlay-primary' viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M8.75 1.5L1.25 9L8.75 16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</button>
							{show && 
							<div className="cursor-pointer" onClick={openMenu}>
								<svg className="h-6 w-6 opacity-80 body-inlay-primary" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1="4" y1="6" x2="20" y2="6" />  <line x1="4" y1="12" x2="20" y2="12" />  <line x1="4" y1="18" x2="20" y2="18" /></svg>
							</div>}
						</div>
						{showMenu ? <MobileHeadEcommerce />: <Unlocked current={currentScreen} />}
					</>
				) : (
					<>
						{
							token && 
							<div className="flex justify-end">
								<div className="cursor-pointer" onClick={openMenu}>
									<svg className="h-6 w-6 opacity-80 body-inlay-primary" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <line x1="4" y1="6" x2="20" y2="6" />  <line x1="4" y1="12" x2="20" y2="12" />  <line x1="4" y1="18" x2="20" y2="18" /></svg>
								</div>
							</div>
						}

						{!show &&
							<div>
								{
									!token ? <ProgramBox box="BEFORE_LOGIN" /> : 
									<div className="text-center mb-3">
										<h1 className="body-inlay-primary font-[500] text-[24px] leading-[33px] tracking-[0.02em]">
											<ProgramBox box="DASHBOARD_HEADING" winnerList={true} currentScreen={currentScreen} />
										</h1>
										<p className="body-inlay-primary pt-[10px] font-light leading-5 tracking-[0.02em] text-[16px] opacity-80">
											<ProgramBox box="DASHBOARD_DESCRIPTION" winnerList={true} currentScreen={currentScreen}  />
										</p>
										{currentScreen > 0 &&
											<button 
											onClick={() => setShow(true)}
											className="bg-btn btn-inlay-primary p-[5px_12px_7px_12px] w-[104px] h-[25px] text-center mt-[15px] rounded-[50px] uppercase font-[400] text-[13px] tracking-[0.04em] leading-[13px]">
												view code
											</button>
										}
									</div>
								}
							</div>}
						
						{!token && <ActivateEncore gToken={gToken} />}
							
						{token && <div style={!token ? { pointerEvents: "none" } : {}}>
							{(!show) && <EarnContent />}
						</div>}

						{!show && <div className='grow my-5 items-end justify-center flex'>
							{token ? <ProgramBox isLink={true} box="POWERED_BY" />:
							<ProgramBox box="LOGIN_FOOTER" opacity="none" />}
						</div>}
					</>
				)}
			</div>
		</div>
		</Background>
	);
};

export default EcommerceToWin;
