import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setThemeColor, setDefaultFavicon } from "@helpers/common";
import { getAppName, getHostName } from "@helpers/common";
import CallbackService from "@services/callback-service";
import { useNavigate, useLocation } from "react-router-dom";
import Management from "@services/management-service";
import meta from "@common/meta.json";

const CreatorAssetsScreen = () => {
    const { actions: scoreAction } = require("@redux/ScoreRedux");
    const management = Management.get();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const creator = useSelector((state: any) => state?.creator);
    const inviteToken = useSelector((state: any) => (state.creator.inviteToken));
    const scannerEventPayload = useSelector((state: any) => (state.creator.scannerEventPayload));
    const jwt_token = useSelector((state: any) => (state.user.user.token));
    const assets = useSelector((state: any) => (state.user.assets));
    const fanId = useSelector((state: any) => (state.user?.user?.user?.data?.id));
    const user = useSelector((state: any) => (state.user));
    const programId = management?.program?.id;
    const program = location.pathname.split("/");
    const cname = program?.[1];
    const pname = program?.[2];
    const theme = cname && pname ? management?.program?.config?.theme : creator.program?.config?.theme;
    const type = management?.program?.config?.type

    useEffect(() => {
        if (type !== "ecommerce" && getAppName() !== "marketplace") {
            setThemeColor(theme || "loyalty");
        }
    },[management])


    useEffect(() => {
        const title = document.getElementById("title") as HTMLLinkElement;
        title.innerHTML=cname || "Encore Fans";
        if (getAppName() !== "creator") {
            const favicon = document.getElementById("favicon") as HTMLLinkElement;
            if (favicon) {
                favicon.href = setDefaultFavicon(cname, assets)
            }
        }
        if (jwt_token) {
            if (inviteToken) {
                const data = {
                    query: 'refer-friend',
                    creatorId: creator?.creator?.id,
                    referrerId: inviteToken?.invite,
                    refereeId: user?.user?.user?.id,
                    programId: creator?.program?.id,
                    actionId: inviteToken?.actionId,
                };
                if(inviteToken?.actionId && inviteToken?.invite){
                    const timer = setTimeout(() => {
                        CallbackService.inviteWebhook(data);
                      }, 3000);
                      return () => clearTimeout(timer);
                }
                
            }
        }

        if (creator?.id && jwt_token) {
            if (scannerEventPayload?.id) {
                let url = `/scanner/event?id=${scannerEventPayload.id}&seed=${scannerEventPayload.seed}`;
                if (scannerEventPayload.redirectUrl) {
                    url += `&redirectUrl=${scannerEventPayload.redirectUrl}`;
                }
                navigate(url);
            }
        }
        const currentDate = new Date().getDate();
        const isAdditionalStep = meta.includes(getHostName());
        
        if(isAdditionalStep && process.env.REACT_APP_MONTHLY_EARN_PROGRAM_END_DAY_LIMIT && currentDate<=parseInt(process.env.REACT_APP_MONTHLY_EARN_PROGRAM_END_DAY_LIMIT)){
            dispatch({type:"CREATOR_SET_IS_PROGRAM_REGISTRATION_END", data:true})
        } else {
            dispatch({type:"CREATOR_SET_IS_PROGRAM_REGISTRATION_END", data:false})
        }

    }, [creator?.id, jwt_token]);

    useEffect(() => {
        if (getAppName() !== "creator") {
            const fetchScoredData = async () => {
                await scoreAction.fetchScores(dispatch, programId);
            }
            if (programId && fanId) {
                fetchScoredData();
            }
        }
    }, [programId, fanId]);

    return;
};

export default CreatorAssetsScreen;
