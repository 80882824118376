import React, { Fragment, useState, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import defaultIcon from "@assets/default-man.jpg";

let timer;

const Team = ({ question }) => {
  const { actions } = require("@redux/SurveyRedux");
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState([0]);

  const data = useSelector((state: any) =>
    Object.assign([], (state.survey.data?.[question] || []))
  );
  const survey = useSelector((state: any) =>
    Object.assign([], (state.survey.survey?.[question] || []))
  );

  const search = (type: string, term: any) => {
        clearTimeout(timer);
          timer = setTimeout(() => {
            if(term){
              actions.search(dispatch, type, term);
            }
            
          }, 600);
  };

  const selectedSearch = (type: string, value: any) => {
    actions.addSelectedSearch(dispatch, type, value);
  };

  const removeSearch = (type: string, value: any) => {
    actions.removeSelectedSearch(dispatch, type, value);
  };


  return (
    <div data-testid="team-component" className={` ${survey.length === 2 ? 'h-96' : survey.length > 2 ? 'h-[500px] overflow-y-auto' : 'h-72' }`}>
      <div className="transition-all h-full duration-1000 ease-in-out animate-fade-in-up">
        <div className="text-center pb-6">
          <p className="text-primary font-normal text-lg">What are your favorite professional teams?</p>
        </div>
        <div>
          <Combobox
            value={selected}
            onChange={(e) => selectedSearch(question, e)}
          >
            <div className="relative mt-1 rounded-[10px]">
              <div className="relative cursor-default overflow-hidden rounded-[10px] text-left">
                <Combobox.Input
                  className="w-full h-12 border-none pl-3 pr-10 text-lg font-normal background focus-visible:bg-white text-primary focus-visible:ring-0 focus-visible:rounded-[10px] focus-visible:outline-none focus-visible:border-none placeholder:font-normal placeholder:text-lg"
                  data-testid="team-search-input"
                  placeholder="Search for teams..."
                  onChange={(e) => search(question, e.target.value)}
                  autoComplete="off"
                />
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options  data-testid={`team-data-list`} className="no-scrollbar absolute mt-1 max-h-60 px-3 w-full overflow-auto rounded-md background py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {data
                    .filter((i) => !survey.map(i=>i.strTeam).includes(i.strTeam))
                    .map((team, key) => (
                      <Combobox.Option
                        key={key}
                        className={({ active }) =>
                          `relative cursor-pointer text-lg font-normal border-b select-none py-2 ${
                            active ? "text-primary" : "text-primary"
                          }`
                        }
                        value={team}
                        data-testid={`team-item-list-${key}`}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center">
                              <img
                                src={team.strTeamBadge?team.strTeamBadge:defaultIcon}
                                alt={team.strTeam}
                                className="w-6 h-6 mr-2 rounded-xl"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src=defaultIcon;
                                }}
                              />
                              <span className="block truncate font-normal text-lg">
                                {team.strTeam}
                              </span>
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
        <div className="lg:mb-0 mt-3 mb-7">
          <div className="grid grid-cols-1 place-items-center">
            {survey.map((item: any, index: number) => (
              <div
                key={index}
                className="inline-flex mr-2 items-center gap-2 background px-4 py-4 mt-6 rounded-full"
              >
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    removeSearch(question, index);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-primary"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div className="flex items-center">
                  <img

                    src={item.strTeamBadge?item.strTeamBadge:defaultIcon}
                      alt={item.strTeam}
                      className="w-6 h-6 mr-2 rounded-xl"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src=defaultIcon;
                      }}
                  />
                  <p className="text-primary text-lg font-normal">
                    {item?.strTeam || ""}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
