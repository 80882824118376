
import React, {useEffect, useState, useRef} from 'react';
import { Link, useNavigate } from "react-router-dom"
import Logo from "@components/Logo";
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import LastStep from "@pages/fans/LastStep";
import { connect } from "react-redux";
const { actions } = require("@redux/UserRedux");


interface Props {
    steps: {
        [key: string]: StepItem[];
    };
}

interface StepItem {
    label: string;
    input: string;
    placeholder: string;
    rules: {
        [key: string]: any;
    };
    type: string
}


const FirstStepScreen = ({ form, next, prev, current, lastStep,creator }: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const inputRef = useRef(null);

    const [isLast, setIsLast] = useState(false);

    const fdata = useSelector((state:any)=> Object.assign({}, state.user.form));

     const { register, handleSubmit, unregister, setValue, formState: { errors }, } = useForm<{[key: string]: string }>({
    });



    //if(isLast) return <LastStep />;
    return <LastStep />;
};


const mapStateToProps = ({ creator }) => {
    return {
      creator : creator?.data,
    };
  };

  
export default connect(mapStateToProps, undefined)(FirstStepScreen);