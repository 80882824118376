import React, { useState } from "react";
import moment from "moment";
import InputMask from "react-input-mask";
import Loader from "@components/Loader";
import Api from "@services/api";
import Management from "@services/management-service";

export const Dob = ({ user, item }) => {
	const [dob, setDob] = useState(null);
	const [loader, setLoader] = useState(false);
	const [error, setError] = useState(null);
	const management = Management.get();

	const handleSubmit = async () => {

		const dateOfBirthMoment = moment(dob, "MM/DD/YYYY", true);

		if (!dateOfBirthMoment.isValid()) {
			setError("Please enter a valid date of birth in the format MM/DD/YYYY.");
			return;
		} else if (
			moment().diff(dateOfBirthMoment, "years") > 100 ||
			!moment(dob, "MM/DD/YYYY", true).isValid()
		) {
			setError("Please enter a correct date of birth.");
			return;
		};
		setError("");

		const data = {
			programId: management?.program?.id,
			fanId: user?.user?.id,
			actionId: item?.id || '',
			tpId: item?.touchpoint?.id,
			pointsEarned: item?.points || 0,
			details:{
			  dob:dob
			}
		};

		setLoader(true)
		await Api.authorizeActionItem(data);
		setLoader(false);

	};

	const handleDobInput = (e) => {
		setDob(e.target.value);
		setError(false);
	};

	return (
		<>
			<div
				className={`mt-3 bg-tile-form px-3 flex items-center justify-between rounded-[10px]`}
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<InputMask
					mask="99/99/9999"
					type={"tel"}
					maskPlaceholder={"mm/dd/yyyy"}
					placeholder={!dob ? "mm/dd/yyyy" : ""}
					onChange={handleDobInput}
					onKeyDown={(e) => {
						if (e.keyCode === 13) {
							handleSubmit();
						}
					}}
					className={`w-full h-[40px] bg-transparent rounded-[10px] tile-inlay-primary placeholder:tile-inlay-primary placeholder:text-[14px] shadow-none outline-none`}
				/>
				<button
					onClick={handleSubmit}
					disabled={!moment(dob, "MM/DD/YYYY", true)?.isValid()}
					className={`bg-btn flex items-center justify-center w-[80px] h-[25px] rounded-full btn-inlay-primary py-[6px] px-[12px] disabled:opacity-30`}
				>
					{loader ? <Loader height={20} width={20} /> : "SUBMIT"}
				</button>
			</div>
			{error && (
				<p className="font-normal text-[10px] mt-1 text-center text-red-500 ">
					{error}
				</p>
			)}
		</>
	);
};
