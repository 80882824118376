import Api from "@services/api";
import store from "@store/configureStore";
import { redirectUri, getScore } from "@helpers/socialAuth";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import NotificationService from "@services/notification-service";

class CallbackService {
  settings = {};

  async connect() {
    let currentUrl = window.location.href;
    const params = new URL(currentUrl).searchParams;
    let path = window.location.pathname;
    let split = path.split("/");
    split.shift();
    const touchpoint = split.shift() || "tiktok";

    let code = params.get("code");
    let shop = params.get("shop");
    const access_token = params.get("access_token");
    if (access_token) {
      code = access_token;
    }
    const state = params.get("state");
    if (code) {
      let token_creator_type = state.split("~");
      store.dispatch({ type: "SET_APP_LOADER", data: true });
      store.dispatch({ type: "SET_RESOLVER_PAGE", data: true });
      store.dispatch({
        type: "SET_APP_CREATOR",
        data: token_creator_type[2] === "creator" ? true : false,
      });
      const creator_id = token_creator_type[1];
      const touchpointId = token_creator_type?.[3];
      const username = token_creator_type?.[4] || "creator";
      const redirect_uri = redirectUri((touchpoint || "").toLowerCase());
      let json;
      if (token_creator_type[2] === "creator") {
        if (touchpoint === "shopify") {
          const data = {
            oauthData: {
              redirectUri: redirect_uri,
              authorizationCode: code,
              query: "creator-info",
              creatorId: creator_id,
              storeDomain: shop,
            },
          };
          json = await Api.resolveOauthCreator(touchpoint, data);
        } else if (touchpoint === "facebook") {
          const data = {
            oauthData: {
              redirectUri: redirect_uri,
              authorizationCode: code,
              query: "creator-info",
            },
          };
          json = await Api.resolveOauthCreator(touchpoint, data);
        } else if (touchpoint === "youtube") {
          const data = {
            oauthData: {
              redirectUri: redirect_uri,
              authorizationCode: code,
              query: "creator-info",
            },
            touchpointData: getScore(touchpoint),
          };
          json = await Api.resolveOauthCreator(touchpoint, data);
        } else {
          const data = {
            oauthData: {
              redirectUri: redirect_uri,
              authToken: code,
              query: "creator-info",
            },
            touchpointData: getScore(touchpoint),
          };
          json = await Api.resolveOauthCreator(touchpoint, data);
        }
      } else {
        if (touchpoint === "shopify") {
          const data = {
            oauthData: {
              query: "creator-info",
              storeDomain: shop,
              authorizationCode: code,
            },
          };
          json = await Api.shopifyOauth(data);
        } else {
          json = await Api.resolveOauth(
            creator_id,
            touchpoint,
            touchpointId,
            code,
            redirect_uri
          );
        }
      }
      if (json.status === 200) {
        NotificationService.success(
          "You have successfully connected your account"
        );
      } else {
        if (json.status === 403) {
          NotificationService.error(json.data?.error?.message);
        } else {
          NotificationService.error(
            "Unable to connect your account please try again"
          );
        }
      }

      const data = {
        json: json,
        username: username,
      };

      return data;
    }
  }

  setInviteToken(searchParams) {
    const invite = searchParams.get("invite");
    const actionId = searchParams.get("a");
    if (invite && actionId) {
      store.dispatch({ type: "SET_INVITE_TOKEN", data: { invite, actionId } });
    }
  }

  setScannerEventPayload(data) {
    store.dispatch({ type: "SET_SCANNER_EVENT_PAYLOAD", data: data });
  }

  async inviteWebhook(data) {
   
    const json = await Api.touchpointActionEgnage(data)
    if (json?.status == 200) {
      store.dispatch({ type: "SET_INVITE_TOKEN", data: null });
      this.fetchScoreTouchpoint()
    }
   
  }

  fetchScore(){
    const { actions } = require("@redux/ScoreRedux");
    let user = store.getState().user?.user;
    if (user?.user?.id && user?.user?.programId) {
      actions.fetchScores(store.dispatch, user?.user?.programId);
    }
  }

  updateScore(data){
    store.dispatch({ type: "UPDATE_SCORES_SUCCESS", data: data });
  }

  updateActionItem(data){
    store.dispatch({ type: "UPDATE_REWARD_TOUCHPOINT", data: data });
  }

  fetchScoreTouchpoint(){
    const { actions } = require("@redux/ScoreRedux");
    const { rewardActions } = require("@redux/EarnRewardRedux");

    let user = store.getState().user?.user;
    if (user?.user?.id && user?.user?.programId) {
      actions.fetchScores(store.dispatch, user?.user?.programId);
      rewardActions.fetchEarnRewards(store.dispatch, user?.user?.programId);
    }
  }

  async scannerEventWebhook(data) {
  
    const json = await Api.touchpointActionEgnage(data);
    if(json.status==200){
      store.dispatch({ type: "SET_SCANNER_EVENT_PAYLOAD", data: null });
    }

    return json;
  }

  async fetchUser(fanId) {
    const json = await Api.getFans(fanId);
    if (json !== undefined) {
      if (json.status === 200) {
        store.dispatch({
          type: "UPDATE_USER_OBJECT_SUCCESS",
          data: json.data?.data,
        });
      }
    }
    return json;
  }

  async fetchCreator() {
    let data = store.getState().user?.user;
    if (data?.user?.id && data?.user?.programs?.[0]?.id) {
      const json = await Api.fetchCreatorAssets(
        data?.user?.id,
        data?.user?.programs?.[0]?.id
      );
      if (json?.status == 200) {
        store.dispatch({
          type: "FETCH_CREATOR_ASSET_SUCCESS",
          data: json.data,
        });
      }
      return json;
    }
  }

  async fetchCreatorAssets() {
    let data = store.getState().user?.user;
    let loader = store.getState().loader;
    if (data?.user?.id) {
      if (loader.isLoginFlow) {
        store.dispatch({ type: "SET_GLOBAL_LOADER", data: true });
        const json = await Api.fetchCreatorAssets(
          data?.user?.id,
          data?.user?.programs?.[0]?.id
        );
        if (json?.status == 200) {
          store.dispatch({
            type: "FETCH_CREATOR_ASSET_SUCCESS",
            data: json.data,
          });
        }

        store.dispatch({ type: "SET_LOGIN_FLAG", data: false });

        return json;
      }
    }

    return undefined;
  }

  setSettings(settings) {
    if (Object.keys(this.settings || {}).length > 0) {
      this.settings = Object.assign(this.settings, settings);
    } else {
      this.settings = settings;
    }
  }

  addToNotificationQueue(data){
    store.dispatch({ type: "ADD_NOTIFICATION_QUEUE", data: [data] });
  }

  removeToNotificationQueue(){
    store.dispatch({ type: "REMOVE_NOTIFICATION_QUEUE"});
  }
}
const Callback = new CallbackService();

//Object.freeze(Callback)

export default Callback;
