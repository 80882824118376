import React, {useEffect, useState, useRef} from 'react';
import {  useNavigate } from "react-router-dom"
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import CallbackService from "@services/callback-service";
import Loader from "@components/Loader";
import moment from 'moment';
import InputMask from "react-input-mask";


const SettingForm = ({back, isExtra=false}) => {

    const { actions } = require("@redux/UserRedux");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState("");

    const inputRef = useRef(null);

    const user = useSelector((state:any)=> Object.assign({}, state.user));
    const creator = useSelector((state:any)=> Object.assign({}, state.creator));
    const dynamicForm = (creator?.program?.extraInfo || {});
    const isFetching = user.isFetching;
    const userExtraInfo  = Object.assign({}, user.user?.user?.extraInfo);
    const fullname =   (user.user?.user?.firstName || "") + " " + (user.user?.user?.lastName || "");

    const [name, setName] = useState(fullname);
    let [extraInfo, setExtraInfo] = useState(userExtraInfo);

    let form = [];

    Object.keys(dynamicForm).map((fields:any)=>{
        (dynamicForm[fields] || []).map((field:any)=>{
            form.push(field)
        });
    });

    const date = new Date();
    const dateOptions:any = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    };

    const minAge = (form.filter(i=>i.type=="date")?.[0]?.rules?.minAge || 16);
    const maxDate = new Date(date.getFullYear() - minAge, date.getMonth(), date.getDate());
    const minDate = new Date(date.getFullYear() - 100, date.getMonth(), date.getDate());
    const maxDateStr = new Intl.DateTimeFormat('fr-CA', dateOptions).format(maxDate);
    const minDateStr = new Intl.DateTimeFormat('fr-CA', dateOptions).format(minDate);

    

    const { register, handleSubmit, setValue, formState: { errors }, } = useForm<{ [key: string]: string }>({});

    const onChangeFullName = (e) => {
        setName(e.target.value);
        setValue("name", e.target.value);
    }

    const onChangeDynamic = (key, value) => {
        extraInfo = {
          ...extraInfo,
          [key]:value
        }
        setValue(key, value);
        setExtraInfo(extraInfo);
    }


    const submit = async (data:any) => {
        setError("");
        if(data?.dateOfBirth){
            if(moment().diff(moment(data?.dateOfBirth), 'years')<minAge){
                setError(`you are under ${minAge} years of age, we are unable to register you.`);
                return;
            }
            if(moment().diff(moment(data?.dateOfBirth), 'years')>100 || Number.isNaN(moment().diff(moment(data?.dateOfBirth), 'years'))){
                setError(`please enter correct date of birth.`);
                return;
            }
        }
        var firstName = name.split(" ").slice(0, -1).join(" ");
        var lastName = name.split(" ").slice(-1).join(" ");
        let userInfo:any = {
            firstName: firstName,
            lastName: lastName,
        };
        if(isExtra){
            userInfo = {creatorId:creator?.creator?.id, fanId:user.user?.user?.id};
        }
        if(Object.keys(dynamicForm).length>0 && isExtra){
            userInfo = {
                ...userInfo,
                extraInfo:extraInfo
            }
        }

        const json = await actions.updateUser(dispatch, userInfo, isExtra, user.user?.user?.id);
        if (json !== undefined) {
            if (200 === json.status) {
                await CallbackService.fetchUser();
                back();

            }
        }
        
    }

    const browserInput:any = document.querySelector('input[type="date"]');
    let isDisabled = false;
    if(!isExtra){
        if(name==fullname){
            isDisabled = true;
        }
    } else {
        if(Object.keys(extraInfo).length>0){
            isDisabled = true;
        }
        
        Object.keys(dynamicForm).map((fields:any)=>{
            (dynamicForm[fields] || []).map((field:any)=>{
                if(userExtraInfo?.[field?.fieldName]!=extraInfo?.[field?.fieldName]){
                    isDisabled = false;
                }
                
            })
        });
        
    }

    return (
            <form>
                <div className="px-5 mt-8 max-w-[420px]">
                    {!isExtra &&
                    <div className="mb-4">
                    <h2 className="tile-inlay-primary font-light text-[18px] leading-9 mx-auto mb-[5px]">
                        Your Name
                    </h2>
                    <input
                        autoComplete='off'
                        type={"text"}
                        value={name}
                        {...register("name", {required:"name is required", pattern:{value:/^([a-zA-Z]{2,}\s[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?)/i, message:"Enter full name"} })}
                        placeholder={"John Doe"}
                        onChange={onChangeFullName}
                        className="w-full border h-[60px] px-3 mr-auto font-normal tile-inlay-primary rounded-lg shadow-none outline-none text-[18pt] placeholder:opacity-30 placeholder:font-normal bg-tile-form"

                    />
                    {errors["name"] && (
                        <p className="text-red-500 mt-2 text-xs">{errors["name"].message}</p>
                    )}
                    </div>
                    }

                    {isExtra && Object.keys(dynamicForm).reverse().map((fields:any)=>{
                    return (dynamicForm[fields] || []).map((field, key)=>(
                        <div className="mb-4 max-w-[340px]" key={key}>
                        <h2 className="tile-inlay-primary font-light text-[18px] leading-9 mx-auto mb-[5px]">
                        {field?.name}
                        </h2>
                        <div className="grow lg:mt-8 ">
                            <div className="relative flex items-center min-w-[290px] rounded border">
                            {field.type=="date"?
                            <InputMask
                            mask="99/99/9999"
                            type={"text"}
                            {...register(field.fieldName, {...field.rules})}
                            onChange={(e)=>onChangeDynamic(field?.fieldName, e.target.value)}
                            value={extraInfo?.[field?.fieldName] || ''}
                            disabled={isDisabled}
                            maskPlaceholder={"mm/dd/yyyy"}
                            placeholder={"mm/dd/yyyy"}
                            className="w-full  h-[60px] min-w-[290px] px-3 mr-auto font-normal tile-inlay-primary rounded-[10px] shadow-none outline-none text-[18pt] placeholder:opacity-30 placeholder:font-normal bg-tile-form"
        
                            />:
                            <input
                            type={field?.type}
                            {...register(field.fieldName, {...field.rules})}
                            onChange={(e)=>onChangeDynamic(field?.fieldName, e.target.value)}
                            value={extraInfo?.[field?.fieldName] || ''}
                            disabled={isDisabled}
                            placeholder={field?.placeholder}
                            className="w-full  h-[60px] min-w-[290px] px-3 mr-auto font-normal tile-inlay-primary rounded-[10px] shadow-none outline-none text-[18pt] placeholder:opacity-30 placeholder:font-normal bg-tile-from"
                            {...field.type == "date" && 
                                {max:maxDateStr, min:minDateStr}
                            }
                            />
                            }
                            {/* {field.type=="date" &&
                            <div className='absolute right-3 zIndex-1' onClick={()=>browserInput.showPicker()}>
                                <svg className="h-7 w-7 text-primary "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />  <line x1="16" y1="2" x2="16" y2="6" />  <line x1="8" y1="2" x2="8" y2="6" />  <line x1="3" y1="10" x2="21" y2="10" /></svg>
                            </div>
                            } */}
                            </div>
                        </div>

                        {errors[field.fieldName] && (
                            <p className="text-red-500 mt-2 text-xs">{errors[field.fieldName].message}</p>
                        )}
                        
                    </div>
                    ))
                })}

                {!!error && (
                    <p className="text-red-500 mt-2 text-xs">{error}</p>
                )}

                <div className="mt-[40px]">
                    <button
                        disabled={isDisabled}
                        onClick={handleSubmit(submit)} 
                        className={` ${isDisabled?"bg-[#616161]":"bg-btn"} btn-inlay-primary hover:opacity-80 text-gray rounded-full mx-auto h-[42px] w-[92px] font-normal text-md`}>
                        {isFetching ? (
                        <Loader height={30} width={30} white={true} />
                        ) : (
                        "save"
                        )}
                    </button>
                </div>
                
          </div>
    </form>
    );
};

export default SettingForm;