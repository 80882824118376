import React, { useEffect, useState } from "react";
import Loader from "@components/Loader";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAppName } from "@helpers/common";
import Api from "@services/api";
import NotificationService from "@services/notification-service";

const SocialCallback: React.FC<any> = (props) => {
  const { actions:scoreAction } = require("@redux/ScoreRedux");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {type} = useParams();
  const [loader, setLoader] = useState(true);
  const user = useSelector(
    (state: any) => state.user?.user?.user || {},
  );
  const creator = useSelector((state: any) => state.creator);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    const code = params.get("code");
    const access_token = params.get("access_token");
    const error = params.get("error");
    if(type){
        if(code){
          submitCreatorSocial(code);
        }
        if(access_token){
          submitCreatorSocial(access_token);
        }
        if(error){
          if(getAppName()!="creator"){
            if(creator?.creator?.username && creator?.program?.name){
              navigate(`/${creator?.creator?.username}/${creator?.program?.name}`);
            }
           
          } else {
            navigate("/");
          }
          
        }
    }
    
  }, []);

  const submitCreatorSocial = async (code) => {

    let  redirect_uri:string; 
    if(type=="instagram"){
        redirect_uri = getAppName() === "creator"? process.env.REACT_APP_INSTAGRAM_CREATOR_REDIRECT_URL:process.env.REACT_APP_INSTAGRAM_REDIRECT_URL
    }
    if(type=="tiktok"){
      redirect_uri = getAppName() === "creator"? process.env.REACT_APP_TIKTOK_CREATOR_REDIRECT_URL:process.env.REACT_APP_TIKTOK_REDIRECT_URL
    }

    if(type=="twitter"){
      redirect_uri = getAppName() === "creator"? process.env.REACT_APP_TWITTER_CREATOR_REDIRECT_URL:process.env.REACT_APP_TWITTER_REDIRECT_URL
    }

    if(type=="youtube"){
      redirect_uri = getAppName() === "creator"? process.env.REACT_APP_YOUTUBE_CREATOR_REDIRECT_URL:process.env.REACT_APP_YOUTUBE_REDIRECT_URL
    }

    if(type=="facebook"){
      redirect_uri = getAppName() === "creator"? process.env.REACT_APP_FACEBOOK_CREATOR_REDIRECT_URL:process.env.REACT_APP_FACEBOOK_REDIRECT_URL
    }

    if(getAppName()=="creator"){
        const data = {
            socialType:type,
            creatorId: user?.id,
            authToken: code,
            redirectUri: redirect_uri,
            touchpointDetails: {
                programId:user?.programs?.[0]?.id,
                type: `socialMedia:${type}`,
                name: (type || "").toUpperCase(),
                authorized: true,
            }
        }
        await Api.submitCreatorSocial(data);
        navigate("/home");
    } else {
        const state = params.get("state");
        let ids = (state || "").split("~");
        const tpId = ids?.[0];
        const aId = ids?.[1];
        const points = ids?.[2];
        const fanId = ids?.[3];
        const programId = ids?.[4];
        const popup = ids?.[5];
        
        const data = {
            socialType:type,
            fanId: fanId,
            authToken: code,
            redirectUri: redirect_uri,
            shareDetails: {
                programId:programId,
                type: type,
                name: (type || "").toUpperCase(),
                tpId:tpId,
                actionId:aId,
                pointsEarned: points,
            }
        }
        const json = await Api.submitFanSocial(data);
        if(json?.status==200 || json?.status ==201){
          setLoader(false);
        } else if (json?.status == 409){
          NotificationService.error(json?.data?.message || "An error occurred")
        }
        if(popup=="popup"){
         window.close();
        }
        if(creator?.program?.id){
          scoreAction.fetchScores(dispatch, creator?.program?.id);
        }
        if(creator?.creator?.username && creator?.program?.name){
          const timer = setTimeout(() => {
            navigate(`/${creator?.creator?.username}/${creator?.program?.name}`);
          }, 2000);
          return () => clearTimeout(timer);
        }   
    }
    
  }

  return (
    <div className={`${getAppName() == "creator" ? "h-screen bg-[#F6F2E9]" : "bg-body"}`}>
        <div className={`flex items-center justify-center h-screen`}>
          {loader?
         <div className="">
            <Loader
              height={30}
              width={30}
              creator={getAppName() == "creator"}
              white={getAppName() == "creator" ? false : true}
              color={'var(--bg-btn)'}
            />
            <p
              className={`${
                getAppName() == "creator" ? "text-black" : "tile-inlay-primary"
              } text-lg`}
            >
              connecting, please wait...
            </p>
          </div>:<div className="">
            <p
              className={`${
                getAppName() == "creator" ? "text-black" : "tile-inlay-primary"
              } text-lg`}
            >
              connected
            </p>
          </div>}
        </div>
    </div>
  );
};

export default SocialCallback;