import Api from "@services/api";
import NotificationService from "@services/notification-service";

const types = {
  SUBMIT_CREATOR_INSTAGRAM_FORM_PENDING: "SUBMIT_CREATOR_INSTAGRAM_FORM_PENDING",
  SUBMIT_CREATOR_INSTAGRAM_FORM_SUCCESS: "SUBMIT_CREATOR_INSTAGRAM_FORM_SUCCESS",
  SUBMIT_CREATOR_INSTAGRAM_FORM_FAILURE: "SUBMIT_CREATOR_INSTAGRAM_FORM_FAILURE",
  LOAD_CREATOR_INSTAGRAM_TOUCHPOINTS:"LOAD_CREATOR_INSTAGRAM_TOUCHPOINTS",
  TOGGLE_CREATOR_INSTAGRAM_TOUCHPOINT:"TOGGLE_CREATOR_INSTAGRAM_TOUCHPOINT",
  UPDATE_CREATOR_INSTAGRAM_TOUCHPOINT:"UPDATE_CREATOR_INSTAGRAM_TOUCHPOINT",
  DELETE_CREATOR_INSTAGRAM_TOUCHPOINT:"DELETE_CREATOR_INSTAGRAM_TOUCHPOINT",
  ADD_INSTAGRAM_FORM: "ADD_INSTAGRAM_FORM",
  CLEAR_INSTAGRAM_FORM: "CLEAR_INSTAGRAM_FORM",
};
export const actions = {
  submitForm: async (dispatch, touchpoint, actionItem, data) => {
    dispatch({ type: types.SUBMIT_CREATOR_INSTAGRAM_FORM_PENDING });
    const json = await Api.creatorTouchpointInit(touchpoint, actionItem, data);

    if (json !== undefined) {
      if (200 === json.status) {
        NotificationService.success("Touchpoint created successfully.");
        dispatch({
          type: types.SUBMIT_CREATOR_INSTAGRAM_FORM_SUCCESS,
          data: json.data,
        });
      } else {
        NotificationService.error(json.data?.error?.message);
        dispatch({ type: types.SUBMIT_CREATOR_INSTAGRAM_FORM_FAILURE, data: "" });
      }
    } else {
      dispatch({ type: types.SUBMIT_CREATOR_INSTAGRAM_FORM_FAILURE, data: "" });
    }
    return json;
  },
  addForm: data => {
    return { type: types.ADD_INSTAGRAM_FORM, data };
  },

  clearForm: () => {
    return { type: types.CLEAR_INSTAGRAM_FORM };
  },

  loadTouchPoints: (data) => {
    return { type: types.LOAD_CREATOR_INSTAGRAM_TOUCHPOINTS, data };
  },
  toggleTouchPoint: (data) => {
    return { type: types.TOGGLE_CREATOR_INSTAGRAM_TOUCHPOINT, data };
  },
  deleteTouchPoint: async (data, dispatch) => {
    const json = await Api.patchCreators(`touchpoints/action-items/deactivate/${data.id}`);
      if (json !== undefined) {
        if (200 === json.status) {
          NotificationService.success("Touchpoint deleted successfully.");
          dispatch({ type: types.DELETE_CREATOR_INSTAGRAM_TOUCHPOINT, data });
        } else {
          NotificationService.error(json.data?.error?.message);
        }
      } else {
        NotificationService.error("Something went wrong.");
      }
      return json;
    },
};
const initialState = {
  data: { touchPointShopify: { storewidePurchases: "StorewidePurchases" } },
  form: {},
  error: "",
  isSubmitting: false,
  shopifyForm: {
    data: [],
    isFetching: false,
    isDeleting: false,
    isSubmitting: {},
    total: 0,
  },
  touchpoints:[],
  touchpointForm:{}
};
export const reducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.SUBMIT_CREATOR_INSTAGRAM_FORM_PENDING: {
      return {
        ...state,
        error: "",
        isSubmitting: true,
      };
    }

    case types.SUBMIT_CREATOR_INSTAGRAM_FORM_SUCCESS: {
      const shopifyForm = data.shopifyFormActionItem;

      return {
        ...state,
        error: "",
        isSubmitting: false,
        form: {},
        shopifyForm: {
          ...state.shopifyForm,

          data: [...(state.shopifyForm?.data || []), shopifyForm],
          total: state.shopifyForm.total + 1,
        },
      };
    }

    case types.SUBMIT_CREATOR_INSTAGRAM_FORM_FAILURE: {
      return {
        ...state,
        isSubmitting: false,
        error: data,
      };
    }
    case types.ADD_INSTAGRAM_FORM: {
      return { ...state, form: data };
    }

    case types.CLEAR_INSTAGRAM_FORM: {
      return {
        ...state,
        isSubmitting: false,
        error: "",
        form: {},
      };
    }

    case types.LOAD_CREATOR_INSTAGRAM_TOUCHPOINTS:{
      return {
        ...state,
        touchpoints:data
      };
    }

    case types.TOGGLE_CREATOR_INSTAGRAM_TOUCHPOINT:{
      return {
        ...state,
        touchpointForm:data
      };
    }

    case types.DELETE_CREATOR_INSTAGRAM_TOUCHPOINT:{
      return {
        ...state,
        touchpoints:state.touchpoints.filter(i => i.id != data.id),
      };
    }
    
    default:
      return state;
  }
};