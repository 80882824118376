/** @format */
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import reducers from "@redux";
import { deepEqual } from "@helpers/common";

const middleware = [
  thunk,
  // more middleware
];
let store = compose(applyMiddleware(...middleware))(createStore)(reducers);

window.addEventListener('storage', (event) => {
  if (event.key === 'persist:encore') {
    
    const localStorageState = JSON.parse(localStorage.getItem('persist:encore'));
    if (localStorageState.user) {
      const user  = JSON.parse(localStorageState.user);
      if (!deepEqual(user.session, store.getState().user?.session)) {
        store.dispatch({type: "SYNC_STORAGE", data: user?.session, token: {token: user?.token}});
      }
    }
  }
});

export default store;

